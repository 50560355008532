/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import { useState } from 'react';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import _ from '@lodash';
import jwtService from '../../auth/services/jwtService';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import ExpiredPassword from './ExpiredPassword';
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
   username: yup.string().required('Please enter an username'),
   password: yup.string().required('Please enter your password.'),
});

const defaultValues = {
   username: '',
   password: '',
   remember: true,
};

function SignInPage() {
   const dispatch = useDispatch();
   const routeParams = useParams();
   const { token } = routeParams;
   const [showPassword, setShowPassword] = useState(false);
   const [actionPage, setActionPage] = useState(token === undefined ? 'sign-in' : 'resetpassword');
   const [showMsg, setShowMsg] = useState(['', false]);

   const { control, formState, handleSubmit, setError, setValue, getValues } = useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
   });

   const { isValid, dirtyFields, errors } = formState;

   /*  useEffect(() => {
      setValue('username', 'pspezamiglio', { shouldDirty: true, shouldValidate: true });
      setValue('password', 'Psp*6163', { shouldDirty: true, shouldValidate: true });
   }, [setValue]);
   */

   function onSubmit({ username, password }) {
      jwtService
         .signInWithEmailAndPassword(username, password)
         .then((user) => {
            // No need to do anything, user data will be set at app/auth/AuthContext
         })
         .catch((_errors) => {
            const _errorsAr = Array.from(_errors);
            _errorsAr.map((x) => {
               if (x.message.includes('expired')) {
                  setActionPage('expiredpassord');
               } else setError(x.type, { type: 'manual', message: x.message });
            });
         });
   }

   const handleScreen = (id) => {
      setActionPage(id);
   };

   return (
      <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
         <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
            <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
               <div className="flex items-center mb-48">
                  <img src="assets/images/logo/AntrakLogo.png" alt="logo" />
                  <div className="border-l-1 mx-8 w-1 h-40" />
                  <div>
                     <Typography className="text-24 font-semibold logo-text" color="inherit">
                        ANTRAK-IT
                     </Typography>
                  </div>
               </div>

               {actionPage === 'sign-in' ? (
                  <>
                     <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">Sign in</Typography>

                     <form name="loginForm" noValidate className="flex flex-col justify-center w-full mt-32" onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                           name="username"
                           control={control}
                           render={({ field }) => (
                              <TextField
                                 {...field}
                                 className="mb-24"
                                 label="Username"
                                 autoFocus
                                 type="text"
                                 error={!!errors.username}
                                 helperText={errors?.username?.message}
                                 variant="outlined"
                                 required
                                 fullWidth
                                 inputProps={{
                                    maxLength: 20,
                                 }}
                                 InputProps={{
                                    autoComplete: 'off',
                                    endadornment: (
                                       <InputAdornment position="end">
                                          <Icon className="text-20" color="action">
                                             user
                                          </Icon>
                                       </InputAdornment>
                                    ),
                                 }}
                              />
                           )}
                        />

                        <Controller
                           name="password"
                           control={control}
                           render={({ field }) => (
                              <TextField
                                 {...field}
                                 className="mb-24"
                                 label="Password"
                                 type="password"
                                 error={!!errors.password}
                                 helperText={errors?.password?.message}
                                 variant="outlined"
                                 required
                                 fullWidth
                                 inputProps={{
                                    maxLength: 20,
                                 }}
                                 InputProps={{
                                    className: 'pr-2',
                                    autoComplete: 'off',
                                    type: showPassword ? 'text' : 'password',
                                    /* endAdornment: (
                                       <InputAdornment position="end">
                                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                                             <Icon className="text-20" color="action">
                                                {showPassword ? 'visibility' : 'visibility_off'}
                                             </Icon>
                                          </IconButton>
                                       </InputAdornment>
                                    ), */
                                 }}
                              />
                           )}
                        />

                        <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between">
                           <Link className="text-md font-medium" to="" onClick={() => setActionPage('forgotpassword')}>
                              Forgot password?
                           </Link>
                        </div>

                        <Button
                           variant="contained"
                           color="secondary"
                           className=" w-full mt-16"
                           aria-label="Sign in"
                           disabled={_.isEmpty(dirtyFields) || !isValid}
                           type="submit"
                           size="large"
                        >
                           Sign in
                        </Button>
                     </form>
                  </>
               ) : actionPage === 'resetpassword' ? (
                  <ResetPassword handleScreen={handleScreen} token={token} />
               ) : actionPage === 'expiredpassord' ? (
                  <ExpiredPassword handleScreen={handleScreen} username={getValues('username')} />
               ) : (
                  <ForgotPassword handleScreen={handleScreen} />
               )}
            </div>
         </Paper>

         <Box
            className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
            sx={{ backgroundColor: 'primary.main' }}
         >
            <svg
               className="absolute inset-0 pointer-events-none"
               viewBox="0 0 960 540"
               width="100%"
               height="100%"
               preserveAspectRatio="xMidYMax slice"
               xmlns="http://www.w3.org/2000/svg"
            >
               <Box component="g" sx={{ color: 'primary.light' }} className="opacity-20" fill="none" stroke="currentColor" strokeWidth="100">
                  <circle r="234" cx="196" cy="23" />
                  <circle r="234" cx="790" cy="491" />
               </Box>
            </svg>
            <Box
               component="svg"
               className="absolute -top-64 -right-64 opacity-20"
               sx={{ color: 'primary.light' }}
               viewBox="0 0 220 192"
               width="220px"
               height="192px"
               fill="none"
            >
               <defs>
                  <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                     <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                  </pattern>
               </defs>
               <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
            </Box>

            <div className="z-10 relative w-full max-w-2xl">
               <div className="text-7xl font-bold leading-none text-gray-100">
                  <div>Welcome to</div>
                  <div>ANTRAK-IT</div>
               </div>
               <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">
                  Antrak Logistics' cargo control and tracking system is called Antrak-IT. <br />
                  Antrak-IT is a real-time web-based documentation & tracking system which allows Antrak Logistics to control, monitor and report on
                  the complete supply chain, from door at origin to site at destination.
                  <br />
                  Antrak-IT can be tailored to suit individual project's or customer's needs.
               </div>
            </div>
         </Box>
      </div>
   );
}

export default SignInPage;
