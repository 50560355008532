/* eslint-disable no-nested-ternary */
import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import jwtService from '../auth/services/jwtService';

// console.log(process.env.REACT_APP_API_ENDPOINT);
// console.log(process.env.NODE_ENV);
const basenameURL = process.env.REACT_APP_API_ENDPOINT;

const instanceTokenAlternative = axios.create({
   baseURL: basenameURL, // declared on .env - http://localhost:5000/api
   timeout: 300000,
});

const instance = axios.create({
   baseURL: basenameURL, // declared on .env - http://localhost:5000/api
   timeout: 300000,
   /* headers: {
        'Authorization': `Bearer ${token}`
    } */
});

instance.interceptors.request.use(
   (config) => {
      const token = window.localStorage.getItem('jwt_access_token');
      if (token) config.headers.Authorization = `Bearer ${token}`;
      if (jwtService.isAuthTokenValid(token)) return config;
      jwtService.handleAuthentication();
   },
   (error) => {
      return Promise.reject(error);
   }
);

instance.interceptors.response.use(undefined, (error) => {
   return errorAxios(error);
});

instanceTokenAlternative.interceptors.response.use(undefined, (error) => {
   return errorAxios(error);
});

const errorAxios = (error) => {
   const errors = [];
   if (error.message === 'Network Error' && !error.response) {
      errors.push({ type: 'password', message: 'Failed to login. Network Error. ' });
      throw new Error(errors);
   }

   if (error.message.includes('cancelToken')) {
      throw new Error('access token expired');
   }

   if (error.response !== undefined) {
      const { status, data, config } = error.response;
      if (status === 404) {
         // treat error from RestException (API) when HttpStatusCode.NotFound
         // treat - Docs error
         if (error.request.responseType === 'arraybuffer') {
            const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(error.response.data)));
            const errorJson = JSON.parse(text);

            const msgError = Object.entries(errorJson?.errors).map(([key, value]) => `${key.toString().toUpperCase()} - ${value.toString()}`) || [];

            error.response.data.errors = msgError.length > 0 ? msgError[0] : '';
         } else if (data !== undefined) {
            const msgError = Object.entries(data?.errors).map(([key, value]) => `${key.toString().toUpperCase()} - ${value.toString()}`) || [];

            error.response.data.errors = msgError.length > 0 ? msgError[0] : '';
         }
      }
      if (status === 400) {
         // treat error from AbstractValidator on API
         if (data !== undefined) {
            const msgError = Object.entries(data.errors).map(([key, value]) => value.toString()) || [];
            error.response.data.errors = msgError.length > 0 ? msgError.join(',') : '';
         }
      }
      if (status === 500) {
         if (error.request.responseType === 'arraybuffer') {
            const text = String.fromCharCode.apply(null, Array.from(new Uint8Array(error.response.data)));
            const errorJson = JSON.parse(text);

            error.response.data.errors = errorJson?.errors.length > 0 ? errorJson.errors : '';
         }

         // this will show error from Exception on API - I didn't have to treat the error to show the messsage properly
      }
   }
   throw error.response;
};

const responseBody = (response) => response.data;

const responseBodyDoc = (response) => {
   const contentDisposition = response.headers['content-disposition'];
   const match = contentDisposition.match(/filename=\s*(.+);/i);
   const filename = match[1].replace('"', '').replace('"', ''); // Replace multiple - with single -;

   return [response.data, filename];
};

const requests = {
   get: (url) => instance.get(url).then(responseBody),
   getDoc: (url) =>
      instance
         .get(url, {
            responseType: 'arraybuffer',
            // responseTupe: 'blob',
            timeout: 300000,
         })
         .then(responseBodyDoc),
   post: (url, body) => instance.post(url, body).then(responseBody),
   postDoc: (url, body) =>
      instance
         .post(url, body, {
            responseType: 'arraybuffer',
            timeout: 300000,
         })
         .then(responseBodyDoc),
   put: (url, body) => instance.put(url, body).then(responseBody),
   del: (url) => instance.delete(url).then(responseBody),
   postForm: (url, file, iShipmentId, iPOId, FileType, iId, sDesc) => {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('IShipmentId', iShipmentId);
      formData.append('IPOid', iPOId);
      formData.append('FileType', FileType);
      formData.append('IId', iId);
      formData.append('SDesc', sDesc);
      return instance
         .post(url, formData, {
            headers: { 'Content-type': 'multipart/form-data' },
         })
         .then(responseBody);
   },
   postFormFileProject: (url, file, sProjectIds) => {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('SProjectIds', sProjectIds);
      return instance
         .post(url, formData, {
            headers: { 'Content-type': 'multipart/form-data' },
         })
         .then(responseBody);
   },
   postFormFilePOLineItems: (url, file, bInsert, bUpdate) => {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('BInsert', bInsert);
      formData.append('BUpdate', bUpdate);
      return instance
         .post(url, formData, {
            headers: { 'Content-type': 'multipart/form-data' },
         })
         .then(responseBody);
   },
};

export default { instance, requests, instanceTokenAlternative };
