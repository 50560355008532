import _ from '@lodash';
// Example - how to call in a slice - const params = PaginationModel.PageSize9999ApiModel({ searchText });

function PageSize9999ApiModel(data) {
   data = data || {};

   return _.defaults(data, {
      pageNumber: 1,
      pageSize: 99999,
   });
}

function PageSize300ApiModel(data) {
   data = data || {};

   return _.defaults(data, {
      pageNumber: 1,
      pageSize: 99999,
   });
}

export default { PageSize9999ApiModel, PageSize300ApiModel };
