import axiosConfig from './axiosConfig';
// &dCreated_To=${filter.dCreated_To === null ? '' : formatDate({ value: filter.dCreated_To, lng: 'enUS' })}
// &dCreated_To=${filter.dCreated_To === null ? '' : formatDate({ value: filter.dCreated_To, lng: 'enUS' })}

const apiReport = {
   list: (filter) =>
      axiosConfig.requests.get(`/reports?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&sName=${filter.sName}&IId=${filter.iId}
            `),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sName: '',
      iId: '',
   },
   details: (id) => axiosConfig.requests.get(`/reports/${id}`),
   create: (report) => axiosConfig.requests.post('/reports', report),
   update: (report) => axiosConfig.requests.put(`/reports/${report.id}`, report),
   delete: (id) => axiosConfig.requests.del(`/reports/${id}`),
};

const apiReportEngine = {
   generateReport: (userRequest) => axiosConfig.requests.postDoc(`/reportengine/GenerateReport`, userRequest),
   getSettingsReport: (templateName) => axiosConfig.requests.post(`/reportengine/GetSettingsReport`, templateName),
};

const apiReportTemplate = {
   list: (filter) =>
      axiosConfig.requests
         .get(`/reportstemplate?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&bShowAll=${filter.bShowAll}&IReportId=${filter.iReportId}
            `),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      bShowAll: false,
      iReportId: 0,
   },
   details: (id) => axiosConfig.requests.get(`/reportstemplate/${id}`),
   create: (report) => axiosConfig.requests.post('/reportstemplate', report),
   update: (report) => axiosConfig.requests.put(`/reportstemplate/${report.ReportTemplate.iReportId}`, report),
   delete: (id) => axiosConfig.requests.del(`/reportstemplate/${id}`),
};

const apiOtherReport = {
   generateDocDispatch: (filter) => {
      return axiosConfig.requests.getDoc(`/otherreports/ReportDocDispatch?sProjectId=${filter.sProjectId}
      &sMode=${filter.sMode || ''}
      &iDepPort=${filter.iDepPort || 0}
      &iCarrier=${filter.iCarrier || 0}
      &iSupplier=${filter.iSupplier || 0}
      &dAtdTo=${filter.dAtdTo === null ? '' : filter.dAtdTo}
      &dAtdFrom=${filter.dAtdFrom === null ? '' : filter.dAtdFrom}
            `);
   },
   generateTimelyDispatch: (filter) => {
      return axiosConfig.requests.getDoc(`/otherreports/ReportTimelyDispatch?sProjectId=${filter.sProjectId}     
      &dEtdFrom=${filter.dEtdFrom === null ? '' : filter.dEtdFrom}
      &dEtdTo=${filter.dEtdTo === null ? '' : filter.dEtdTo}
            `);
   },
   generatePOAnalytics: (filter) => {
      return axiosConfig.requests.get(`/otherreports/POAnalytics?sProjectId=${filter.sProjectId}     
      &DReceivFrom=${filter.dReceivFrom === null ? '' : filter.dReceivFrom}
      &DReceivTo=${filter.dReceivTo === null ? '' : filter.dReceivTo}
            `);
   },
};

const apiReportScheduler = {
   list: (filter) => {
      return axiosConfig.requests
         .get(`/ReportScheduleTask?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&sScheduleDescription=${filter.sScheduleDescription}
         &bIsActive=${filter.bIsActive}
         &iSystemCreateUser=${filter.iSystemCreateUser}
         &iReportId=${filter.iReportId}
            `);
   },
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sScheduleDescription: '',
      bIsActive: '',
      iSystemCreateUser: 0,
      iReportId: 0,
   },
   details: (id) => axiosConfig.requests.get(`/ReportScheduleTask/${id}`),
   create: (report) => axiosConfig.requests.post('/ReportScheduleTask', report),
   update: (report) => axiosConfig.requests.put(`/ReportScheduleTask`, report),
   delete: (id) => axiosConfig.requests.del(`/ReportScheduleTask/${id}`),
   saveReportFilters: (report) => axiosConfig.requests.put(`/ReportScheduleTask/SaveReportFilters`, report),
   createRecipient: (report) => axiosConfig.requests.post('/ReportScheduleTask/CreateTaskRecipient', report),
   updateRecipient: (report) => axiosConfig.requests.put(`/ReportScheduleTask/EditTaskRecipient`, report),
   deleteRecipient: (id) => axiosConfig.requests.del(`/ReportScheduleTask/DeleteTaskRecipient/${id}`),
};

export default { apiReport, apiReportEngine, apiReportTemplate, apiOtherReport, apiReportScheduler };
