import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Help = lazy(() => import('./Help'));

const HelpCenterAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.client, // ['admin']
   routes: [
      {
         path: '/client/helpcenter/help',
         element: <Help />,
      },
   ],
};

export default HelpCenterAppConfig;
