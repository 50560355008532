import axiosConfig from './axiosConfig';

const apiEquipmentType = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/equipmentTypes?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&BActive=${filter.bActive}&sDescription=${filter.sDescription}&sMode=${filter.sMode}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sDescription: '',
      sMode: '',
      bActive: true,
   },
   details: (id) => axiosConfig.requests.get(`/equipmentTypes/${id}`),
   create: (type) => axiosConfig.requests.post('/equipmentTypes', type),
   update: (type) => axiosConfig.requests.put(`/equipmentTypes/${type.id}`, type),
   delete: (id) => axiosConfig.requests.del(`/equipmentTypes/${id}`),
};

export default { apiEquipmentType };
