import { formatDate, getGlobalDate } from 'app/utils/FormatDate';
import axiosConfig from './axiosConfig';

const apiDiaryTask = {
   list: (filter) => {
      const paramProjects = filter.iProjectId.length > 0 ? filter.iProjectId.join('&iProjectId=') : 0;

      return axiosConfig.requests.get(
         `/diarytasks?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&iCompanyId=${filter.iCompanyId}&iActionBy=${
            filter.iActionBy
         }&iCreatedBy=${filter.iCreatedBy}
			&iProjectId=${paramProjects}&iPoId=${filter.iPoId}
			&sOrderNo=${filter.sOrderNo}
			&iShipmentId=${filter.iShipmentId}
			&sStatus=${filter.sStatus}&sPriority=${filter.sPriority}
			&dDueFrom=${filter.dDueFrom === null ? '' : formatDate({ value: filter.dDueFrom, lng: 'enUS' })}			
			&dDueTo=${filter.dDueTo === null ? '' : formatDate({ value: filter.dDueTo, lng: 'enUS' })}			
			`
      );
   },
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sTypeDate: 'today',
      iCompanyId: '',
      iActionBy: '',
      sAssignedto: 'Me' /* My Organization, Me, etc. */,
      iProjectId: [],
      iPoId: '',
      sOrderNo: '',
      iShipmentId: '',
      iCreatedBy: '',
      sStatus: 'Incomplete',
      sPriority: '',
      dDueFrom: getGlobalDate(), // new Date(),
      dDueTo: getGlobalDate(),
   },
   details: (id) => axiosConfig.requests.get(`/diarytasks/${id}`),
   create: (fields) => axiosConfig.requests.post('/diarytasks', fields),
   update: (fields) => axiosConfig.requests.put(`/diarytasks/${fields.id}`, fields),
   allocateBulk: (fields) => axiosConfig.requests.put(`/diarytasks/allocateBulk`, fields),
   delete: (id) => axiosConfig.requests.del(`/diarytasks/${id}`),
};

export default { apiDiaryTask };
