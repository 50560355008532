import { lazy } from 'react';

const MainDashboardApp = lazy(() => import('./MainDashboardApp'));

const MainDashboardAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/operator/dashboard/main',
         element: <MainDashboardApp />,
      },
   ],
};

export default MainDashboardAppConfig;
