/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import fuse from './fuse';
import i18n from './i18nSlice';
import user from './userSlice';
import commonAPI from './commonAPI'; /* Priscila */

const createReducer = (asyncReducers) => (state, action) => {
   const combinedReducer = combineReducers({
      fuse,
      i18n,
      user,
      commonAPI /* Priscila */,
      ...asyncReducers,
   });

   /*
	Reset the redux store when user logged out
	 */
   if (action.type === 'user/userLoggedOut') {
      state = undefined; // Priscila before this line was commented
   }

   return combinedReducer(state, action);
};

export default createReducer;
