/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material/styles';
import MaterialTable from '@material-table/core';
import { settingsTable } from 'app/utils/MaterialTableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import SetURL from 'app/utils/SetURL';
import { selectSearchResults } from '../store/advancedSearchSlice';

function EquipmentsTab(props) {
   const dispatch = useDispatch();
   const theme = useTheme();
   const data = useSelector(selectSearchResults)?.data?.equipments || [];
   const currentLanguageLocaleCode = useSelector(({ i18n }) => i18n.localeCode);

   const columns = [
      {
         title: 'Job No.',
         field: 'iShipmentId',
         render: (rowData) => (
            <Typography
               className="flex items-center"
               component={Link}
               onClick={() => dispatch(closeDialog())}
               role="button"
               to={`/${SetURL()}/shipment/shipments/${rowData.iShipmentId}`}
               state="equip"
               color="secondary"
            >
               {rowData.iShipmentId}
            </Typography>
         ),
      },
      {
         title: 'Container No.',
         field: 'sContainerNo',
      },
      {
         title: 'Type',
         field: 'sTypeContainerMode',
      },
      {
         title: 'Waybill No.',
         field: 'sPosWaybillNo',
      },
      {
         title: 'Seal No.',
         field: 'sSealNo',
      },
      {
         title: 'Truck No.',
         field: 'sPosTruckNo',
      },
      {
         title: 'Trailer No.',
         field: 'sPosTrailerNo',
      },
      {
         title: 'ETA Site',
         field: 'dPosSiteEta',
         type: 'date',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
      {
         title: 'Delivered',
         field: 'dDeliveredSite',
         type: 'date',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
   ];

   return (
      <div>
         <MaterialTable
            options={{
               paging: false,
               // pageSize: 10,
               // pageSizeOptions: [10, 20, data.length],
               exportAllData: true,
               toolbar: false,
               showTitle: false,
               maxBodyHeight: '430px',
               ...settingsTable(theme),
            }}
            components={{
               Container: (propsc) => <Paper {...propsc} elevation={0} />,
            }}
            title=""
            columns={columns}
            data={data}
         />
      </div>
   );
}

export default EquipmentsTab;
