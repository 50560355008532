import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { useState, memo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import withReducer from 'app/store/withReducer';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { getSearchResult, setLoadingDialog } from './store/advancedSearchSlice';
import reducer from './store';
import AdvancedSearchResultDialog from './AdvancedSearchResultDialog';

function AdvancedSearchPanel(props) {
   const dispatch = useDispatch();

   const methods = useForm({
      mode: 'onChange',
      defaultValues: {},
   });
   const { reset, watch, control, formState, getValues } = methods;

   const user = useSelector(selectUser);
   const [valueTerm, setValueTerm] = useState('');
   const [errorValue, setErrorValue] = useState('');

   const handleChange = (event) => {
      setValueTerm(event.target.value);
   };

   const searchTerm = () => {
      if (valueTerm === '') return setErrorValue('Enter a search term..');
      setErrorValue('');

      dispatch(setLoadingDialog(true));
      dispatch(getSearchResult(valueTerm)).then(() => dispatch(setLoadingDialog(false)));
      dispatch(openDialogResultSearch);
   };

   const openDialogResultSearch = () => {
      return dispatch(
         openDialog({
            children: (
               <>
                  <AdvancedSearchResultDialog />
               </>
            ),
         })
      );
   };

   return (
      <FormProvider {...methods}>
         <form onSubmit={methods.handleSubmit(searchTerm)} className="flex w-full">
            <div className={clsx('flex items-center w-full border-0', props.className)}>
               <TextField
                  fullWidth
                  onChange={handleChange}
                  placeholder={
                     user.role !== 'client'
                        ? 'Search by BL, AWB, Contr No., Vessel, Package No., SCN, PO No., Job No., Organization or Project'
                        : 'Search by BL, AWB, Contr No., Vessel, Package No., SCN, PO No. or Job No.'
                  }
                  InputProps={{
                     endAdornment: (
                        <InputAdornment position="end">
                           <IconButton className="w-40 h-40" color="secondary" type="submit">
                              <FuseSvgIcon className="h-40 md:h-24 w-24 pointer-events-none">heroicons-outline:search</FuseSvgIcon>
                           </IconButton>
                        </InputAdornment>
                     ),
                     classes: {
                        input: 'FuseSearch-input py-0 px-16 h-40 md:h-48 ltr:pr-48 rtl:pl-48',
                        notchedOutline: 'rounded-8',
                     },
                  }}
                  error={errorValue !== ''}
                  helperText={errorValue !== '' ? errorValue : ''}
                  variant="outlined"
               />
            </div>
         </form>
      </FormProvider>
   );
}

export default withReducer('advancedSearchPanel', reducer)(memo(AdvancedSearchPanel));
