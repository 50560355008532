import Icon from '@mui/material/Icon';

function StatusIconActiveInactive(props) {
   switch (props.status) {
      case 'Active':
      case true:
         return <Icon className="text-20 text-green bg-white rounded-full">check_circle</Icon>;
      case 'Inactive':
      case false:
         return <Icon className="text-20 text-red bg-white rounded-full">remove_circle</Icon>;
      default:
         return null;
   }
}

export default StatusIconActiveInactive;
