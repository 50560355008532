/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { memo, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { TextField, Button, Paper } from '@mui/material';
import { Controller } from 'react-hook-form';

function ControllerAutoComplete(props) {
   const {
      options = [],
      getOptionLabel,
      required = false,
      control,
      name,
      renderOption,
      limitTags = 2,
      className,
      label,
      variant,
      disableCloseOnSelect = false,
      multiple = false,
      optionId, // created apart
      onChange, // used when I want to call other functions together
   } = props;

   const varOptionId = optionId === undefined ? 'iId' : optionId;
   const [inputValue, setInputValue] = useState('');
   const [items, setValue] = useState([]);

   const filterOptionsCustom = createFilterOptions({
      matchFrom: 'any',
      limit: 100,
   });

   const transformValue = (value, fieldtosave) => {
      if (Array.isArray(value)) {
         return value.map((v) => v?.[fieldtosave] || v);
      }

      return value[fieldtosave];
   };

   const MyPopper = (props1) => {
      const addAllClick = (e) => {
         setValue(items);
      };
      const clearClick = (e) => {
         setValue([]);
      };

      return (
         <Paper {...props1} elevation={1}>
            <Button color="primary" onClick={addAllClick}>
               Select All
            </Button>
            {props1.children}
         </Paper>
      );
   };

   return (
      <Controller
         name={name}
         control={control}
         render={({ field, fieldState: { error }, formState }) => (
            <Autocomplete
               {...field}
               // PaperComponent={MyPopper}
               limitTags={limitTags}
               // renderTags={() => null}
               /* renderTags={(value, getTagProps) => {
                  const numTags = value.length;

                  return <Typography className="pr-10" variant="body2">{` +${numTags} selected `}</Typography>;
               }} */
               {...(multiple && {
                  renderTags: (value, getTagProps) => {
                     const numTags = value.length;

                     return <Typography className="pr-10" variant="body2">{` +${numTags} selected `}</Typography>;
                  },
               })}
               className={className}
               options={options}
               {...(multiple && {
                  inputValue,
               })}
               // inputValue={inputValue}
               getOptionLabel={getOptionLabel}
               // value={field.value && _.find(options, { iId: field.value })}
               value={
                  // eslint-disable-next-line no-nested-ternary
                  multiple
                     ? field.value
                        ? field.value.map((v) =>
                             options.find((x) => {
                                return x[varOptionId] === (v?.[varOptionId] === undefined ? v : v[varOptionId]);
                             })
                          )
                        : []
                     : (field.value || '') &&
                       options.find((x) => {
                          return x[varOptionId] === field.value;
                       })
               }
               renderInput={(params) => (
                  <TextField
                     {...params}
                     id={name}
                     // onChange={(e) => setInputValue(e.target.value)}
                     {...(multiple && {
                        onChange: (e) => setInputValue(e.target.value),
                     })}
                     label={label}
                     required={!!required}
                     size="small"
                     error={!!error}
                     helperText={error ? error.message : null}
                     variant={variant || 'outlined'}
                     fullWidth
                  />
               )}
               renderOption={renderOption}
               onChange={(event, newValue) => {
                  newValue === null
                     ? field.onChange('')
                     : newValue !== ''
                     ? field.onChange(transformValue(newValue, varOptionId))
                     : field.onChange(newValue);

                  if (onChange) onChange(newValue); // when I want to call other functions together
                  /* if (newValue === null) field.onChange('');
						else if (newValue !== '') field.onChange(newValue[varOptionId]);
						else field.onChange(newValue);
						*/
               }}
               isOptionEqualToValue={(option, value) => {
                  if (!multiple) {
                     if (value === '') return true; // this disappeared with warn message
                     if (option[varOptionId] === value);
                     return true;
                  }

                  if (value === undefined) return true;
                  if (option[varOptionId] === value[varOptionId]) return true;
                  // const index = field.value.findIndex((item) => item === option[varOptionId]);
                  // if (index > -1) {
                  //  return true;
                  // }

                  return false;
               }}
               filterOptions={filterOptionsCustom}
               disableCloseOnSelect={disableCloseOnSelect}
               multiple={multiple}
               style={{ width: '100%' }}
            />
         )}
      />
   );
}

export default memo(ControllerAutoComplete);

/* Example

<ControllerAutoComplete							
							name="iConsigneeId"
							className="mt-8 mb-16"
							options={companies}
							label="Consignee"
							getOptionLabel={option => option.sName}
							renderOption={option => (
								<span>
									{option.sCode} -{option.sName}
								</span>
							)}
						/>
*/
