/* eslint-disable import/no-cycle */
import { combineReducers } from '@reduxjs/toolkit';
import companies from './companiesCommonSlice';
import agents from './agentsCommonSlice';
import carriers from './carriersCommonSlice';
import projects from './projectsCommonSlice';
import countries from './countriesCommonSlice';
import currencies from './currenciesCommonSlice';
import ports from './portsCommonSlice';
import operators from './operatorsCommonSlice';
import incoterms from './incotermsCommonSlice';
import unititems from './unitItemsCommonSlice';
import confirmationDialog from './confirmationDialogSlice';
import equipmentTypes from './equipmentTypesCommonSlice';
import historyNavigation from './historyNavigationSlice';
import purchaseorders from './purchaseordersCommonSlice';
import shipments from './shipmentsCommonSlice';

const Commonreducers = combineReducers({
   companies,
   agents,
   carriers,
   projects,
   countries,
   currencies,
   ports,
   operators,
   incoterms,
   unititems,
   confirmationDialog,
   equipmentTypes,
   historyNavigation,
   purchaseorders,
   shipments,
});

export default Commonreducers;
