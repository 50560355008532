/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material/styles';
import MaterialTable from '@material-table/core';
import { settingsTable } from 'app/utils/MaterialTableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import SetURL from 'app/utils/SetURL';
import { shipmentStatus } from 'app/utils/Collections/shipmentStatus';
import { selectSearchResults } from '../store/advancedSearchSlice';

function ShipmentsTab(props) {
   const dispatch = useDispatch();
   const theme = useTheme();
   const data = useSelector(selectSearchResults)?.data?.shipments || [];
   const currentLanguageLocaleCode = useSelector(({ i18n }) => i18n.localeCode);

   const columns = [
      {
         title: 'Job No.',
         field: 'id',
         render: (rowData) => (
            <Typography
               className="flex items-center"
               component={Link}
               onClick={() => dispatch(closeDialog())}
               role="button"
               to={`/${SetURL()}/shipment/shipments/${rowData.id}`}
               color="secondary"
            >
               {rowData.id}
            </Typography>
         ),
      },
      {
         title: 'Project',
         field: 'sProjectCod',
      },
      {
         title: 'CW Job No.',
         field: 'sShipmentCW',
      },
      {
         title: 'Vessel / Flight',
         field: 'sVessel',
         align: 'left',

         render: (rowData) => (
            <Typography>
               {rowData.sVessel} {rowData.sVoyNo}
            </Typography>
         ),
      },
      { title: 'Bill/AWB', field: 'sBillAwb', align: 'left' },
      {
         title: 'Status',
         field: 'sStatus',
         render: (rowData) => shipmentStatus.filter((item) => item.value === rowData.sStatus).map((filtered) => filtered.label),
      },
      { title: 'Departure', field: 'sDepPortName', align: 'left' },
      { title: 'Arrival', field: 'sArrPortName', align: 'left' },
      {
         title: 'ETD',
         field: 'dEtd',
         align: 'left',
         type: 'date',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
      {
         title: 'Revised ETD',
         field: 'dInitialEtd',
         align: 'left',
         type: 'date',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
      {
         title: 'ATD',
         field: 'dAtd',
         align: 'left',
         type: 'date',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
      {
         title: 'ETA',
         field: 'dEta',
         align: 'left',
         type: 'date',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
      {
         title: 'Revised ETA',
         field: 'dInitialEta',
         align: 'left',
         type: 'date',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
      {
         title: 'ATA',
         field: 'dAta',
         type: 'date',
         align: 'left',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
      {
         title: 'ETA Site',
         field: 'dETASite',
         type: 'date',
         align: 'left',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
   ];

   return (
      <div>
         <MaterialTable
            options={{
               paging: false,
               // pageSize: 10,
               // pageSizeOptions: [10, 20, data.length],
               exportAllData: true,
               toolbar: false,
               showTitle: false,
               maxBodyHeight: '430px',
               ...settingsTable(theme),
            }}
            components={{
               Container: (propsc) => <Paper {...propsc} elevation={0} />,
            }}
            title=""
            columns={columns}
            data={data}
         />
      </div>
   );
}

export default ShipmentsTab;
