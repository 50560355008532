import axiosConfig from './axiosConfig';

const apiProject = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/projects?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&SStatus=${filter.status}&sName=${filter.name}&SCode=${filter.code}
			&SPrincipalClientName=${filter.principalclientname}&SCWDept=${filter.cwdept}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      status: '',
      name: '',
      code: '',
      principalclientname: '',
      cwdept: '',
   },
   details: (id) => axiosConfig.requests.get(`/projects/${id}`),
   create: (project) => axiosConfig.requests.post('/projects', project),
   update: (project) => axiosConfig.requests.put(`/projects/${project.id}`, project),
   delete: (id) => axiosConfig.requests.del(`/projects/${id}`),
   updateExpeitingEmail: (email, project) => axiosConfig.requests.put(`/projects/${project.id}/expeditingemail`, email),
   listPriorityCodes: (id, code) => axiosConfig.requests.get(`/projects/listprioritycodes/SProjectCode=${code}&IProjectId=${id}`),
};

const apiProjectAgents = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/projectagents?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&IProjectId=${filter.iprojectId}&SProjectCode=${filter.scode}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iprojectId: 0,
      scode: '',
   },
   details: (id) => axiosConfig.requests.get(`/projectagents/${id}`),
   create: (agent) => axiosConfig.requests.post('/projectagents', agent),
   update: (agent) => axiosConfig.requests.put(`/projectagents/${agent.id}`, agent),
   delete: (id) => axiosConfig.requests.del(`/projectagents/${id}`),
};

const apiProjectCWARDebtors = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/projectCWARdebtors?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&IProjectId=${filter.iprojectId}&SProjectCode=${filter.scode}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iprojectId: 0,
      scode: '',
   },
   details: (id) => axiosConfig.requests.get(`/projectCWARdebtors/${id}`),
   create: (form) => axiosConfig.requests.post('/projectCWARdebtors', form),
   update: (form) => axiosConfig.requests.put(`/projectCWARdebtors/${form.id}`, form),
   delete: (id) => axiosConfig.requests.del(`/projectCWARdebtors/${id}`),
};

const apiProjectTrackingFields = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/projecttrackingfields?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&IProjectId=${filter.iprojectId}&SProjectCode=${filter.scode}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iprojectId: 0,
      scode: '',
   },
   details: (id) => axiosConfig.requests.get(`/projecttrackingfields/${id}`),
   create: (fields) => axiosConfig.requests.post('/projecttrackingfields', fields),
   update: (field) => axiosConfig.requests.put(`/projecttrackingfields/${field.iId}`, field),
   delete: (id) => axiosConfig.requests.del(`/projecttrackingfields/${id}`),
};

const apiProjectNotifyParty = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/projectnotifyparty?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&IProjectId=${filter.iprojectId}&SProjectCode=${filter.scode}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iprojectId: 0,
      scode: '',
   },
   create: (notifyparty) => axiosConfig.requests.post('/projectnotifyparty', notifyparty),
   delete: (id) => axiosConfig.requests.del(`/projectnotifyparty/${id}`),
};

const apiProjectUserAccess = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/projectUserAccess?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&IProjectId=${filter.iprojectId}&SProjectCode=${filter.scode}
			&ICompanyId=${filter.icompanyId}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iprojectId: 0,
      icompanyId: 0,
      scode: '',
   },
   details: (id) => axiosConfig.requests.get(`/projectUserAccess/${id}`),
   create: (users) => axiosConfig.requests.post('/projectUserAccess', users),
   update: (user) => axiosConfig.requests.put(`/projectUserAccess/${user.iId}`, user),
   delete: (id) => axiosConfig.requests.del(`/projectUserAccess/${id}`),
};

const apiProjectUserETANotif = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/projectuseretanotif?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&IProjectId=${filter.iprojectId}&SProjectCode=${filter.scode}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iprojectId: 0,
      scode: '',
   },
   create: (users) => axiosConfig.requests.post('/projectuseretanotif', users),
   delete: (id) => axiosConfig.requests.del(`/projectuseretanotif/${id}`),
};

export default {
   apiProject,
   apiProjectAgents,
   apiProjectTrackingFields,
   apiProjectNotifyParty,
   apiProjectUserETANotif,
   apiProjectUserAccess,
   apiProjectCWARDebtors,
};
