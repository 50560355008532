import { format as formatDateFns, formatRelative, formatDistance } from 'date-fns';
import { locales } from 'src/i18n';
import { utcToZonedTime } from 'date-fns-tz'; // Added import for time zone conversion
import store from 'app/store';

export const formatDate = ({ value, format = 'short', lng }) => {
   const currentLanguageId = store.getState().i18n.language;
   let finalLanguage = currentLanguageId;
   if (lng && lng !== '') {
      finalLanguage = lng;
   }

   let date = value; //
   if (value && value !== null) {
      // if (isDate(value)) {
      const locale = locales[finalLanguage];

      if (format === 'shortMonthYear') return formatDateFns(new Date(value), 'MM/yyyy', { locale });
      if (format === 'short') return formatDateFns(new Date(value), 'P', { locale });
      if (format === 'shortTime') return formatDateFns(new Date(value), 'Ppp', { locale });
      if (format === 'long') return formatDateFns(new Date(value), 'PPPP', { locale });
      if (format === 'long3') return formatDateFns(new Date(value), 'dd/MMM/yyyy', { locale });
      if (format === 'long2') return formatDateFns(new Date(value), 'PP', { locale });
      if (format === 'relative') return formatRelative(new Date(value), new Date(), { locale });
      if (format === 'hourminutesecond') return formatDateFns(new Date(value), 'HH:mm:ss', { locale });
      if (format === 'ago')
         return formatDistance(value, new Date(), {
            locale,
            addSuffix: true,
         });

      date = formatDate(new Date(value), format, { locale });
   }

   return date;
};

// to initialize the date
export function getGlobalDate() {
   //  for TimeZone - data is stored in Australia/Perth time zone. However, in the application,
   // if it is Canada, we must convert some dates like new Date(), for search and check like Diary, not for saving. We can't convert all dates because it doesn't make sense
   // const windowsTimeZone = timeZone.australiaPerth;
   const currentTimezone = store.getState().user?.data?.timeZone || 'Australia/Perth';

   // Get the current date in the Australia/Perth time zone
   const currentDatePerth = new Date();

   // Convert the Australia/Perth date to UTC
   // const currentUtcDate = zonedTimeToUtc(currentDatePerth, windowsTimeZone);
   // Convert the UTC date to the Vancouver time zone
   const currentDate = currentTimezone === 'Australia/Perth' ? currentDatePerth : utcToZonedTime(currentDatePerth, currentTimezone);

   return currentDate;
}

// for onChanging the date and convert it to the timzone
export function onChangeTimeZone(e) {
   let currentDate = e;
   if (e && e !== null && e !== undefined) {
      const currentTimezone = store.getState().user?.data?.timeZone || 'Australia/Perth';
      currentDate = currentTimezone === 'Australia/Perth' ? e : utcToZonedTime(e, currentTimezone);
   }
   return currentDate;
}

export default { formatDate, getGlobalDate, onChangeTimeZone };
