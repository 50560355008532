import { lazy } from 'react';

const ProjectsDashboardApp = lazy(() => import('./ProjectsDashboardApp'));

const ProjectsDashboardAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/client/dashboards/projects',
         element: <ProjectsDashboardApp />,
      },
   ],
};

export default ProjectsDashboardAppConfig;
