import { combineReducers } from '@reduxjs/toolkit';
import diarytasks from './diarytasksSlice';
import diarytask from './diarytaskSlice';

const reducer = combineReducers({
   diarytasks,
   diarytask,
});

export default reducer;
