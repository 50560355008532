import { lazy } from 'react';

const PortPage = lazy(() => import('./PortPage'));

const PortPageConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/operator/masterdata/ports',
         element: <PortPage />,
      },
   ],
};

export default PortPageConfig;
