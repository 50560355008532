import DashboardAppConfig from './dashboards/dashboardsConfigs';
import PurchaseOrderAppConfig from './purchaseorder/PurchaseOrderAppConfig';
import ShipmentAppConfig from './shipment/ShipmentAppConfig';
import AccountingAppConfig from './accounting/AccountingAppConfig';
import MapAppConfig from './map/MapAppConfig';
import HelpCenterAppConfig from './helpcenter/HelpCenterAppConfig';

const ClientConfigs = [...DashboardAppConfig, PurchaseOrderAppConfig, ShipmentAppConfig, AccountingAppConfig, MapAppConfig, HelpCenterAppConfig];

export default ClientConfigs;
