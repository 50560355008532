import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const PurchaseOrder = lazy(() => import('./purchaseorder/PurchaseOrder'));
const PurchaseOrders = lazy(() => import('./purchaseorders/PurchaseOrders'));

const PurchaseOrderAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.client, // ['admin']
   routes: [
      {
         path: '/client/purchaseorder/purchaseorders/:poId',
         element: <PurchaseOrder />,
      },
      {
         path: '/client/purchaseorder/purchaseorders',
         element: <PurchaseOrders />,
      },

      {
         path: '/client/purchaseorder',
         element: <PurchaseOrders />,
      },
   ],
};

export default PurchaseOrderAppConfig;
