/* eslint-disable no-nested-ternary */
import { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import CustomButton from 'app/shared-components/CustomButton';
import { formatDate, onChangeTimeZone } from 'app/utils/FormatDate';
import useConfirmationManagement from 'app/shared-components/ConfirmationDialog/useConfirmationManagement';
import IconPopover from 'app/shared-components/IconPopover';
import { saveTask, removeTask, setRightSidebarOpen } from '../store/diarytaskSlice';

function DiaryTaskFormHeader(props) {
   const dispatch = useDispatch();
   const methods = useFormContext();
   const { formState, control, getValues, register, setValue, handleSubmit } = methods;
   const navigate = useNavigate();
   const routeParams = useParams();
   const { taskId } = routeParams;

   const logCreated = formatDate({ value: onChangeTimeZone(getValues('dCreated')), format: 'shortTime' });
   const logUpdated = formatDate({ value: onChangeTimeZone(getValues('dUpdated')), format: 'shortTime' });

   // delete
   const confirmDelete = useConfirmationManagement().open;

   function handleSaveTask(data) {
      dispatch(saveTask(getValues()));
   }

   const handleRemoveTask = useCallback(() => {
      confirmDelete({
         function: () => {
            dispatch(removeTask(getValues('iId'))).then(() => {
               navigate(
                  Boolean(routeParams.taskId) && Boolean(routeParams.shipId)
                     ? `/operator/shipment/shipments/${routeParams.shipId}`
                     : Boolean(routeParams.taskId) && Boolean(routeParams.poId)
                     ? `/operator/purchaseorder/purchaseorders/${routeParams.poId}`
                     : '/operator/diarytask'
               );
            });
         },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   /*   const handleNewTask = () => {
      dispatch(newTask());
      if (taskId !== 'New') history.push('/operator/diarytask/new');
   };
*/

   return (
      <div className="flex items-center justify-end w-full align-top">
         <CustomButton className="whitespace-nowrap mx-4" text="Save Diary" onClick={handleSubmit(handleSaveTask)} size="small" type="submit" />
         {getValues('iId') !== 0 && Boolean(routeParams.shipId) === false && Boolean(routeParams.poId) === false && (
            <CustomButton
               className="whitespace-nowrap mx-4"
               text="Add Diary"
               onClick={() => navigate(`/operator/diarytask/new/`, { state: getValues() })}
               size="small"
               // type="submit"
            />
         )}

         {getValues('iId') !== 0 && (
            <CustomButton
               size="small"
               className="whitespace-nowrap mx-4"
               color="error"
               variant="outlined"
               text="Delete Diary"
               onClick={handleRemoveTask}
               startIcon={<Icon className="hidden sm:flex">delete</Icon>}
            />
         )}

         {/* Log - Creation and Update */}
         <div className="flex flex-row align-top">
            {getValues('iId') > 0 && (
               <div>
                  {/** SHOW POPOER WITH INFO LOG */}
                  <IconPopover
                     key="DiaryHeaderDetails"
                     id="DiaryHeaderDetails"
                     content={
                        <>
                           <div className="flex flex-row p-10 items-center">
                              <FuseSvgIcon color="action" size={16}>
                                 heroicons-outline:plus
                              </FuseSvgIcon>
                              <Typography color="textSecondary" className="text-base  mx-4">
                                 Created by {getValues('sNameCreatedBy')} on
                              </Typography>
                              <FuseSvgIcon color="action" size={16}>
                                 heroicons-outline:clock
                              </FuseSvgIcon>
                              <Typography color="textSecondary" className="text-base  mx-4">
                                 {logCreated}
                              </Typography>
                           </div>
                           <div className="flex flex-row p-10 items-center">
                              {getValues('sNameUpdatedBy') !== null && getValues('sNameUpdatedBy') !== '' && (
                                 <div className="flex flex-row  items-center">
                                    <FuseSvgIcon color="action" size={16}>
                                       heroicons-outline:pencil
                                    </FuseSvgIcon>
                                    <Typography color="textSecondary" className="text-base  mx-4">
                                       Updated by {getValues('sNameUpdatedBy')} on
                                    </Typography>
                                    <FuseSvgIcon color="action" size={16}>
                                       heroicons-outline:clock
                                    </FuseSvgIcon>
                                    <Typography color="textSecondary" className="text-base  mx-4">
                                       {logUpdated}
                                    </Typography>
                                 </div>
                              )}
                           </div>
                        </>
                     }
                  />
               </div>
            )}
         </div>

         {/* Close right side bar */}
         <div className="flex items-center pl-10">
            <IconButton
               className=""
               // component={NavLinkAdapter}
               /* ) to={
                  Boolean(routeParams.taskId) && Boolean(routeParams.shipId)
                     ? `/operator/shipment/shipments/${routeParams.shipId}`
                     : Boolean(routeParams.taskId) && Boolean(routeParams.poId)
                     ? `/operator/purchaseorder/purchaseorders/${routeParams.poId}`
                     : '/operator/diarytask'
               } */
               size="large"
               onClick={() => dispatch(setRightSidebarOpen(false))}
            >
               <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
            </IconButton>
         </div>
      </div>
   );
}

export default DiaryTaskFormHeader;
