import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

/** Priscila - 17.09.2021 */
import { format as formatDate, formatRelative, formatDistance, isDate } from 'date-fns';
import { es } from 'date-fns/locale';
import enUS from 'date-fns/locale/en-US';
import enAU from 'date-fns/locale/en-AU';
import enCA from 'date-fns/locale/en-CA';
// Added import for time zone conversion

enAU.options.weekStartsOn = 0;

export const locales = { enAU, enUS, es, enCA }; // used in shared-component - datepicker too

export const localesCode = { enAU: 'en-AU', enUS: 'en-US', es: 'es', enCA: 'en-CA' }; // Priscila

export const localeFormatShortMap = {
   enAU: 'dd/MM/yyyy',
   enCA: 'dd/MM/yyyy',
   enUS: 'MM/dd/yyyy',
   es: 'dd/MM/yyyy',
};

/** Priscila - 17.09.2021 */
// the translations
// (tip move them in a JSON file and import them)
const resources = {
   en: {
      translation: {
         'Welcome to React': 'Welcome to React and react-i18next',
      },
   },
};

i18n
   .use(initReactI18next) // passes i18n down to react-i18next
   .init({
      resources,
      lng: 'enAU',
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
         escapeValue: false, // react already safes from xss

         /* Priscila added on 17.09.2021 */
         format: (value, format, lng) => {
            if (isDate(value)) {
               const locale = locales[lng];

               if (format === 'short') return formatDate(value, 'P', { locale });
               if (format === 'long') return formatDate(value, 'PPPP', { locale });
               if (format === 'relative') return formatRelative(value, new Date(), { locale });
               if (format === 'ago')
                  return formatDistance(value, new Date(), {
                     locale,
                     addSuffix: true,
                  });

               return formatDate(value, format, { locale });
            }

            return value;
         },
         /** end Priscila */
      },
   });

export default i18n;
