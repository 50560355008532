import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import useConfirmationManagement from 'app/shared-components/ConfirmationDialog/useConfirmationManagement';

function ConfirmationDialog() {
   const { isOpened, confirm, decline, text = "Are you sure? This can't be undone." } = useConfirmationManagement();
   return (
      <>
         <form>
            <Dialog open={isOpened} onClose={decline}>
               <DialogTitle id="alert-dialog-title">
                  <Icon className="text-14 mx-4" color="inherit">
                     arrow_forward_ios
                  </Icon>
                  Confirmation
               </DialogTitle>
               <DialogContent>
                  <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
               </DialogContent>
               <DialogActions>
                  <Button onClick={confirm}>Yes</Button>
                  <Button onClick={decline}>No</Button>
               </DialogActions>
            </Dialog>
         </form>
      </>
   );
}

export default ConfirmationDialog;
