import { formatDate } from 'app/utils/FormatDate';
import axiosConfig from './axiosConfig';

const apiShipment = {
   list: (filter) => {
      const paramShipmentStatus = filter.sShipmentStatus.length > 0 ? filter.sShipmentStatus.join('&sShipmentStatus=') : '';

      const paramProjects = filter.sProjectCode.length > 0 ? filter.sProjectCode.join('&sProjectCode=') : '';

      return axiosConfig.requests.get(
         `/shipments?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}
			&sProjectCode=${paramProjects}&iProjectId=${filter.iProjectId}
			&iShipmentId=${filter.iShipmentId}&sBillOfLading=${filter.sBillOfLading}
			&sShipmentStatus=${paramShipmentStatus}
			&sBookingReference=${filter.sBookingReference}
			&sCargo=${filter.sCargo}&iDepPort=${filter.iDepPort}
			&dETD_From=${filter.dETD_From === null ? '' : formatDate({ value: filter.dETD_From, lng: 'enUS' })}
			&dETD_To=${filter.dETD_To === null ? '' : formatDate({ value: filter.dETD_To, lng: 'enUS' })}
			&dETA_From=${filter.dETA_From === null ? '' : formatDate({ value: filter.dETA_From, lgn: 'enUS' })}
			&dETA_To=${filter.dETA_To === null ? '' : formatDate({ value: filter.dETA_To, lng: 'enUS' })}
			&dDeliveredSite_From=${filter.dDeliveredSite_From === null ? '' : formatDate({ value: filter.dDeliveredSite_From, lng: 'enUS' })}
			&dDeliveredSite_To=${filter.dDeliveredSite_To === null ? '' : formatDate({ value: filter.dDeliveredSite_To, lng: 'enUS' })}
			&dCreated_From=${filter.dCreated_From === null ? '' : formatDate({ value: filter.dCreated_From, lng: 'enUS' })}
			&dCreated_To=${filter.dCreated_To === null ? '' : formatDate({ value: filter.dCreated_To, lng: 'enUS' })}
			&iLoginOperator=${filter.iLoginOperator}&sOrderNo=${filter.sOrderNo}
			&sVessel=${filter.sVessel}&sPackageNo=${filter.sPackageNo}
			&sSupplierInvoiceNo=${filter.sSupplierInvoiceNo}&sSupplierPacklistNo=${filter.sSupplierPacklistNo}
			&sContainerNo=${filter.sContainerNo}&sPosTruckNo=${filter.sPosTruckNo}
         &sSCN=${filter.sSCN}&sCWShipment=${filter.sCWShipment}
			`
      );
   },
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sProjectCode: [],
      iProjectId: '',
      iShipmentId: '',
      sCWShipment: '',
      sBillOfLading: '',
      sBookingReference: '',
      sShipmentStatus: [],
      sCargo: '',
      iDepPort: '',
      dETD_From: null,
      dETD_To: null,
      dETA_From: null,
      dETA_To: null,
      dDeliveredSite_From: null,
      dDeliveredSite_To: null,
      dCreated_From: null,
      dCreated_To: null,
      iLoginOperator: '',
      sOrderNo: '',
      sVessel: '',
      sModeOfTransport: '',
      sPackageNo: '',
      sSupplierInvoiceNo: '',
      sSCN: '',
      sSupplierPacklistNo: '',
      sContainerNo: '',
      sPosTruckNo: '',
   },

   getShipmentsToTransferPacks: (id) => axiosConfig.requests.get(`/shipments/getShipmentsToTransferPacks?id=${id}`),
   details: (id) => axiosConfig.requests.get(`/shipments/${id}`),
   create: (po) => axiosConfig.requests.post('/shipments/', po),
   update: (po) => axiosConfig.requests.put(`/shipments/edit`, po),
   delete: (id) => axiosConfig.requests.del(`/shipments/${id}`),
   getAllData: (filter) => {
      const arShipments = filter.sShipments.length > 0 ? filter.sShipments.join(',') : '';

      return axiosConfig.requests.get(`/shipments/getAllData?SShipments=${arShipments}
         &iPOId=${filter.iPOId || 0}			
            `);
   },
   supervisorEmail: (fields) => axiosConfig.requests.post(`/shipments/SupervisorEmail`, fields),
   exportManifest: (field) =>
      axiosConfig.requests.getDoc(
         `/shipments/ExportManifest?IShipmentId=${field.id}&bAddProject=${field.bAddProject}&bAddSCN=${field.bAddSCN}&bAddRemark=${field.bAddRemark}`
      ),
   exportCombinedManifest: (ids) => {
      const paramIShipmentIds = ids.length > 0 ? ids.join('&ids=') : '';

      return axiosConfig.requests.getDoc(`/shipments/ExportCombinedManifest?ids=${paramIShipmentIds}`);
   },
   exportForwardInstruction: (id) => axiosConfig.requests.getDoc(`/shipments/ExportForwardingInstruction?IShipmentId=${id}`),
   sendPreAlert: (id) => axiosConfig.requests.post(`/shipments/PreAlertEmail?IShipmentId=${id}`),
};

const apiPackages = {
   list: (filter) => {
      const filterShipmentArray = filter.iShipmentId.length > 0 ? filter.iShipmentId.join('&iShipmentId=') : '';

      return axiosConfig.requests.get(
         `/ShipmentPackages?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}			
			&iShipmentId=${filterShipmentArray}
			`
      );
   },
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iShipmentId: '',
   },
   details: (id) => axiosConfig.requests.get(`/ShipmentPackages/${id}`),
   create: (pack) => axiosConfig.requests.post('/ShipmentPackages/', pack),
   update: (pack) => axiosConfig.requests.put(`/ShipmentPackages/edit`, pack),
   updateBulk: (packs) => axiosConfig.requests.put(`/ShipmentPackages/editbulk`, packs),
   delete: (ids) => {
      const paramIds = ids.length > 0 ? ids.join('&id=') : '';
      axiosConfig.requests.del(`/ShipmentPackages/delete?id=${paramIds}`);
   },
   manifest: (pack) => axiosConfig.requests.put(`/ShipmentPackages/manifest`, pack),
   transferpack: (pack) => axiosConfig.requests.post(`/ShipmentPackages/transferPackage`, pack),
};

const apiLegs = {
   list: (filter) => {
      return axiosConfig.requests.get(
         `/ShipmentLegs?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}			
			&iShipmentId=${filter.iShipmentId}
			`
      );
   },
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iShipmentId: '',
   },
   details: (id) => axiosConfig.requests.get(`/ShipmentLegs/${id}`),
   create: (leg) => axiosConfig.requests.post('/ShipmentLegs/', leg),
   update: (leg) => axiosConfig.requests.put(`/ShipmentLegs/edit`, leg),
   delete: (id) => axiosConfig.requests.del(`/ShipmentLegs/${id}`),
};

const apiEquipments = {
   list: (filter) => {
      return axiosConfig.requests.get(
         `/ShipmentEquipments?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}			
			&iShipmentId=${filter.iShipmentId}
			`
      );
   },
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iShipmentId: '',
   },
   details: (id) => axiosConfig.requests.get(`/ShipmentEquipments/${id}`),
   create: (equip) => axiosConfig.requests.post('/ShipmentEquipments/', equip),
   update: (equip) => axiosConfig.requests.put(`/ShipmentEquipments/edit`, equip),
   delete: (id) => axiosConfig.requests.del(`/ShipmentEquipments/${id}`),
};

const apiInvoices = {
   details: (id) => axiosConfig.requests.get(`/ShipmentInvoice/${id}`),
   update: (inv) => axiosConfig.requests.put(`/ShipmentInvoice/edit`, inv),
   // actions = Invoice, UpdateRemark, REInvoice, GenerateInvoice
   updateActions: (inv) => axiosConfig.requests.put(`/ShipmentInvoice/invoice`, inv),
   exportInvoiceSummary: (id) => axiosConfig.requests.getDoc(`/ShipmentInvoice/ExportInvoiceSummaryExcel?IShipmentId=${id}`),
   exportInvoice: (fields) =>
      axiosConfig.requests.getDoc(
         `/ShipmentInvoice/ExportInvoiceExcel?iShipmentId=${fields.iShipmentId}&iVersion=${fields.iVersion}&iConsigneeInv=${
            fields.iConsigneeInv
         }&bCurrency=${fields.bCurrency || false}&bShipperPO=${fields.bShipperPO || false}&bShowLineItem=${fields.bShowLineItem || false}`
      ),
   exportInvoice2Model: (fields) =>
      axiosConfig.requests.getDoc(
         `/ShipmentInvoice/ExportInvoiceExcel2Model?iShipmentId=${fields.iShipmentId}&iVersion=${fields.iVersion}&iConsigneeInv=${
            fields.iConsigneeInv
         }&bCurrency=${fields.bCurrency || false}&bShipperPO=${fields.bShipperPO || false}`
      ),
};

const apiCWIntegration = {
   list: (iShipmentId) => axiosConfig.requests.get(`/ShipmentCWIntegration?iShipmentId=${iShipmentId}`),
   updateNotSend: (fields) => axiosConfig.requests.put(`/ShipmentCWIntegration/editcwflagnotsend`, fields),
   sendShipmenttoCW: (iShipmentId) => axiosConfig.requests.get(`/ShipmentCWIntegration/SendShipmenttoCW?iShipmentId=${iShipmentId}`),
   downloadCwInvoice: (form) => axiosConfig.requests.getDoc(`/ShipmentCWIntegration/DownloadCWInvoice?key=${form.key}&docPK=${form.docPK}`),
   listInvoices: (filter) => {
      return axiosConfig.requests.get(`/ShipmentCWIntegration/ListInvoices?iShipmentId=${filter.iShipmentId}
         &sStatus=${filter.sStatus}
         &dFrom=${filter.dFrom === null ? '' : formatDate({ value: filter.dFrom, lng: 'enUS' })}
			&dTo=${filter.dTo === null ? '' : formatDate({ value: filter.dTo, lng: 'enUS' })}
            `);
   },
};

export default {
   apiShipment,
   apiPackages,
   apiLegs,
   apiEquipments,
   apiInvoices,
   apiCWIntegration,
};
