/* eslint-disable import/extensions */
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosPorts from 'app/api/apiPort';

export const getPorts = createAsyncThunk('commonAPI/ports/getPorts', async () => {
   const params = { ...axiosPorts.apiPort.filterListDM };

   const response = await axiosPorts.apiPort.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const portsCommonDataAdapter = createEntityAdapter({
   selectId: (port) => port.iId,
});

export const { selectAll: selectPortsCommon } = portsCommonDataAdapter.getSelectors((state) => state.commonAPI.ports.dataPorts);

const portsCommonSlice = createSlice({
   name: 'ports',
   initialState: {
      dataPorts: portsCommonDataAdapter.getInitialState(),
   },
   reducers: {
      setLoading: {
         reducer: (state, action) => {
            state.loading = action.payload;
         },
      },
   },
   extraReducers: {
      [getPorts.fulfilled]: (state, { payload }) => {
         portsCommonDataAdapter.setAll(state.dataPorts, payload);
         const newEmptyValue = { iId: 0, sCode: '', sName: '' };
         portsCommonDataAdapter.addOne(state.dataPorts, newEmptyValue);
      },
   },
});

export const { setLoading } = portsCommonSlice.actions;

export default portsCommonSlice.reducer;
