import { lazy } from 'react';

const CurrencyPage = lazy(() => import('./CurrencyPage'));

const CurrencyPageConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/operator/masterdata/currency',
         element: <CurrencyPage />,
      },
   ],
};

export default CurrencyPageConfig;
