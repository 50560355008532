import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import axiosPO from 'app/api/apiPurchaseOrder';
import { showMessage } from 'app/store/fuse/messageSlice';
import _ from '@lodash';

export const getPOs = createAsyncThunk('commonAPI/pos/getPOs', async (filter, { dispatch }) => {
   const params = _.merge({}, { ...axiosPO.apiPurchaseOrder.filterListDM }, filter);

   try {
      const response = await axiosPO.apiPurchaseOrder.list({ ...params });

      const data = await response.data;

      return data === undefined ? null : data;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      dispatch(
         showMessage({
            message: `Error: ${msgError}`,
            autoHideDuration: 4000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'right',
            },
            variant: 'error',
         })
      );

      throw error;
   }
});

const posCommonDataAdapter = createEntityAdapter({
   selectId: (po) => po.iId,
});

export const { selectAll: selectPOsCommon, selectById: selectPOById } = posCommonDataAdapter.getSelectors(
   (state) => state.commonAPI.purchaseorders.dataPOs
);

const purchaseordersCommonSlice = createSlice({
   name: 'pos',
   initialState: {
      dataPOs: posCommonDataAdapter.getInitialState(),
   },
   extraReducers: {
      [getPOs.fulfilled](state, { payload }) {
         posCommonDataAdapter.setAll(state.dataPOs, payload);
      },
   },
});

export default purchaseordersCommonSlice.reducer;
