import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosUser from 'app/api/apiUser';

export const getOperators = createAsyncThunk('commonAPI/operators/getOperators', async () => {
   const params = {
      ...axiosUser.apiUser.filterListDM,
      bActive: true,
      sAccess: ['Internal', 'Administrator', 'Agent'],
   };

   const response = await axiosUser.apiUser.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data.users;
});

const operatorsCommonDataAdapter = createEntityAdapter({
   selectId: (user) => user.iId,
});

export const { selectAll: selectOperators } = operatorsCommonDataAdapter.getSelectors((state) => state.commonAPI.operators.data);

const operatorsCommonSlice = createSlice({
   name: 'operators ',
   initialState: {
      data: operatorsCommonDataAdapter.getInitialState(),
   },
   reducers: {
      setLoading: {
         reducer: (state, action) => {
            state.loading = action.payload;
         },
      },
   },
   extraReducers: {
      [getOperators.fulfilled](state, { payload }) {
         operatorsCommonDataAdapter.setAll(state.data, payload);
         // const newEmptyValue = { iId: 0, sCod: 'TBA' };
         // incotermsCommonDataAdapter.addOne(state.dataIncoterms, newEmptyValue);
      },
   },
});

export const { setLoading } = operatorsCommonSlice.actions;

export default operatorsCommonSlice.reducer;
