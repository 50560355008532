/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from '@mui/material';
import jwtService from '../../auth/services/jwtService';
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
   passwordCurrent: yup.string().required('Please enter your current password'),
   passwordConfirm: yup
      .string()
      .required('Please enter your password.')
      .min(9, 'Password is too short - must be at least 9 characters.')
      .oneOf([yup.ref('password'), null], 'Passwords must match.'),
   password: yup.string().required('Please enter your password.').min(9, 'Password is too short - must be at least 9 characters.'),
});

function ExpiredPassword(props) {
   const defaultValues = {
      passwordConfirm: '',
      password: '',
      username: props.username,
   };

   const { handleScreen, token } = props;
   const dispatch = useDispatch();

   // const [username, setUserName] = props.username;
   const [userId, setUserId] = useState(0);
   const [showMsg, setShowMsg] = useState(['', false]);

   const { control, formState, handleSubmit, setError, setValue, getValues } = useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
   });

   const { isValid, dirtyFields, errors } = formState;

   function onSubmit({ username, passwordCurrent, passwordConfirm }) {
      jwtService
         .signInWithPasswordExpired(username, passwordCurrent, passwordConfirm)
         .then((user) => {
            // No need to do anything, user data will be set at app/auth/AuthContext
         })
         .catch((_errors) => {
            const _errorsAr = Array.from(_errors);
            _errorsAr.map((x) => {
               setError(x.type, { type: 'manual', message: x.message });
            });
         });
   }

   return (
      <>
         <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">Your password has expired</Typography>
         <Typography className="font-medium">Create a new password for your account</Typography>

         <form name="resetForm" noValidate className="flex flex-col justify-center w-full mt-32" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-24">Username: {props.username}</div>
            <Controller
               name="passwordCurrent"
               control={control}
               render={({ field }) => (
                  <TextField
                     {...field}
                     className="mb-24"
                     label="Current Password"
                     type="password"
                     error={!!errors.passwordCurrent}
                     helperText={errors?.passwordCurrent?.message}
                     variant="outlined"
                     required
                     fullWidth
                     inputProps={{
                        maxLength: 20,
                     }}
                     InputProps={{
                        className: 'pr-2',
                        autoComplete: 'off',
                        type: 'password',
                     }}
                  />
               )}
            />
            <Controller
               name="password"
               control={control}
               render={({ field }) => (
                  <TextField
                     {...field}
                     className="mb-24"
                     label="Password"
                     type="password"
                     error={!!errors.password}
                     helperText={errors?.password?.message}
                     variant="outlined"
                     required
                     fullWidth
                     inputProps={{
                        maxLength: 20,
                     }}
                     InputProps={{
                        className: 'pr-2',
                        autoComplete: 'off',
                        type: 'password',
                     }}
                  />
               )}
            />
            <Controller
               name="passwordConfirm"
               control={control}
               render={({ field }) => (
                  <TextField
                     {...field}
                     className="mb-24"
                     label="Password (Confirm)"
                     type="password"
                     error={!!errors.passwordConfirm}
                     helperText={errors?.passwordConfirm?.message}
                     variant="outlined"
                     required
                     fullWidth
                     inputProps={{
                        maxLength: 20,
                     }}
                     InputProps={{
                        className: 'pr-2',
                        autoComplete: 'off',
                        type: 'password',
                     }}
                  />
               )}
            />
            <Button
               variant="contained"
               color="secondary"
               className=" w-full mt-4"
               aria-label="Register"
               disabled={_.isEmpty(dirtyFields) || !isValid}
               type="submit"
               size="large"
            >
               Change your password
            </Button>
            {showMsg[0] !== '' && (
               <Alert className="mt-12" severity={showMsg[1] === true ? 'error' : 'success'}>
                  <Typography className="text-md font-medium" color={showMsg[1] === true ? 'error.main' : 'success.main'}>
                     {showMsg[0]}
                  </Typography>
               </Alert>
            )}

            <div className="mt-8">
               <Typography className="text-md font-medium" color="text.secondary">
                  Password must meet complexity requirements as below:
                  <ul className=" list-disc">
                     <li>At least 9 characters is required to create a new password;</li>
                     <li> The new password must be different of from the 5 previous passwords;</li>
                     <li className="mb-6">
                        The new password must be contained with Uppercase/Lowercase characters, numbers, and special characters. E.g. @nDr3w^69.{' '}
                     </li>
                  </ul>
               </Typography>
            </div>
            <Typography className="mt-32 text-md font-medium" color="text.secondary">
               <span>Return to</span>
               <Link
                  className="ml-4"
                  to="/sign-in"
                  onClick={() => {
                     setShowMsg(['', false]);
                     handleScreen('sign-in');
                  }}
               >
                  sign in
               </Link>
            </Typography>
         </form>
      </>
   );
}

export default ExpiredPassword;
