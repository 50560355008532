import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

export const taskPriority = [
   {
      value: '',
      name: '',
      label: '',
   },
   {
      value: 'Critical',
      name: 'Critical',
      label: (
         <div className="flex items-center bg-orange-100 inline px-8 py-4 rounded-4 inline text-sm font-medium" key="Critical">
            <FuseSvgIcon size={16} className="text-red">
               heroicons-solid:exclamation-circle
            </FuseSvgIcon>
            <span className="mx-4">Critical</span>
         </div>
      ),
   },
   {
      value: 'Urgent',
      name: 'Urgent',
      label: (
         <div className="flex items-center  bg-purple-50 inline px-8 py-4 rounded-4 inline text-sm font-medium" key="Urgent">
            <FuseSvgIcon size={16} className="text-red">
               heroicons-solid:arrow-narrow-up
            </FuseSvgIcon>
            <span className="mx-4">Urgent</span>
         </div>
      ),
   },
   {
      value: 'Non Urgent',
      name: 'Non Urgent',
      label: (
         <div className="flex items-center inline text-sm font-medium" key="NonUrgent">
            <FuseSvgIcon size={16} className="text-green">
               heroicons-solid:arrow-narrow-down
            </FuseSvgIcon>
            <span className="mx-4">Non Urgent</span>
         </div>
      ),
   },
   {
      value: 'Normal',
      name: 'Normal',
      label: (
         <div className="flex items-center inline text-sm font-medium" key="Normal">
            <FuseSvgIcon size={16} className="text-gray">
               heroicons-solid:minus
            </FuseSvgIcon>
            <span className="mx-4">Normal</span>
         </div>
      ),
   },
];

export default {
   taskPriority,
};
