/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material/styles';
import MaterialTable from '@material-table/core';
import { settingsTable } from 'app/utils/MaterialTableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import SetURL from 'app/utils/SetURL';
import { poStatus } from 'app/utils/Collections/poStatus';
import { modeOfTransport } from 'app/utils/Collections/modeOfTransport';
import { selectSearchResults } from '../store/advancedSearchSlice';

function PuchaseOrdersTab(props) {
   const dispatch = useDispatch();
   const theme = useTheme();
   const data = useSelector(selectSearchResults)?.data?.purchaseOrders || [];
   const currentLanguageLocaleCode = useSelector(({ i18n }) => i18n.localeCode);

   const columns = [
      {
         title: 'Order No.',
         field: 'sNo',
         render: (rowData) => (
            <Typography
               className="flex items-center font-medium text-md"
               component={Link}
               onClick={() => dispatch(closeDialog())}
               role="button"
               to={`/${SetURL()}/purchaseorder/purchaseorders/${rowData.id}`}
               color="secondary"
            >
               {rowData.sNo}
            </Typography>
         ),
      },
      {
         title: 'Project',
         field: 'sProjectCod',
      },
      {
         title: 'Name',
         field: 'sName',
      },
      {
         title: 'Supplier',
         field: 'sSupplierName',
      },
      {
         title: 'Incoterms',
         field: 'sIncotermCode',
      },
      {
         title: 'Status',
         field: 'sStatus',
         render: (rowData) => poStatus.filter((item) => item.value === rowData.sStatus).map((filtered) => filtered.label),
      },
      {
         title: 'MOT',
         field: 'sMode',
         render: (rowData) => modeOfTransport.filter((item) => item.value === rowData.sMode).map((filtered) => filtered.label),
      },
      {
         title: 'Due Date',
         field: 'dDue',
         align: 'left',
         type: 'date',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
   ];

   return (
      <div>
         <MaterialTable
            options={{
               paging: false,
               // pageSize: 10,
               // pageSizeOptions: [10, 20, data.length],
               exportAllData: true,
               toolbar: false,
               showTitle: false,
               maxBodyHeight: '430px',
               ...settingsTable(theme),
            }}
            components={{
               Container: (propsc) => <Paper {...propsc} elevation={0} />,
            }}
            title=""
            columns={columns}
            data={data}
         />
      </div>
   );
}

export default PuchaseOrdersTab;
