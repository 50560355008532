import { Typography } from '@mui/material';
import Icon from '@mui/material/Icon';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Tooltip from '@mui/material/Tooltip';

export const modeOfTransport = [
   {
      label: (
         <div className="flex items-center" key="NONE">
            <Icon className="block text-lg bg-white rounded-full">help</Icon>
            <span className="mx-4">None</span>
         </div>
      ),
      icon: (
         <Tooltip title="none">
            <FuseSvgIcon size={16}>material-solid:help</FuseSvgIcon>
         </Tooltip>
      ),
      value: '(none)',
      name: 'none',
      labelVessel: '',
   },
   {
      label: (
         <div className="flex items-center" key="Tba">
            <Icon className="block text-lg bg-white rounded-full">help</Icon>
            <span className="mx-4">TBA</span>
         </div>
      ),
      icon: (
         <Tooltip title="TBA">
            <FuseSvgIcon size={16}>material-solid:help</FuseSvgIcon>
         </Tooltip>
      ),
      value: 'TBA',
      name: 'TBA',
      labelVessel: 'Vessel & Voy Flight',
   },
   {
      label: (
         <div className="flex items-center" key="Sea">
            <Icon className="block text-lg">directions_boat</Icon>
            <span className="mx-4">Sea</span>
         </div>
      ),
      icon: (
         <Tooltip title="Sea">
            <FuseSvgIcon size={16}>material-solid:directions_boat</FuseSvgIcon>
         </Tooltip>
      ),
      value: 'Sea',
      name: 'Sea',
      labelVessel: 'Vessel/Voyage',
   },
   {
      label: (
         <div className="flex items-center" key="Air">
            <Icon className="block text-lg">flight</Icon>
            <span className="mx-4">Air</span>
         </div>
      ),
      icon: (
         <Tooltip title="Air">
            <FuseSvgIcon size={16}>material-solid:flight</FuseSvgIcon>
         </Tooltip>
      ),
      value: 'Air',
      name: 'Air',
      labelVessel: 'Flight',
   },
   {
      label: (
         <div className="flex items-center" key="Road">
            <Icon className="block text-lg ">local_shipping</Icon>
            <span className="mx-4">Road</span>
         </div>
      ),
      icon: (
         <Tooltip title="Road">
            <FuseSvgIcon size={16}>material-solid:local_shipping</FuseSvgIcon>
         </Tooltip>
      ),
      value: 'Road',
      name: 'Road',
      labelVessel: 'Road',
   },
   {
      label: (
         <div className="flex items-center" key="Rail">
            <Icon className="block text-lg ">train</Icon>
            <span className="mx-4">Rail</span>
         </div>
      ),
      icon: (
         <Tooltip title="Rail">
            <FuseSvgIcon size={16}>material-solid:train</FuseSvgIcon>
         </Tooltip>
      ),
      value: 'Rail',
      name: 'Rail',
      labelVessel: 'Rail',
   },
   {
      label: (
         <div className="flex items-center" key="Courier">
            <Icon className="block text-lg ">mail</Icon>
            <span className="mx-4">Courier</span>
         </div>
      ),
      icon: (
         <Tooltip title="Courier">
            <FuseSvgIcon size={16}>material-solid:mail</FuseSvgIcon>
         </Tooltip>
      ),
      value: 'Courier',
      name: 'Courier',
      labelVessel: 'Courier',
   },
   {
      label: (
         <div className="flex items-center" key="Hand">
            <Icon className="block text-lg  bg-white rounded-full">emoji_people</Icon>
            <span className="mx-4">Hand Carried</span>
         </div>
      ),
      icon: (
         <Tooltip title="Hand Carried">
            <FuseSvgIcon size={16}>material-solid:emoji_people</FuseSvgIcon>
         </Tooltip>
      ),
      value: 'Hand Carried',
      name: 'Hand Carried',
      labelVessel: 'Hand Carried',
   },
   {
      label: (
         <div className="flex items-center" key="NA">
            <Typography className="block text-14  bg-white rounded-full">N/A</Typography>
            <span className="mx-4">Not for shipment</span>
         </div>
      ),
      icon: (
         <Tooltip title="Not for shipment">
            <Typography className="block text-14  bg-white rounded-full">N/A</Typography>
         </Tooltip>
      ),
      value: 'Not for shipment',
      name: 'Not for shipment',
      labelVessel: '',
   },
];

export default {
   modeOfTransport,
};
