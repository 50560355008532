import { lazy } from 'react';

const MyAccount = lazy(() => import('./MyAccountPage'));

const MyAccountConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: 'myaccount',
         element: <MyAccount />,
      },
   ],
};

export default MyAccountConfig;
