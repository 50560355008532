import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';
import DiaryTaskForm from './diarytask-form/DiaryTaskForm';

const DiaryTasks = lazy(() => import('./DiaryTasks'));

const DiaryTaskAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.user, // ['admin']
   routes: [
      {
         path: '/operator/diarytask',
         element: <DiaryTasks />,
         children: [
            {
               path: ':taskId',
               element: <DiaryTaskForm />,
            },
         ],
      },
   ],
};

export default DiaryTaskAppConfig;
