import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Company = lazy(() => import('./company/Company'));
const Companies = lazy(() => import('./companies/Companies'));

const CompanyAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.user, // ['admin']
   routes: [
      {
         path: '/operator/company/companies/:companyId',
         element: <Company />,
      },
      {
         path: '/operator/company/companies',
         element: <Companies />,
      },
      {
         path: '/operator/company',
         element: <Companies />,
      },
   ],
};

export default CompanyAppConfig;
