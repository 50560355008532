import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Invoices = lazy(() => import('./invoices/Invoices'));

const AccountingAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.client, // ['admin']
   routes: [
      {
         path: '/client/invoice/invoices',
         element: <Invoices />,
      },

      {
         path: '/client/invoice',
         element: <Invoices />,
      },
   ],
};

export default AccountingAppConfig;
