import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
/* Created by Priscila */

export const confirmationDialogThunkActions = createAsyncThunk('confirmationDialog', async (_, { subscribe, getState, dispatch }) => {
   // const store = extra.store;

   dispatch(confirmationDialogActions.open());

   return new Promise((resolve) => {
      const unsubscribe = subscribe(() => {
         const state = getState();
         if (state.confirmationDialog.isConfirmed) {
            unsubscribe();
            resolve(true);
         }
         if (state.confirmationDialog.isDeclined) {
            unsubscribe();
            resolve(false);
         }
      });
   });
});

const confirmationDialogSlice = createSlice({
   name: 'confirmationDialog',
   initialState: {
      isOpened: false,
      isConfirmed: false,
      isDeclined: false,
      isLoading: false,
      text: "Are you sure? This can't be undone.",
      callFunction: null,
   },
   reducers: {
      open: (state, action) => {
         state.isOpened = true;
         state.isDeclined = false;
         state.isConfirmed = false;
         state.callFunction = action.payload.function;
         state.text = action.payload?.text;
      },
      confirm: (state) => {
         state.isConfirmed = true;
         state.isOpened = false;
      },
      decline: (state) => {
         state.isDeclined = true;
         state.isOpened = false;
      },
   },
});

export const confirmationDialogActions = confirmationDialogSlice.actions;

export default confirmationDialogSlice.reducer;
