import { selectUser } from 'app/store/userSlice';
import { useSelector } from 'react-redux';

function SetURL() {
   const user = useSelector(selectUser);

   return user.role === 'client' ? 'client' : 'operator';
}

export default SetURL;
