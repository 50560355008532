/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material/styles';
import MaterialTable from '@material-table/core';
import { settingsTable } from 'app/utils/MaterialTableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import StatusIconActiveInactive from 'app/utils/StatusIconActiveInactive';
import { selectSearchResults } from '../store/advancedSearchSlice';

function ProjectsTab(props) {
   const dispatch = useDispatch();
   const theme = useTheme();
   const data = useSelector(selectSearchResults)?.data?.projects || [];

   const columns = [
      {
         title: 'Project Code',
         field: 'sCod',
         align: 'left',
         render: (rowData) => (
            <Typography
               className="flex items-center"
               component={Link}
               onClick={() => dispatch(closeDialog())}
               role="button"
               to={`/operator/project/projects/${rowData.id}`}
               color="secondary"
            >
               {rowData.sCod}
            </Typography>
         ),
      },
      {
         title: 'Name',
         field: 'sName',
      },
      {
         title: 'Dept',
         field: 'sCWDept',
      },
      {
         title: 'is Active?',
         field: 'sStatus',
         render: (rowData) => <StatusIconActiveInactive status={rowData.sStatus} />,
      },
      {
         title: 'Shipper',
         field: 'sShipperName',
      },
   ];

   return (
      <div>
         <MaterialTable
            options={{
               paging: false,
               // pageSize: 10,
               // pageSizeOptions: [10, 20, data.length],
               exportAllData: true,
               toolbar: false,
               showTitle: false,
               maxBodyHeight: '410px',
               ...settingsTable(theme),
            }}
            components={{
               Container: (propsc) => <Paper {...propsc} elevation={0} />,
            }}
            title=""
            columns={columns}
            data={data}
         />
      </div>
   );
}

export default ProjectsTab;
