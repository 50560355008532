import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CachedIcon from '@mui/icons-material/Cached';
import HeaderEntity from 'app/shared-components/HeaderEntity';
import BasicDialogAutoComplete from 'app/shared-components/BasicDialogAutoComplete';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { selectDiaryPOsAssociated, getPOsAssociated, selectDiaryShipAssociated, getShipsAssociated } from '../store/diarytaskSlice';

function DiaryTaskFormPOShip(props) {
   const dispatch = useDispatch();
   const methods = useFormContext();
   const { control, getValues, register, setValue } = methods;
   const routeParams = useParams();

   /*
    * Send value from Modal Purchase Order to Form
    */
   const receiveDataModalPO = (param1, param2, param3) => {
      setValue('iPoId', param1);
      setValue('sOrderName', param2);
      setValue('sOrderNo', param3, { shouldDirty: true });
   };

   /*
    * Send value from Modal Shipment to Form
    */
   const receiveDataModalShipment = (param1, param2, param3) => {
      setValue('iShipmentId', param1);
      setValue('sCargo', param2);
      setValue('iShipmentId', param3, { shouldDirty: true });
   };

   /*
    * Open Modal Form PO
    */
   const openDialogFormPO = () => {
      return dispatch(
         openDialog({
            children: (
               <BasicDialogAutoComplete
                  // setModalFormData={setModalFormProject}
                  receiveData={receiveDataModalPO}
                  dialogTitle="Purchase Order"
                  dialogContentText="Search purchase orders and click on OK"
                  inputName1="sName"
                  inputName2="sNo"
                  inputClassName="mt-20"
                  inputLabel="Purchase Order"
                  inputOptionId="iId"
                  inputGetOptionLabel={(option) =>
                     option.sName === undefined ? '' : `${option.sNo || ''} - ${option.sName || ''} (${option.sStatus || ''} )`
                  }
                  inputRequired
                  buttonTextOK="OK"
                  calluseSelector={selectDiaryPOsAssociated}
                  callfunction={() => dispatch(getPOsAssociated({ iShipmentId: getValues('iShipmentId') }))}
               />
            ),
         })
      );
   };

   /*
    * Open Modal Form Shiment
    */
   const openDialogFormShipment = () => {
      return dispatch(
         openDialog({
            children: (
               <BasicDialogAutoComplete
                  // setModalFormData={setModalFormProject}
                  receiveData={receiveDataModalShipment}
                  dialogTitle="Shipment"
                  dialogContentText="Search shipments and click on OK"
                  inputName1="sCargo"
                  inputName2="iId"
                  inputClassName="mt-20"
                  inputLabel="Shipment"
                  inputOptionId="iId"
                  inputGetOptionLabel={(option) => (option.sIdCargo === undefined ? '' : `${option.sIdCargo || ''} (${option.sStatus || ''} )`)}
                  buttonTextOK="OK"
                  calluseSelector={selectDiaryShipAssociated}
                  callfunction={() =>
                     dispatch(
                        getShipsAssociated({
                           iPoId: getValues('iPoId'),
                        })
                     )
                  }
               />
            ),
         })
      );
   };

   return (
      <div className="flex flex-col sm:flex-row flex-wrap w-full">
         {!routeParams.shipId && !routeParams.poId && (
            <div className="flex  items-start sm:w-1/3 min-w-0">
               <HeaderEntity
                  icon="content_paste"
                  title="Project"
                  subTitle={getValues('sProjectName')}
                  inputHidden={register('iProjectId')}
                  textControl={control}
                  textName="sProjectCod"
                  buttonChangeToolTip="Change Project"
                  buttonChangeOnClick={() => {}}
                  buttonChangeIcon={null}
                  buttonLinkToolTip="Go to Project"
                  buttonLinkAddress={`/operator/project/projects/${getValues('iProjectId')}`}
                  buttonLinkIcon="arrow_forward"
                  id={getValues('iProjectId')}
               />
            </div>
         )}

         <div className="flex  items-start sm:w-1/3 min-w-0">
            <HeaderEntity
               icon="ballot"
               noWrap={false}
               title="Purchase Order"
               subTitle={getValues('sOrderNo')}
               inputHidden={register('iPoId')}
               textControl={control}
               textName="sOrderNo"
               buttonChangeToolTip="Change Purchase Order"
               buttonChangeOnClick={() => {
                  openDialogFormPO();
               }}
               buttonChangeIcon={!routeParams.poId ? <CachedIcon /> : null}
               buttonLinkToolTip="Go to Purchase Order"
               buttonLinkAddress={`/operator/purchaseorder/PurchaseOrders/${getValues('iPoId')}`}
               buttonLinkIcon="arrow_forward"
               id={getValues('iPoId')}
            />
         </div>

         <div className="flex  items-start sm:w-1/3 min-w-0">
            <HeaderEntity
               icon="explore"
               title="Shipment"
               subTitle={getValues('sShipmentCargo')}
               inputHidden={register('iShipmentId')}
               textControl={control}
               textName="iShipmentId"
               buttonChangeToolTip="Change Shipment"
               buttonChangeOnClick={() => {
                  openDialogFormShipment();
               }}
               buttonChangeIcon={!routeParams.shipId ? <CachedIcon /> : null}
               buttonLinkToolTip="Go to Shipment"
               buttonLinkAddress={`/operator/shipment/Shipments/${getValues('iShipmentId')}`}
               buttonLinkIcon="arrow_forward"
               id={getValues('iShipmentId')}
            />
         </div>
      </div>
   );
}

export default DiaryTaskFormPOShip;
