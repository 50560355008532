/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material/styles';
import MaterialTable from '@material-table/core';
import { settingsTable } from 'app/utils/MaterialTableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import SetURL from 'app/utils/SetURL';
import { selectSearchResults } from '../store/advancedSearchSlice';

function PackagesTab(props) {
   const dispatch = useDispatch();
   const theme = useTheme();
   const data = useSelector(selectSearchResults)?.data?.packages || [];
   const currentLanguageLocaleCode = useSelector(({ i18n }) => i18n.localeCode);

   const columns = [
      {
         title: 'Job No.',
         field: 'iShipmentId',
         render: (rowData) => (
            <Typography
               className="flex items-center"
               component={Link}
               onClick={() => dispatch(closeDialog())}
               role="button"
               to={`/${SetURL()}/shipment/shipments/${rowData.iShipmentId}`}
               state="package"
               color="secondary"
            >
               {rowData.iShipmentId}
            </Typography>
         ),
      },
      {
         title: 'Package No.',
         field: 'sPackageNo',
         width: '150px',
         // cellStyle: { padding: '18px', width: '200px', minWidth: '100px', maxWidth: 'px200' }
      },
      {
         title: 'No',
         field: 'nNo',
         align: 'center',
         width: '80px',
      },
      {
         title: 'Kind',
         field: 'sKind',
         width: '80px',
      },
      {
         title: 'Dims (L x W x H) mm',
         field: 'nDim1',
         width: '200px',
         render: (rowData) => {
            return (
               <Typography className="truncate">
                  {rowData.nDim1} x {rowData.nDim2} x {rowData.nDim3}
               </Typography>
            );
         },
      },
      {
         title: 'Cubic (m3)',
         field: 'nM3',
         align: 'center',
         width: '50px',
      },
      {
         title: 'Weight (kgs)',
         field: 'nWeight',
         align: 'center',
         width: '50px',
      },
      {
         title: 'SCN',
         field: 'sScn',
         width: '100px',
      },
      {
         title: 'Available',
         field: 'dAvailable',
         type: 'date',
         width: '80px',
         dateSetting: { locale: currentLanguageLocaleCode },
         emptyValue: () => <div>N/A</div>,
      },
   ];

   return (
      <div>
         <MaterialTable
            options={{
               paging: false,
               // pageSize: 10,
               // pageSizeOptions: [10, 20, data.length],
               exportAllData: true,
               toolbar: false,
               showTitle: false,
               maxBodyHeight: '430px',
               ...settingsTable(theme),
            }}
            components={{
               Container: (propsc) => <Paper {...propsc} elevation={0} />,
            }}
            title=""
            columns={columns}
            data={data}
         />
      </div>
   );
}

export default PackagesTab;
