import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';
import DiaryTaskForm from '../diarytask/diarytask-form/DiaryTaskForm';

const Shipment = lazy(() => import('./shipment/Shipment'));
const Shipments = lazy(() => import('./shipments/Shipments'));

const ShipmentAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.user, // ['admin']
   routes: [
      {
         path: '/operator/shipment/shipments/:shipId',
         element: <Shipment />,
         children: [
            {
               path: 'diarytask/:taskId',
               element: <DiaryTaskForm />,
            },
         ],
      },
      {
         path: '/operator/shipment/shipments',
         element: <Shipments />,
      },

      {
         path: '/operator/shipment',
         element: <Shipments />,
      },
   ],
};

export default ShipmentAppConfig;
