import { useState, useEffect } from 'react';
import {
   itemsInitialValues,
   projectsInitialValues,
   shipmentsInitialValues,
   diaryInitialValues,
   companiesInitialValues,
   posInitialValues,
} from 'app/utils/UserSettingsDataGridInitialValues';

const getDefaultValue = (key) => {
   switch (key) {
      case findTerm('project', key):
         return [...projectsInitialValues];
      case findTerm('item', key):
         return [...itemsInitialValues];
      case findTerm('shipment', key):
         return [...shipmentsInitialValues];
      case findTerm('diary', key):
         return [...diaryInitialValues];
      case findTerm('company', key):
         return [...companiesInitialValues];
      case findTerm('po', key):
         return [...posInitialValues];
      default:
         return [];
   }
};

const findTerm = (term, key) => {
   if (key.includes(term)) {
      return key;
   }
   return '';
};

export const getStorageValue = (key, defaultValue) => {
   // getting stored value
   if (typeof window !== 'undefined') {
      const saved = localStorage.getItem(key);
      const initial = saved !== null ? JSON.parse(saved) : defaultValue;
      return initial;
   }

   return null;
};

// used on jwtService to initilize the local storage as empty array
export const setStorageValue = (key, defaultValue) => {
   const value = getStorageValue(key, defaultValue);
   localStorage.setItem(key, JSON.stringify(value));
};

// used in the tab to useState by retuning or setting values
export const useLocalStorage = (key) => {
   const [value, setValue] = useState(() => {
      let defaultKey = {};
      if (key.includes('filter')) defaultKey = [];
      else if (key.includes('Search')) defaultKey = [];
      else defaultKey = getDefaultValue(key);

      return getStorageValue(key, defaultKey);
   });

   useEffect(() => {
      // storing input name
      localStorage.setItem(key, JSON.stringify(value));
   }, [key, value]);

   return [value, setValue];
};
