import axiosConfig from './axiosConfig';

const apiCompany = {
   list: (filter) =>
      axiosConfig.requests
         .get(`/companies?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&BActive=${filter.bActive}&sName=${filter.sName}&SCode=${filter.sCode}&bClient=${filter.bClient}&bCarrier=${filter.bCarrier}&BVendor=${filter.bVendor}
                &BAgent=${filter.bAgent}&BTransport=${filter.bTransport}&BWarehouse=${filter.bWarehouse} &BPacking=${filter.bPacking}&BTerminal=${filter.bTerminal}&BDg=${filter.bDg}&sFilterbyEntity=${filter.sFilterbyEntity}
				&SCodeInterface=${filter.sCodeInterface}
            &SContact=${filter.sContact}
            `),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sContact: '',
      sName: '',
      sCode: '',
      sCodeInterface: '',
      bActive: true,
      bClient: '',
      bCarrier: '',
      bVendor: '',
      bAgent: '',
      bTransport: '',
      bWarehouse: '',
      bPacking: '',
      bTerminal: '',
      bDg: '',
      sFilterbyEntity: '',
   },
   details: (id) => axiosConfig.requests.get(`/companies/${id}`),
   create: (company) => axiosConfig.requests.post('/companies', company),
   update: (company) => axiosConfig.requests.put(`/companies/${company.company.id}`, company),
   delete: (id) => axiosConfig.requests.del(`/companies/${id}`),
};

export default { apiCompany };
