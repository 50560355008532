import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Shipment = lazy(() => import('./shipment/Shipment'));
const Shipments = lazy(() => import('./shipments/Shipments'));

const ShipmentAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.client, // ['admin']
   routes: [
      {
         path: '/client/shipment/shipments/:shipId',
         element: <Shipment />,
      },
      {
         path: '/client/shipment/shipments',
         element: <Shipments />,
      },

      {
         path: '/client/shipment',
         element: <Shipments />,
      },
   ],
};

export default ShipmentAppConfig;
