import { useDispatch, useSelector } from 'react-redux';
import { confirmationDialogActions } from 'app/store/commonAPI/confirmationDialogSlice';

function useConfirmationManagement() {
   const dispatch = useDispatch();
   const { isOpened } = useSelector(({ commonAPI }) => commonAPI.confirmationDialog);
   const { callFunction } = useSelector(({ commonAPI }) => commonAPI.confirmationDialog);
   const { text } = useSelector(({ commonAPI }) => commonAPI.confirmationDialog);

   const open = (prop1) => {
      // const { payload } = await dispatch(confirmationDialogThunkActions());
      // return payload;
      return dispatch(confirmationDialogActions.open(prop1));
   };

   const confirm = () => {
      callFunction();
      return dispatch(confirmationDialogActions.confirm());
   };

   const decline = () => {
      return dispatch(confirmationDialogActions.decline());
   };

   return {
      isOpened,
      open,
      confirm,
      decline,
      text,
   };
}

export default useConfirmationManagement;
