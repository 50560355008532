export const settingsTable = (theme) => {
   return {
      addRowPosition: 'first',
      padding: 'dense',
      headerStyle: {
         backgroundColor: theme.palette.background.default,
         fontSize: theme.typography.pxToRem(14),
         position: 'sticky',
         top: 0,
         zIndex: 999,
      },
      actionsCellStyle: {
         borderBottom: `1px solid ${theme.palette.divider}`,
      },
      rowStyle: { borderBottom: `1px solid ${theme.palette.divider}` },
      editCellStyle: {
         borderBottom: `1px solid ${theme.palette.divider}`,
         fontSize: '1.3rem', // theme.typography.pxToRem(1.3)
         fontWeight: 400,
         lineHeight: '1.2!important',
      },
      cellStyle: {
         fontSize: '1.3rem', // theme.typography.pxToRem(1.3)
         fontWeight: 400,
         lineHeight: '1.2!important',
         textRendering: 'optimizeLegibility',
         borderBottom: `1px solid ${theme.palette.divider}`,
      },
   };
};

export const onAllSelected = (tableRef, setCheckedState, checkedState, selectedRows, setSelectedRows) => {
   tableRef.current.onAllSelected(false);
   tableRef.current.dataManager.changeAllSelected(false);
   setCheckedState(!checkedState);

   let checkIndexsPerPage = [];
   const checkRowsPerPage = [];

   /** *remove previous check -  as I don' know if it was sorted I will search the whole table and change it */
   if (selectedRows.length > 0) {
      tableRef.current.dataManager.searchedData.map((row, idx) => {
         if (row.tableData.checked === true) {
            tableRef.current.dataManager.changeRowSelected(false, [idx]);
         }
      });
   }

   /** *add current check */
   setSelectedRows(tableRef.current.dataManager.pagedData);
   checkIndexsPerPage = tableRef.current.dataManager.pagedData.map((row, idx2) => {
      const rowIndex = tableRef.current.dataManager.sortedData.findIndex((x) => x.iId === row.iId);
      return rowIndex; //
   });
   checkIndexsPerPage.map((y) => tableRef.current.dataManager.changeRowSelected(!checkedState, [y]));
};

export const cellStyle = (theme) => {
   return {
      whiteSpace: 'nowrap',
      fontSize: '1.3rem', // theme.typography.pxToRem(1.3)
      fontWeight: 400,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .MuiTableCell-root': { borderBottom: `1px solid ${theme.palette.divider}` },
   };
};
// used in the div as well
export const cellStyleDiv = (theme) => {
   return {
      fontSize: '1.3rem', // theme.typography.pxToRem(1.3)
      fontWeight: 400,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '& .MuiTableCell-root': { borderBottom: `1px solid ${theme.palette.divider}` },
   };
};

/** ************************************** */
/* setColumns to save state on Local Storage
/*************************************** */
export const setColumnsLS = (_col, _setHiddenColumn, _hiddenColumn) => {
   const tempCols = [..._hiddenColumn];
   let newArr = [];

   if (_col.hidden === true) {
      const newColumn = { column: _col.field };
      // in case the field already exist in the array, I delete and add it again - to avoid duplication
      const filterCols = tempCols.filter((i) => i.column !== _col.field);
      newArr = [...filterCols];
      // add the field
      newArr.push(newColumn);
   } else {
      const filterCols = tempCols.filter((i) => i.column !== _col.field);
      newArr = [...filterCols];
   }
   // console.log(newArr);
   _setHiddenColumn(newArr);
};

export const hideColumn = (hiddenColumn, value) => {
   hiddenColumn = hiddenColumn || [];
   return hiddenColumn.filter((col) => col.column === value).length === 1;
};

export default {
   settingsTable,
   cellStyle,
   setColumnsLS,
   hideColumn,
   onAllSelected,
};
