import _ from '@lodash';
import clsx from 'clsx';
import Icon from '@mui/material/Icon';
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ControllerSelect from 'app/shared-components/ControllerSelect';
import ControllerTextField from 'app/shared-components/ControllerTextField';

export const projectStatus = [
   {
      label: (
         <div className="flex items-center">
            <Icon className="block text-lg text-green bg-white rounded-full">check_circle</Icon>
            <span className="mx-4">Active</span>
         </div>
      ),
      value: 'Active',
      name: 'Active',
      icon: <Icon className="block text-lg text-green bg-white rounded-full">check_circle</Icon>,
   },
   {
      label: (
         <div className="flex items-center">
            <Icon className="block text-lg text-red bg-white rounded-full">remove_circle</Icon>
            <span className="mx-4">Inactive</span>
         </div>
      ),
      value: 'Inactive',
      name: 'Active',
      icon: <Icon className="block text-lg text-red bg-white rounded-full">remove_circle</Icon>,
   },
];

export const projectModules = [
   {
      title: 'Post Shipment Activity',
      value: 'bPostShipAct',
      type: 'Shipment',
   },
   {
      title: 'Display Post Shipment Activity',
      value: 'bShowPostShipmentClient',
      type: 'Client view',
   },
   {
      title: 'Domestic Shipping' /* duvida */,
      value: 'bDomesticShip',
      type: 'Container',
   },
   {
      title: 'Exception Reports',
      value: 'bException',
      type: 'Report / Invoice / Manifest',
   },

   {
      title: 'PO Amendment',
      value: 'bPoamendment',
      type: 'Purchase Order',
   },
   {
      title: 'Rev. ETA Notif.',
      value: 'bRevisedEtanotif',
      type: 'Email',
   },
   {
      title: 'DPI/Cotecna - Inspection Type',
      value: 'bCotecnaIt',
      type: 'Purchase Order',
   },
   {
      title: 'Doc. Received',
      value: 'bDocReceived',
      type: 'Package',
   },
   {
      title: 'File Perfection',
      value: 'bFilePerfection',
      type: 'Shipment',
   },
   {
      title: 'Vendors Complicance',
      value: 'bVendorsCompliance',
      type: 'Purchase Order',
   },
   {
      title: 'Buyers',
      value: 'bBuyersPo',
      type: 'Purchase Order',
   },
   {
      title: 'Private Comments',
      value: 'bPrivCommentsShip',
      type: 'Shipment',
   },
   {
      title: 'Display Shipment Legs',
      value: 'bShipmentLeg',
      type: 'Client view',
   },
   {
      title: 'List of Operators',
      value: 'bShipOperator',
      type: 'Shipment',
   },
   {
      title: 'Remarks',
      value: 'bPackRemark',
      type: 'Package',
   },
   {
      title: 'Package Date - Compulsory',
      value: 'bPackDate',
      type: 'Package',
   },
   {
      title: 'Clearance Data',
      value: 'bClearance',
      type: 'Shipment',
   },
   {
      title: 'Display Line Items',
      value: 'bLineItems',
      type: 'Client view',
   },
   {
      title: 'Additional Info field',
      value: 'bAdditionalInfo',
      type: 'Purchase Order',
   },
   {
      title: 'Additional Info2 field',
      value: 'bAdditionalInfo2',
      type: 'Purchase Order',
   },
   {
      title: 'Priority Codes',
      value: 'bPc',
      type: 'Purchase Order',
   },
   {
      title: 'URL field',
      value: 'bUrlPo',
      type: 'Purchase Order',
   },
   {
      title: 'Display Project Code - Manifest',
      value: 'bManifestProject',
      type: 'Report / Invoice / Manifest',
   },
   {
      title: 'Copy PO Additional Info2 to SCN at Package',
      value: 'bPackScnpo',
      type: 'Package',
   },
   {
      title: 'Invoice - 2º Template',
      value: 'bInvoice2Model',
      type: 'Report / Invoice / Manifest',
   },
   {
      title: 'Pre-alert (subject)',
      value: 'bPreAlert',
      type: 'Email',
   },
   {
      title: 'Display Shipper',
      value: 'bShipper',
      type: 'Purchase Order',
   },
   /* {
    title: 'Display List of Consignee',
    value: 'bInvoiceConsignee',
    type: 'Report / Invoice / Manifest',
  }, */
   {
      title: 'Allow clients to upload docs. - PO',
      value: 'bUploadbyClient',
      type: 'Client view',
   },
   {
      title: 'Invoice Summary and Packing List',
      value: 'bInvSummaryPackingList',
      type: 'Report / Invoice / Manifest',
   },
   {
      title: 'Dispaly Product and Material - 2nd Template',
      value: 'bInv2Product',
      type: 'Report / Invoice / Manifest',
   },
   {
      title: 'Display DPI fields',
      value: 'bDpi',
      type: 'Report / Invoice / Manifest',
   },
   {
      title: 'Display SCN - Manifest',
      value: 'bScnPackManifest',
      type: 'Report / Invoice / Manifest',
   },
];

export const legendtypeModules = [
   {
      name: 'Client view',
      class: 'bg-red text-white',
      color: 'red',
   },
   {
      name: 'Container',
      class: 'bg-cyan text-white',
      color: 'cyan',
   },
   {
      name: 'Email',
      class: 'bg-grey text-white',
      color: 'grey',
   },
   {
      name: 'Package',
      class: 'bg-green text-white',
      color: 'green',
   },
   {
      name: 'Purchase Order',
      class: 'bg-orange text-white',
      color: 'orange',
   },
   {
      name: 'Report / Invoice / Manifest',
      class: 'bg-purple text-white',
      color: 'purple',
   },
   {
      name: 'Shipment',
      class: 'bg-indigo text-white',
      color: 'blue',
   },
];

function LegendModules(props) {
   return (
      <div className={clsx('inline text-base py-2 px-6 rounded-full truncate', _.find(legendtypeModules, { name: props.name }).class)}>
         {props.name}
      </div>
   );
}
// <div className={clsx(Collections.legendtypeModules.filter(item=> item.name === label.type).map(filtered=> (filtered.class)), 'w-12 h-4 rounded-6 mx-3 mb-3 flex-none')} />

function PriorityCodes(props) {
   const methods = useFormContext();
   const { control } = methods;

   return (
      <div className="flex flex-col mx-16 mb-10">
         <Controller
            name="project.sP1"
            control={control}
            render={({ field }) => <TextField {...field} className="flex flex-1 mx-8" variant="outlined" size="small" fullWidth />}
         />
         <Controller
            name="project.sP2"
            control={control}
            render={({ field }) => <TextField {...field} className="flex flex-1 mx-8" variant="outlined" size="small" fullWidth />}
         />
         <Controller
            name="project.sP3"
            control={control}
            render={({ field }) => <TextField {...field} className="flex flex-1 mx-8" variant="outlined" size="small" fullWidth />}
         />
         <Controller
            name="project.sP4"
            control={control}
            render={({ field }) => <TextField {...field} className="flex flex-1 mx-8" variant="outlined" size="small" fullWidth />}
         />
         <Controller
            name="project.sP5"
            control={control}
            render={({ field }) => <TextField {...field} className="flex flex-1 mx-8" variant="outlined" size="small" fullWidth />}
         />
         <Controller
            name="project.sP6"
            control={control}
            render={({ field }) => <TextField {...field} className="flex flex-1 mx-8" variant="outlined" size="small" fullWidth />}
         />
      </div>
   );
}

function OperatorEmail(props) {
   return (
      <div className="flex flex-col mx-16 mb-10">
         <ControllerTextField name="project.sEmailOperator" className="flex flex-1 mx-8" label="E-mail notification address" />
      </div>
   );
}

function PrealertSubjectby(props) {
   return (
      <div className="flex flex-col mx-16 mb-10">
         <ControllerSelect
            name="project.sTypePreAlert"
            label="Subject by"
            options={[
               { value: '', label: '' },
               { value: 'sNo', label: 'Order No' },
               { value: 'sAdditionalInfo', label: 'Additional Info' },
               { value: 'sAdditionalInfo2', label: 'Additional Info2' },
            ]}
         />
      </div>
   );
}

function ShowOrganizationdetails(props) {
   // const orgdetail = _.find(props.company, { iId: props.item });
   const orgdetail = props.company;

   if (orgdetail !== undefined) {
      return (
         <div className="flex flex-row">
            <div className="flex-1">
               {orgdetail.sPhysicalAddress && (
                  <Typography variant="caption" color="textSecondary" className="font-semibold">
                     Physical
                  </Typography>
               )}
               {orgdetail.sPhysicalAddress && <Typography color="textSecondary">{orgdetail.sPhysicalAddress}</Typography>}
               {orgdetail.sPhysicalSuburb && <Typography color="textSecondary">{orgdetail.sPhysicalSuburb}</Typography>}
               {orgdetail.sPhysicalState && <Typography color="textSecondary">{orgdetail.sPhysicalState}</Typography>}
               {orgdetail.sPhysicalCountry && <Typography color="textSecondary">{orgdetail.sPhysicalCountry}</Typography>}
               {orgdetail.sPhone && <Typography color="textSecondary">Phone: {orgdetail.sPhone}</Typography>}
            </div>
            <div className="flex-1">
               {orgdetail.sPostalAddress && (
                  <Typography variant="caption" color="textSecondary" className="font-semibold">
                     Postal
                  </Typography>
               )}
               {orgdetail.sPostalAddress && <Typography color="textSecondary">{orgdetail.sPostalAddress}</Typography>}
               {orgdetail.sPostalSuburb && <Typography color="textSecondary">{orgdetail.sPostalSuburb}</Typography>}
               {orgdetail.sPostalCity && <Typography color="textSecondary">{orgdetail.sPostalCity}</Typography>}
               {orgdetail.sPostalCountry && <Typography color="textSecondary">{orgdetail.sPostalCountry}</Typography>}
            </div>
         </div>
      );
   }

   return '';
}

export default {
   projectStatus,
   projectModules,
   legendtypeModules,
   LegendModules,
   PriorityCodes,
   OperatorEmail,
   PrealertSubjectby,
   ShowOrganizationdetails,
};
