import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosProject from 'app/api/apiProject';

/** *********PROJECTS ************ */
export const getProjects = createAsyncThunk('commonAPI/projects/getProjects', async ({ status }) => {
   const params = { ...axiosProject.apiProject.filterListDM };
   params.status = status;

   const response = await axiosProject.apiProject.list({ ...params });
   const data = await response.data;

   return data;
});

const projectsCommonDataAdapter = createEntityAdapter({
   selectId: (project) => project.iId,
});

export const { selectAll: selectProjectsCommon, selectById: selectProjectById } = projectsCommonDataAdapter.getSelectors(
   (state) => state.commonAPI.projects.dataProjects
);

const projectsCommonSlice = createSlice({
   name: 'projects',
   initialState: {
      dataProjects: projectsCommonDataAdapter.getInitialState(),
   },
   extraReducers: {
      [getProjects.fulfilled]: (state, { payload }) => {
         projectsCommonDataAdapter.setAll(state.dataProjects, payload);
      },
   },
});

export const initialValues = {
   iId: 0,
   sCod: '',
   sName: '',
   sNameCod: '',
   iShipperId: null,
   iConsigneeId: null,
   sConsgineeName: '',
   iPrincipalClientId: null,
   sComercialInvoice: 'Commercial Invoice',
   sPackingList: 'Packing List',
   sSignOrg: '',
   sSignPosition: '',
   sProformaInvoice: '',
   sInTransitComments: '',
   sStatus: 'Active',
   sShippingMarks: '',
   sSiteName: '',
   sSiteOtherName: '',
   sLoginCreated: '',
   sClientRef: '',
   sDeliveredDays: '',
   sTimeUpDays: '0',
   sCustomSiteName: '',
   sP1: 'P1 - Hand Carry',
   sP2: 'P2 - Urgent Airfreight',
   sP3: 'P3 - Consolidated Airfreight',
   sP4: 'P4 - Direct Seafreight (FCL / LCL)',
   sP5: 'P5 - Consolidated Seafreight',
   sP6: 'P6 - Road',
   bSupply: false,
   bPostShipAct: false,
   iDaysKpi: 0,
   bDomesticShip: false,
   bException: false,
   bPoamendment: false,
   bRevisedEtanotif: false,
   bPc: false,
   bCotecnaIt: false,
   bDocReceived: false,
   sBranch: '',
   bFilePerfection: false,
   bVendorsCompliance: false,
   bBuyersPo: false,
   bPrivCommentsShip: false,
   bShipmentLeg: false,
   bShipOperator: false,
   sEmailOperator: '',
   bPackRemark: false,
   bPackDate: false,
   bClearance: false,
   bLineItems: false,
   bAdditionalInfo: false,
   bAdditionalInfo2: false,
   bUrlPo: false,
   bManifestProject: false,
   bPackScnpo: false,
   bInvoice2Model: false,
   bPreAlert: false,
   sTypePreAlert: '',
   bShipper: false,
   bInvoiceConsignee: false,
   bUploadbyClient: false,
   sExpeditingEmail: '',
   bDirectUplift: false,
   bInv2Product: false,
   bDpi: false,
   sCwdept: '',
   bScn: false,
   bScnPackManifest: false,
   bInvSummaryPackingList: false,
   bShowPostShipmentClient: false,
};
export default projectsCommonSlice.reducer;
