import Button from '@mui/material/Button';

export default function CustomButton(props) {
   const {
      className = '',
      startIcon = '',
      color = 'secondary',
      variant = 'contained',
      size = 'small',
      text = '',
      disabled = false,
      to = '',
      onClick,
      component = '',
      type = '',
   } = props;

   return (
      <Button
         className={className}
         variant={variant}
         color={color}
         {...(startIcon !== '' && { startIcon })}
         {...(component !== '' && { component })}
         {...(type !== '' && { type })}
         size={size}
         disabled={disabled}
         onClick={onClick}
         {...(to !== '' && { to })}
      >
         {text}
      </Button>
   );
}
