/* eslint-disable no-nested-ternary */
/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import settingsConfig from 'app/configs/settingsConfig';
import { initialHistoryNavigation } from 'app/store/commonAPI/historyNavigationSlice';
import { getStorageValue } from 'app/utils/UseLocalStorage';
import axiosReport from 'app/api/apiReport';
import apiUser from 'app/api/apiUser';
import { appendNavigationItem } from './fuse/navigationSlice';
import jwtService from '../auth/services/jwtService';
import authRoles from '../auth/authRoles';

export const getMenuReports = (user) => async (dispatch, getState) => {
   const params = { ...axiosReport.apiReport.filterListDM };

   const response = await axiosReport.apiReport.list({ ...params });
   const dataReport = await response.data;

   dispatch(setReportsAvailable(dataReport));

   const menuChild = {
      id: '',
      title: '',
      translate: '',
      auth: authRoles.staff,
      type: 'item',
      url: 'operator/report',
   };

   const reports = [];

   dataReport.forEach((x) => {
      reports.push({
         ...menuChild,
         id: `report${x.iId}`,
         title: x.sName,
         url: `operator/report/${x.iId}`,
         auth: x.sPermission === 'user' ? authRoles.user : x.sPermission === 'staff' ? authRoles.staff : authRoles.admin,
      });
      // dispatch(appendNavigationItem(, 'reports'));
   });

   const oherReports = [
      {
         id: 'combinedManifest',
         title: 'Combined Manfest',
         icon: '',
         auth: authRoles.user,
         type: 'item',
         url: '/operator/report/combinedManifest',
      },
      {
         id: 'docdispatchMeasurement',
         title: 'Doc, Dispatch & T/T Measurement',
         icon: '',
         type: 'item',
         auth: authRoles.staff,
         url: '/operator/report/docdispatchMeasurement',
      },
      {
         id: 'timelydispatch',
         title: 'Timely Dispatch',
         icon: '',
         type: 'item',
         auth: authRoles.staff,
         url: '/operator/report/timleyDispatch',
      },
      {
         id: 'poanalytics',
         title: 'P/O Analytics',
         icon: '',
         type: 'item',
         auth: authRoles.staff,
         url: '/operator/report/poAnalytics',
      },
      {
         id: 'scheduledreport',
         title: 'Report Scheduler',
         icon: '',
         type: 'item',
         auth: authRoles.staff,
         url: '/operator/report/schedulertasks',
      },
   ];

   const allReports = [...reports, ...oherReports].sort((a, b) => a.title.localeCompare(b.title));

   allReports.forEach((x) => {
      dispatch(appendNavigationItem(x, 'reports'));
   });
};

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
   /*
    You can redirect the logged-in user to a specific route depending on his role
    */
   if (user.loginRedirectUrl) {
      settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
   }

   // SET HISTORY NAVIGATION VIA STORAGE - PRISCILA
   dispatch(initialHistoryNavigation(_.merge([], getStorageValue(`${user.id}_historyNavigation`, []))));

   // LOAD MENU REPORTS
   if (user.role !== 'client') dispatch(getMenuReports(user));

   // LOAD MENU INVOICE
   if (user.role === 'client' && user?.data?.projectUserAccess.filter((x) => x.bShowInvoice === true).length > 0) {
      dispatch(
         appendNavigationItem({
            id: 'invoiceclient',
            title: 'Accounting',
            auth: authRoles.client,
            icon: 'heroicons-outline:cash',
            type: 'item',
            url: '/client/invoice',
         })
      );
   }

   return user;
});

export const updateUserSettings = createAsyncThunk('user/updateSettings', async (settings, { dispatch, getState }) => {
   const { user } = getState();
   const newUser = _.merge({}, user, { data: { settings } });

   dispatch(updateUserData(newUser));

   return newUser;
});

export const updateUserShortcuts = createAsyncThunk('user/updateShortucts', async (shortcuts, { dispatch, getState }) => {
   const { user } = getState();
   const newUser = {
      ...user,
      data: {
         ...user.data,
         shortcuts,
      },
   };

   dispatch(updateUserData(newUser));

   return newUser;
});

export const logoutUser = () => async (dispatch, getState) => {
   const { user } = getState();

   if (!user.role || user.role.length === 0) {
      // is guest
      return null;
   }

   history.push({
      pathname: '/',
   });

   dispatch(setInitialSettings());

   return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
   if (!user.role || user.role.length === 0) {
      // is guest
      return;
   }

   jwtService
      .updateUserData(user)
      .then(() => {
         dispatch(showMessage({ message: 'User data saved with api' }));
      })
      .catch((error) => {
         dispatch(showMessage({ message: error.message }));
      });
};

export const changePassword = createAsyncThunk('user/changePassword', async (params, { dispatch, rejectWithValue }) => {
   try {
      const response = await apiUser.apiUser.changePassword(params);

      const data = await response.data;

      dispatch(
         showMessage({
            message: 'Saved.',
            autoHideDuration: 2000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
            variant: 'success',
         })
      );

      return params;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      return rejectWithValue(`Error: ${msgError}`);
   }
});

export const getDetailUser = createAsyncThunk('user/getDetailUser', async (params, { dispatch }) => {
   const response = await apiUser.apiUser.details(params);
   const data = await response.data;

   return data;
});

export const saveUser = createAsyncThunk('user/saveUser', async (userData, { dispatch, rejectWithValue }) => {
   const finalParam = {
      UserContacts: { ...userData },
   };

   try {
      const response = await apiUser.apiUser.update(finalParam);

      const data = await response.data;

      dispatch(
         showMessage({
            message: 'Saved.',
            autoHideDuration: 2000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
            variant: 'success',
         })
      );

      return userData;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      dispatch(
         showMessage({
            message: `Error: ${msgError}`,
            autoHideDuration: 4000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'right',
            },
            variant: 'error',
         })
      );

      throw error;
   }
});

export const sendEmailForgotPassword = createAsyncThunk('user/forgotPassword', async (params, { dispatch, rejectWithValue }) => {
   try {
      const response = await apiUser.apiUser.forgotPassword(params);

      return response;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      return rejectWithValue(`Error: ${msgError}`);
   }
});

export const resetPassword = createAsyncThunk('user/resetPassword', async (params, { dispatch, rejectWithValue }) => {
   try {
      const response = await apiUser.apiResetPassword.resetPassword(params);

      return response;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      return rejectWithValue(`Error: ${msgError}`);
   }
});

const initialState = {
   id: 0,
   role: [], // guest
   from: '',
   loginRedirectUrl: '/sign-in',
   data: {
      bWelcome: false, // temporary
      regionalDataFormat: 'Australia',
      timeZone: 'Australia/Perth',
      displayName: '',
      userName: '',
      iCompanyId: 0,
      photoURL: 'assets/images/avatars/profile.jpg',
      email: '',
      bNotShowDoc: false,
      bEditCompany: false,
      shortcuts: ['apps.calendar', 'apps.mailbox', 'apps.contacts', 'apps.tasks'],
      projectUserAccess: [],
      historyNavigation: [],
      shipmentsCols: [],
      posCols: [],
      companiesCols: [],
      projectsCols: [],
      itemsCols: [],
      settings: '',
      details: {},
      reportsAvailable: [],
   },
};

const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      userLoggedOut: (state, action) => initialState,
      // temporary
      userWelcome: (state, action) => {
         state.data.bWelcome = action.payload;
      },
      setReportsAvailable: (state, action) => {
         state.data.reportsAvailable = action.payload;
      },
   },
   extraReducers: {
      [updateUserSettings.fulfilled]: (state, action) => action.payload,
      [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
      [setUser.fulfilled]: (state, action) => action.payload,
      [getDetailUser.fulfilled]: (state, action) => {
         state.data.details = action.payload;
      },
      [saveUser.fulfilled]: (state, { payload }) => {
         state.data.displayName = `${payload.sFirstName} ${payload.sSurname}`;
         state.data.details = payload;
         state.data.timeZone = payload.sTimeZone || 'Australia/Perth';
      },
      [changePassword.fulfilled]: (state, { payload }) => {
         state.data.details.sPassword = payload.sNewPassword;
      },
   },
});

export const { userLoggedOut, userWelcome, setReportsAvailable } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserShortcuts = ({ user }) => user.data.shortcuts;

export const selectUserProjects = ({ user }) => user.data.projectUserAccess;

export const selectUserReports = ({ user }) => user.data.reportsAvailable;

export const selectUserProjectId = (id) =>
   createSelector([selectUserProjects], (projects) => {
      if (id) return projects.find((x) => x.iProjectId === id);

      return null;
   });

export default userSlice.reducer;
