import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import _ from '@lodash';

const historyNavigationDataAdapter = createEntityAdapter({
   selectId: (field) => field.id || field.iId || field.sType,
   sortComparer: (a, b) => a.sType?.localeCompare(b?.sType) || b?.dDateTime?.localeCompare(a?.dDateTime),
});

export const { selectAll: selectHistoryNavigation } = historyNavigationDataAdapter.getSelectors((state) => state.commonAPI.historyNavigation);

const initialValues = {
   id: '',
   iId: 0,
   sType: '',
   sOrderNo: '',
   sProjectCode: '',
   sName: '',
   dDateTime: Date(),
};

export const saveHistoryNavigation = (data) => (dispatch, getState) => {
   //* *Check to Delete the old one */
   const historydataInitial = selectHistoryNavigation(getState());
   const dataByType = historydataInitial.filter((x) => x.sType === data.sType);
   if (dataByType.length > 15) {
      const datatoDelete = dataByType[dataByType.length - 1];
      dispatch(deleteHistory(datatoDelete));
   }

   // Update State on Redux
   dispatch(setHistory(data));

   // Update Local Storage
   const historydata = selectHistoryNavigation(getState());
   const { user } = getState();
   const key = `${user.id}_historyNavigation`;
   localStorage.setItem(key, JSON.stringify(historydata));
};

export const deleteHistoryNavigation = (data) => (dispatch, getState) => {
   // Update State on Redux
   dispatch(deleteHistory(data));

   // Update Local Storage
   const historydata = selectHistoryNavigation(getState());
   const { user } = getState();
   const key = `${user.id}_historyNavigation`;
   localStorage.setItem(key, JSON.stringify(historydata));
};

const historyNavigationSlice = createSlice({
   name: 'historyNavigation',
   initialState: historyNavigationDataAdapter.getInitialState(),
   reducers: {
      setHistory: (state, { payload }) => {
         historyNavigationDataAdapter.upsertOne(state, _.merge({ ...initialValues, id: payload.iId + payload.sType }, payload));
      },
      deleteHistory: (state, { payload }) => {
         historyNavigationDataAdapter.removeOne(state, payload.id);
      },
      // used when user logged in - populate State using storage
      initialHistoryNavigation: (state, { payload }) => {
         historyNavigationDataAdapter.setAll(state, payload);
      },
   },
});

export const { setHistory, deleteHistory, initialHistoryNavigation } = historyNavigationSlice.actions;

export default historyNavigationSlice.reducer;
