import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';
import { get } from 'lodash';

export default function ControllerSelect(props) {
   const {
      control,
      options = [],
      name,
      className,
      label,
      variant,
      required = false,
      fullWidth = true,
      disabled = false,
      multiple = false,
      optionName, // created apart to be used in the array to show the label
      optionValue,
      onChange,
      value,
   } = props;

   const varOptionValue = optionValue === undefined ? 'value' : optionValue;

   const generateSingleOptions = () => {
      return options.map((option) => {
         return (
            <MenuItem key={option[varOptionValue]} value={option[varOptionValue]}>
               {!optionName ? option.label : option[optionName]}
            </MenuItem>
         );
      });
   };

   const errorMessage = get(control?._formState?.errors[name], `message`, false);

   return (
      <FormControl
         error={!!errorMessage}
         size="small"
         variant={variant || 'outlined'}
         className={className || null}
         fullWidth={!!fullWidth}
         disabled={!!disabled}
         required={!!required}
      >
         <InputLabel id={`lbl${name}`}>{label}</InputLabel>
         <Controller
            name={name}
            control={control}
            render={({ field }) => (
               <Select
                  {...field}
                  // onChange={onChange ? onChange : field.onChange}
                  // onChange={onChange ? onChange; : field.onChange}
                  onChange={(e) => {
                     if (onChange) {
                        field.onChange(e.target.value);
                        onChange(e);
                     } else {
                        field.onChange(e.target.value);
                     }
                  }}
                  /* onChange={e => {
							e === null
								? field.onChange('')
								: e !== ''
								? field.onChange(e.target.value)
								: field.onChange('');
						}} */
                  value={value || field.value}
                  multiple={!!multiple}
                  labelId={`lbl${name}`}
                  label={label}
                  //	{...(defaultValue && { defaultValue: defaultValue })}
               >
                  {options !== null && generateSingleOptions()}
               </Select>
            )}
         />
         <FormHelperText error>{errorMessage}</FormHelperText>
      </FormControl>
   );
}

/* Example
	<ControllerAutoComplete
						name="sPhysicalCountry"
						className="mt-16 mx-4"
						label="Country"
						optionName="sCountry"
						options={countries}
					/>
*/
