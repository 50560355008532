import Popover from '@mui/material/Popover';

function CustomPopover(props) {
   const { id, mouseover = 'false', state, onClose, children } = props;

   return (
      <Popover
         {...props}
         id={id || 'poppover1'}
         {...(mouseover === 'true' && { sx: { ...{ pointerEvents: 'none' } } })}
         open={Boolean(state)}
         anchorEl={state}
         onClose={onClose}
         anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
         }}
         transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
         }}
      >
         <>{children}</>
      </Popover>
   );
}
export default CustomPopover;
