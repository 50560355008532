import { lazy } from 'react';

const ImportAUMSCIPLPage = lazy(() => import('./CIPL_UpdateLineItems/ImportAUMSCIPLPage'));

const AUMSAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/operator/aums/udpatelineitems',
         element: <ImportAUMSCIPLPage />,
      },
   ],
};

export default AUMSAppConfig;
