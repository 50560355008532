import axiosConfig from './axiosConfig';

const apiUnitItem = {
   list: (filter) =>
      axiosConfig.requests.get(
         `/unititems?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&BActive=${filter.bActive}&sDescription=${filter.sDescription}`
      ),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sDescription: '',
      bActive: true,
   },
   details: (id) => axiosConfig.requests.get(`/unititems/${id}`),
   create: (unit) => axiosConfig.requests.post('/unititems', unit),
   update: (unit) => axiosConfig.requests.put(`/unititems/${unit.id}`, unit),
   delete: (id) => axiosConfig.requests.del(`/unititems/${id}`),
};

export default { apiUnitItem };
