import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosCompany from 'app/api/apiCompany';

/** ************ COMPANY * */
export const getAgents = createAsyncThunk('commonAPI/agents/getAgents', async (dispatch) => {
   const params = { ...axiosCompany.apiCompany.filterListDM };
   params.bActive = [true];
   params.bAgent = true;

   const response = await axiosCompany.apiCompany.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const agentsCommonDataAdapter = createEntityAdapter({
   selectId: (company) => company.iId,
});

export const { selectAll: selectAgentsCommon } = agentsCommonDataAdapter.getSelectors((state) => state.commonAPI.agents.dataCompanies);

const agentsCommonSlice = createSlice({
   name: 'agents',
   initialState: {
      dataCompanies: agentsCommonDataAdapter.getInitialState(),
   },
   extraReducers: {
      [getAgents.fulfilled]: (state, { payload }) => {
         agentsCommonDataAdapter.setAll(state.dataCompanies, payload);
      },
   },
});

export default agentsCommonSlice.reducer;
