/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material/styles';
import MaterialTable from '@material-table/core';
import { settingsTable } from 'app/utils/MaterialTableSettings';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import StatusIconActiveInactive from 'app/utils/StatusIconActiveInactive';
import { selectSearchResults } from '../store/advancedSearchSlice';

function OrganizationsTab(props) {
   const dispatch = useDispatch();
   const theme = useTheme();
   const data = useSelector(selectSearchResults)?.data?.organizations || [];

   const columns = [
      {
         title: 'Code',
         field: 'sCode',
         render: (rowData) => (
            <Typography
               className="flex items-center font-medium text-md"
               component={Link}
               onClick={() => dispatch(closeDialog())}
               role="button"
               to={`/operator/company/companies/${rowData.id}`}
               color="secondary"
            >
               {rowData.sCode}
            </Typography>
         ),
      },
      {
         title: 'Name',
         field: 'sName',
      },
      {
         title: 'Integration Code',
         field: 'sCodeInterface',
      },
      {
         title: 'is Active?',
         field: 'bActive',
         render: (rowData) => <StatusIconActiveInactive status={rowData.bActive} />,
      },
      {
         title: 'Agent',
         field: 'bAgent',
         type: 'boolean',
      },
      {
         title: 'Client',
         field: 'bClient',
         type: 'boolean',
      },
      {
         title: 'Vendor',
         field: 'bVendor',
         align: 'center',
         type: 'boolean',
      },
   ];

   return (
      <div>
         <MaterialTable
            options={{
               paging: false,
               // pageSize: 10,
               // pageSizeOptions: [10, 20, data.length],
               exportAllData: true,
               toolbar: false,
               showTitle: false,
               maxBodyHeight: '410px',
               ...settingsTable(theme),
            }}
            components={{
               Container: (propsc) => <Paper {...propsc} elevation={0} />,
            }}
            title=""
            columns={columns}
            data={data}
         />
      </div>
   );
}

export default OrganizationsTab;
