import TextField from '@mui/material/TextField';
import { locales } from 'src/i18n';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { isDate, isValid, format } from 'date-fns';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

export default function ControllerDatePicker(props) {
   const { control, name, className, label, variant = 'standard', required = false, fullWidth = false, disabled = false, onChange } = props;

   const maskMap = {
      enAU: '__/__/____',
      enCA: '__/__/____',
      enUS: '__/__/____',
      es: '__.__.____',
   };

   const currentLanguageId = useSelector(({ i18n }) => i18n.language);
   const errorMessage = get(control?._formState?.errors[name], `message`, false);

   return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales[currentLanguageId]}>
         <Controller
            name={name}
            control={control}
            render={({ fieldState: { error, isTouched, isDirty, invalid }, field: { ref, ...rest }, formState: { errors } }) => (
               <DatePicker
                  {...rest}
                  renderInput={(props3) => (
                     <TextField
                        {...props3}
                        label={label}
                        size="small"
                        helperText={error ? error.message : null}
                        error={!!error || !!props3.error}
                        variant={variant}
                        className={className || null}
                        required={!!required}
                        fullWidth={!!fullWidth}
                     />
                  )}
                  // value={rest.value === '' ? null : rest.value} // treated this way because I had an error on Json passing null to datetime? typeof
                  value={rest.value}
                  componentsProps={{
                     actionBar: {
                        actions: ['today', 'clear', 'cancel'],
                     },
                  }}
                  // inputFormat="yyyy-MM-dd"
                  onChange={(e) => {
                     if (isValid(e)) {
                        // const isoDate = isDate(e) ? e?.toISOString() : e;
                        // const selectedDate = isDate(e) ? format(e, "yyyy-MM-dd'T'HH:mm:ss.SSS") : e;
                        const selectedDate = isDate(e) ? format(e, "yyyy-MM-dd'T'00:00:00").toString() : e;
                        // const convDate = `${format(selectedDate, 'yyyy-MM-dd')}T00:00.000`;
                        rest.onChange(selectedDate);
                        if (props.onChange) onChange(selectedDate);
                     } else {
                        rest.onChange(e);
                        if (props.onChange) onChange(e);
                     }
                  }}
                  // inputFormat={localeFormatShortMap[currentLanguageId]} // P = international format  had issue when typing
                  mask={maskMap[currentLanguageId]}
                  // margin="normal"
                  id={name}
                  disabled={!!disabled}
                  renderDay={(day, _value, DayComponentProps) => {
                     const isWeekend = [0, 6].includes(day.getDay());

                     if (isWeekend)
                        return (
                           <PickersDay
                              {...DayComponentProps}
                              sx={{
                                 color: 'red',
                              }}
                           />
                        );

                     return <PickersDay {...DayComponentProps} />;
                  }}
               />
            )}
         />
      </LocalizationProvider>
   );
}
