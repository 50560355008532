/* eslint-disable no-nested-ternary */
import { isDate } from 'date-fns';
import { locales } from 'src/i18n';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function ControllerTimePicker(props) {
   const { control, name, className, label, variant = 'standard', required = false, fullWidth = false, ampm = false } = props;

   const currentLanguageId = useSelector(({ i18n }) => i18n.language);

   return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locales[currentLanguageId]}>
         <Controller
            name={name}
            control={control}
            render={({ fieldState: { error, isTouched, isDirty }, field: { ref, ...rest } }) => (
               <TimePicker
                  {...rest}
                  renderInput={(props3) => (
                     <TextField
                        {...props3}
                        label={label}
                        size="small"
                        helperText={error ? error.message : null}
                        error={!!error || !!props3.error}
                        variant={variant}
                        className={className || null}
                        required={!!required}
                        fullWidth={!!fullWidth}
                     />
                  )}
                  ampm={!!ampm}
                  views={['hours', 'minutes', 'seconds']}
                  inputFormat="HH:mm:ss"
                  openTo="hours"
                  mask="__:__:__"
                  value={
                     rest.value !== '' && rest.value !== null
                        ? isDate(rest.value)
                           ? rest.value
                           : new Date().setHours(rest.value.split(':')[0], rest.value.split(':')[1], 0)
                        : '00:00:00'
                  }
                  onChange={(e) => rest.onChange(e)}
                  id={name}
               />
            )}
         />
      </LocalizationProvider>
   );
}
