import i18next from 'i18next';
import authRoles from '../auth/authRoles';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
   // client view
   {
      id: 'dashboards',
      title: 'Dashboard',
      auth: authRoles.client, // ['admin']
      subtitle: 'Unique dashboard designs',
      type: 'item',
      icon: 'heroicons-outline:home',
      url: '/client/dashboards/projects',
   },
   {
      id: 'purchaseorderclient',
      title: 'Purchase Order',
      auth: authRoles.client, // ['admin']
      // translate: 'Purchase Order',
      type: 'item',
      icon: 'ballot',
      url: '/client/purchaseorder',
   },
   {
      id: 'shipmentsclient',
      title: 'Shipments',
      translate: 'Shipments',
      auth: authRoles.client, // ['admin']
      icon: 'explore',
      type: 'item',
      url: '/client/shipment',
   },
   {
      id: 'map',
      title: 'Map',
      translate: 'Map',
      auth: authRoles.client, // ['admin']
      icon: 'map',
      type: 'item',
      url: '/client/map/shipintransit',
   },
   {
      id: 'help',
      title: 'Help',
      translate: 'Help',
      auth: authRoles.client, // ['admin']
      icon: 'heroicons-outline:question-mark-circle',
      type: 'item',
      url: '/client/helpcenter/Help',
   },
   // operator view
   {
      id: 'dashboard',
      title: 'Dashboard',
      translate: 'Dashboard',
      auth: authRoles.staff,
      type: 'item',
      icon: 'dashboard',
      url: '/operator/dashboard/main',
   },
   {
      id: 'project',
      title: 'Projects',
      translate: 'Projects',
      type: 'item',
      auth: authRoles.admin,
      icon: 'content_paste',
      url: '/operator/project',
   },
   {
      id: 'organization',
      title: 'Organization',
      translate: 'Organization',
      auth: authRoles.user,
      type: 'item',
      icon: 'business',
      url: '/operator/company',
   },
   {
      id: 'purchaseordermain',
      title: 'Purchase Order',
      auth: authRoles.user,
      // translate: 'Purchase Order',
      type: 'group',
      icon: 'ballot',
      url: '/operator/purchaseorder',
      children: [
         {
            id: 'aums_cipl',
            title: 'AUMS - Update Line Items',
            type: 'item',
            auth: authRoles.staff, // ['admin']
            url: '/operator/aums/udpatelineitems',
         },
         {
            id: 'importPOLineItems',
            title: 'Import Purchase Order & Line Items',
            type: 'item',
            auth: authRoles.staff, // ['admin']
            url: '/operator/purchaseorder/importPOLineItems',
         },
      ],
      /* children: [
			{
				id: 'purchaseorder',
				title: 'Purchase Order',
				translate: 'Purchase Order',
				type: 'item',
				url: '/operator/purchaseorder'
			},
			{
				id: 'importitems',
				title: 'Import items - Excel',
				translate: 'Import items - Excel',
				type: 'item',
				url: 'purchaseorders/items'
			},
			{
				id: 'aumsupdateitems',
				title: 'AUMS - Update Line items',
				translate: 'AUMS - Update Line items',
				type: 'item',
				url: 'purchaseorders/aums'
			},
			{
				id: 'supplierimport',
				title: 'Import Suppliers - Excel',
				translate: 'Import Suppliers - Excel',
				type: 'item',
				url: 'purchaseorder/supplier'
			},
			{
				id: 'importposanditems',
				title: 'Import POs and Line items - Excel',
				translate: 'Import POs & Line items - Excel',
				type: 'item',
				url: 'purchaseorder/importposanditems'
			}
		] */
   },
   {
      id: 'shipments',
      title: 'Shipments',
      translate: 'Shipments',
      auth: authRoles.user,
      icon: 'explore',
      type: 'item',
      url: '/operator/shipment',
      /* children: [
         {
            id: 'combinedManifest',
            title: 'Combined Manfest',
            icon: 'vertical_split',
            type: 'item',
            url: '/operator/shipment/combinedManifest',
         },
      ], */
   },
   /*  {
      id: 'rates',
      title: 'Rates',
      translate: 'Rates',
      auth: authRoles.staff, // ['admin']
      icon: 'table_view',
      type: 'collapse',
      children: [
         {
            id: 'ratemaintenance',
            title: 'Rate Maintenance',
            translate: 'Rate Maintenance',
            type: 'item',
            url: 'rates/air',
         },
         {
            id: 'rateimportfiles',
            title: 'Import Files',
            translate: 'Import Files',
            auth: authRoles.admin, // ['admin']
            type: 'item',
            url: 'rates/importair',
         },
         {
            id: 'rateindexcalc',
            title: 'Index & Sell Rates Calc',
            translate: 'Index & Sell Rates Calc',
            auth: authRoles.admin, // ['admin']
            type: 'collapse',
            children: [
               {
                  id: 'rateindexcalcair',
                  title: 'Air Freight',
                  translate: 'Air Freight',
                  type: 'item',
                  url: 'rates/air',
               },
               {
                  id: 'rateindexcalclcl',
                  title: 'LCL',
                  translate: 'LCL',
                  type: 'item',
                  url: 'rates/lcl',
               },
               {
                  id: 'rateindexcalcfcl',
                  title: 'FCL',
                  translate: 'FCL',
                  type: 'item',
                  url: 'rates/fcl',
               },
            ],
         },
         {
            id: 'ratesquarter',
            title: 'Quarter Rates',
            translate: 'Quarter Rates',
            auth: authRoles.admin, // ['admin']
            type: 'item',
            url: 'rates/quarter',
         },
         {
            id: 'ratesexchangefluctuation',
            title: 'Exchange Fluctuation',
            translate: 'Exchange Fluctuation',
            auth: authRoles.admin, // ['admin']
            type: 'item',
            url: 'rates/exchangefluctuation',
         },
         {
            id: 'ratescheckbuyandsell',
            title: 'Check Buy & Sell',
            translate: 'Check Buy & Sell',
            auth: authRoles.admin, // ['admin']
            type: 'item',
            url: 'rates/checkbuyandsell',
         },
      ],
   }, */
   {
      id: 'diary',
      title: 'Diary',
      translate: 'Diary',
      auth: authRoles.user,
      icon: 'playlist_add_check',
      type: 'item',
      url: '/operator/diarytask',
   },
   {
      id: 'reports',
      title: 'Reports',
      translate: 'Reports',
      icon: 'assessment',
      type: 'group',
      auth: authRoles.user,
      children: [
         /*   {
            id: 'rptaccount',
            title: 'Account',
            translate: 'Account',
            type: 'item',
            url: 'reports/accountreport',
         }, */
      ],
   },
   {
      id: 'masterdata',
      title: 'Reference Data',
      auth: authRoles.admin,
      type: 'group',
      icon: 'backup_table',
      children: [
         {
            id: 'currency',
            title: 'Currencies',
            type: 'item',
            auth: authRoles.admin,
            url: '/operator/masterdata/currency',
         },
         {
            id: 'equipmenttypes',
            title: 'Equipment Types',
            type: 'item',
            auth: authRoles.admin,
            url: '/operator/masterdata/equipmenttypes',
         },
         {
            id: 'incoterms',
            title: 'Incoterms',
            type: 'item',
            auth: authRoles.admin,
            url: '/operator/masterdata/incoterms',
         },
         {
            id: 'ports',
            title: 'Ports',
            type: 'item',
            auth: authRoles.admin,
            url: '/operator/masterdata/ports',
         },
         {
            id: 'unititems',
            title: 'Unit Items',
            type: 'item',
            auth: authRoles.admin,
            url: '/operator/masterdata/unititems',
         },
      ],
   },
];

export default navigationConfig;
