import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosUnitItems from 'app/api/apiUnitItem';

export const getUnitItems = createAsyncThunk('commonAPI/unititems/getUnitItems', async () => {
   const params = { ...axiosUnitItems.apiUnitItem.filterListDM };

   const response = await axiosUnitItems.apiUnitItem.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const unititemsCommonDataAdapter = createEntityAdapter({
   selectId: (unitItem) => unitItem.iId,
});

export const { selectAll: selectUnitItems } = unititemsCommonDataAdapter.getSelectors((state) => state.commonAPI.unititems.dataUnitItems);

const unitItemsCommonSlice = createSlice({
   name: 'unititems ',
   initialState: {
      dataUnitItems: unititemsCommonDataAdapter.getInitialState(),
   },
   reducers: {
      setLoading: {
         reducer: (state, action) => {
            state.loading = action.payload;
         },
      },
   },
   extraReducers: {
      [getUnitItems.fulfilled](state, { payload }) {
         unititemsCommonDataAdapter.setAll(state.dataUnitItems, payload);
         // const newEmptyValue = { iId: 0, sCod: 'TBA' };
         // incotermsCommonDataAdapter.addOne(state.dataIncoterms, newEmptyValue);
      },
   },
});

export const { setLoading } = unitItemsCommonSlice.actions;

export default unitItemsCommonSlice.reducer;
