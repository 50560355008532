import FuseLoading from '@fuse/core/FuseLoading';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from 'app/shared-components/CustomButton';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Badge from '@mui/material/Badge';
import { selectUser } from 'app/store/userSlice';
import { selectSearchResults } from './store/advancedSearchSlice';
import EquipmentsTab from './tabs/EquipmentsTab';
import PackagesTab from './tabs/PackagesTab';
import ShipmentsTab from './tabs/ShipmentsTab';
import PuchaseOrdersTab from './tabs/PuchaseOrdersTab';
import ProjectsTab from './tabs/ProjectsTab';
import OrganizationsTab from './tabs/OrganizationsTab';

const Root = styled('div')(({ theme }) => ({
   backgroundColor: theme.palette.background.paper,
   // borderBottomWidth: 1,
   // borderStyle: 'solid',
   // borderColor: theme.palette.divider,
}));

function AdvancedSearchResultDialog(props) {
   const dispatch = useDispatch();
   const [selectedTab, setSelectedTab] = useState(0);
   const result = useSelector(selectSearchResults);
   const user = useSelector(selectUser);

   function handleTabChange(event, value) {
      setSelectedTab(value);
   }

   return (
      <Dialog open fullWidth maxWidth="md" onClose={() => dispatch(closeDialog())}>
         {result.loadingDialog ? (
            <FuseLoading />
         ) : (
            <>
               <Root className="flex flex-1 lg:my-0 pt-12">
                  <Tabs
                     value={selectedTab}
                     onChange={handleTabChange}
                     indicatorColor="primary"
                     textColor="primary"
                     variant="scrollable"
                     scrollButtons="auto"
                     classes={{ root: 'w-full h-40 border-b-1' }}
                     sx={{ overflow: 'visible', '& .MuiTabs-scroller': { overflow: 'visible !important' } }}
                  >
                     <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
                        disableRipple
                        label={
                           <Badge
                              badgeContent={`${result?.data?.shipments?.length || 0}`}
                              color={result?.data?.shipments?.length > 0 ? 'error' : 'secondary'}
                           >
                              <span className="p-6">Shipments</span>
                           </Badge>
                        }
                     />
                     <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
                        disableRipple
                        label={
                           <Badge
                              badgeContent={`${result?.data?.purchaseOrders?.length || 0}`}
                              color={result?.data?.purchaseOrders?.length > 0 ? 'error' : 'secondary'}
                           >
                              <span className="p-6">Puchase Orders</span>
                           </Badge>
                        }
                     />
                     <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
                        disableRipple
                        label={
                           <Badge
                              badgeContent={`${result?.data?.equipments?.length || 0}`}
                              color={result?.data?.equipments?.length > 0 ? 'error' : 'secondary'}
                           >
                              <span className="p-6">Containers</span>
                           </Badge>
                        }
                     />
                     <Tab
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
                        disableRipple
                        label={
                           <Badge
                              badgeContent={`${result?.data?.packages?.length || 0}`}
                              color={result?.data?.packages?.length > 0 ? 'error' : 'secondary'}
                           >
                              <span className="p-6">Packages</span>
                           </Badge>
                        }
                     />

                     {user.role !== 'client' && (
                        <Tab
                           className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
                           disableRipple
                           label={
                              <Badge
                                 badgeContent={`${result?.data?.organizations?.length || 0}`}
                                 color={result?.data?.organizations?.length > 0 ? 'error' : 'secondary'}
                              >
                                 <span className="p-6">Organizations</span>
                              </Badge>
                           }
                        />
                     )}

                     {user.role !== 'client' && user.role !== 'user' && (
                        <Tab
                           className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 "
                           disableRipple
                           label={
                              <Badge
                                 badgeContent={`${result?.data?.projects?.length || 0}`}
                                 color={result?.data?.projects?.length > 0 ? 'error' : 'secondary'}
                              >
                                 <span className="p-6">Projects</span>
                              </Badge>
                           }
                        />
                     )}
                  </Tabs>
               </Root>

               <DialogContent classes={{ root: 'p-12' }} sx={{ backgroundColor: (theme) => theme.palette.background.default }}>
                  {selectedTab === 0 && <ShipmentsTab />}
                  {selectedTab === 1 && <PuchaseOrdersTab />}
                  {selectedTab === 2 && <EquipmentsTab />}
                  {selectedTab === 3 && <PackagesTab />}
                  {selectedTab === 4 && <OrganizationsTab />}
                  {selectedTab === 5 && <ProjectsTab />}

                  <div className="flex px-16 pt-16 justify-end w-full border-0">
                     <CustomButton className="m-4" variant="outlined" text="Close" color="primary" onClick={() => dispatch(closeDialog())} />
                  </div>
               </DialogContent>
            </>
         )}
      </Dialog>
   );
}

export default AdvancedSearchResultDialog;
