import Card from '@mui/material/Card';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteHistoryNavigation } from 'app/store/commonAPI/historyNavigationSlice';
import { selectUser } from 'app/store/userSlice';
import { toggleQuickPanel } from './store/stateSlice';

const ListItem = styled('li')(({ theme }) => ({
   margin: theme.spacing(0.5),
}));

export default function HistoryNavigationChips(props) {
   const { content } = props;
   const [chipData, setChipData] = useState(content);
   const user = useSelector(selectUser);
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const handleDelete = (chipToDelete) => () => {
      setChipData((chips) => chips.filter((chip) => chip.iId !== chipToDelete.iId));
      dispatch(deleteHistoryNavigation(chipToDelete));
   };

   const handleClick = (link) => () => {
      dispatch(toggleQuickPanel());
      navigate(link);
   };

   const checkType = (data) => {
      switch (data.sType) {
         case 'Project': {
            return {
               label: data.sProjectCode,
               link: `/operator/project/projects/${data.iId}`,
            };
         }
         case 'Company': {
            return { label: data.sName, link: `/operator/company/companies/${data.iId}` };
         }
         case 'PO': {
            return {
               label: data.sOrderNo,
               link:
                  user.role === 'client' ? `/client/purchaseorder/purchaseorders/${data.iId}` : `/operator/purchaseorder/purchaseorders/${data.iId}`,
            };
         }
         case 'Shipment': {
            return {
               label: data.iId,
               link: user.role === 'client' ? `/client/shipment/shipments/${data.iId}` : `/operator/shipment/shipments/${data.iId}`,
            };
         }
         default: {
            return { label: '', link: '' };
         }
      }
   };

   return (
      <Card elevation={0} className="flex flex-wrap w-full rounded-16 p-20 min-h-64 shadow content-start">
         {chipData.map((data) => {
            const { label } = checkType(data);
            const { link } = checkType(data);

            return (
               <div className="flex flex-wrap p-4" key={data.iId}>
                  <Chip label={label} onDelete={handleDelete(data)} onClick={handleClick(link)} size="small" />
               </div>
            );
         })}
      </Card>
   );
}
