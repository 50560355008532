import axiosConfig from './axiosConfig';

const apiAdvancedSearch = {
   search: (filter) => {
      return axiosConfig.requests.get(`/AdvancedSearch/List?sTerm=${filter}
         `);
   },
   /* Data Model */
   filterListDM: {
      sTerm: '',
   },
};

export default { apiAdvancedSearch };
