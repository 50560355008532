import _ from '@lodash';
import { projectModules } from '../main/operator/project/component/Components';

/** THESE COLUMNS WILL BE HIDDEN iN THE DATAGRID */

/** **************** */
/* DIARY 
/*************** */
export const diaryInitialValues = [
   { column: 'sSupplierName' },
   { column: 'dDueTime' },
   { column: 'dUpdated' },
   { column: 'dCreated' },
   { column: 'sNameCreatedBy' },
];

/** **************** */
/* PACKAGES
/*************** */
export const packagesInitialValues = [
   { column: 'sSupplierInvoiceN' },
   { column: 'sSupplierPacklistNo' },
   { column: 'bHazardous' },
   { column: 'sUn' },
   { column: 'sClass' },
   { column: 'sPackingGroup' },
];

/** **************** */
/* ITEMS 
/*************** */
export const itemsInitialValues = [
   { column: 'sPovariation' },
   { column: 'sHscode' },
   { column: 'dEta' },
   { column: 'dActual' },
   { column: 'dUpdate' },
   { column: 'dCreated' },
   { column: 'sAfmScn' },
   { column: 'sCurrency' },
   { column: 'sMaterial' },
   { column: 'sComments' },
   { column: 'sDpi' },
   { column: 'dDpiDateReported' },
   { column: 'dDpiDateReceived' },
];

/* SHIPMENTS 
/*************** */
export const shipmentsInitialValues = [
   { column: 'sVoyNo' },
   { column: 'dAta' },
   { column: 'sCargo' },
   { column: 'sEcn' },
   { column: 'sPriority' },
   { column: 'sBookingReference' },
   { column: 'sIncotermCode' },
   { column: 'sType' },
   { column: 'iSi' },
   { column: 'sBillOfLading' },
   { column: 'bConsolidation' },
   { column: 'bHazards' },
   { column: 'bOutOfGauge' },
   { column: 'sComments' },
   { column: 'sPrivComments' },
   { column: 'sDepartureAgentName' },
   { column: 'sArrivalAgentName' },
   { column: 'sSiteAgentName' },
   { column: 'dEtasite' },
   { column: 'dInitialEtasite' },
   { column: 'dPreAlert' },
   { column: 'dActualPreAlert' },
   { column: 'dInvRaised' },
   { column: 'dActualInvRaised' },
   { column: 'dInvSent' },
   { column: 'dActualInvSent' },
   { column: 'dCustomsCleared' },
   { column: 'dCustomsPerfected' },
   { column: 'dCreated' },
   { column: 'dUpdate' },
   { column: 'sLoginCreated' },
   { column: 'sLoginUpdated' },
];

/** **************** */
/* COMPANIES 
/*************** */
export const companiesInitialValues = [
   { column: 'sComments' },
   { column: 'sEmail' },
   { column: 'sPhone' },
   { column: 'sPostalCountry' },
   { column: 'sPostalAddress' },
   { column: 'sPhysicalCountry' },
   { column: 'sPhysicalState' },
   { column: 'sPhysicalSuburb' },
   { column: 'sPhysicalAddress' },
   { column: 'sAbn' },
   { column: 'bTransport' },
   { column: 'bTerminal' },
   { column: 'bPacking' },
   { column: 'bDg' },
];

export const posInitialValues = [
   { column: 'sPriorityCode' },
   { column: 'sMode' },
   { column: 'dDate' },
   { column: 'dReceived' },
   { column: 'sPublicComments' },
   { column: 'sPrivateComments' },
   { column: 'sLocation' },
   { column: 'sDpi' },
   { column: 'sInspectionType' },
   { column: 'sVendorCompliance' },
   { column: 'sBuyer' },
   { column: 'sAdditionalInfo' },
   { column: 'sAdditionalInfo2' },
   { column: 'sLoginCreated' },
   { column: 'dExp' },
   { column: 'dActualExp' },
   { column: 'dRevDue' },
   { column: 'dActualRevDue' },
   { column: 'dCreated' },
   { column: 'dUpdate' },
];

export const posInitialValuesClient = [
   { column: 'sPriorityCode' },
   { column: 'sMode' },
   { column: 'dDate' },
   { column: 'dReceived' },
   { column: 'dDue' },
   { column: 'sLocation' },
   { column: 'sDpi' },
   { column: 'sInspectionType' },
   { column: 'sVendorCompliance' },
   { column: 'sBuyer' },
   { column: 'sAdditionalInfo' },
   { column: 'sAdditionalInfo2' },
   { column: 'sLoginCreated' },
   { column: 'dExp' },
   { column: 'dActualExp' },
   { column: 'dRevDue' },
   { column: 'dActualRevDue' },
   { column: 'dCreated' },
   { column: 'dUpdate' },
];
/** **************** */
/* PROJECTS
/*************** */
const projectsInitialValuesPart1 = [
   { column: 'sShipperName' },
   { column: 'sConsgineeName' },
   { column: 'sPrincipalClientName' },
   { column: 'sClientRef' },
   { column: 'sSiteName' },
   { column: 'sComercialInvoice' },
   { column: 'sPackingList' },
   { column: 'sProformaInvoice' },
   { column: 'sSignOrg' },
   { column: 'sSignPosition' },
];

const modulesColumns = () => {
   const newArr = [];
   const modules = _.sortBy(projectModules, ['type', 'title'], ['asc', 'asc']);

   modules.map((label, idx) => {
      const newColumn = {
         column: label.value,
      };
      return newArr.push(newColumn);
   });
   return newArr;
};

export const projectsInitialValues = [...projectsInitialValuesPart1, ...modulesColumns()];

export default {
   itemsInitialValues,
   projectsInitialValues,
   companiesInitialValues,
   posInitialValues,
   shipmentsInitialValues,
   packagesInitialValues,
};
