import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'app/api/apiAdvancedSearch';

export const getSearchResult = createAsyncThunk('advancedSearchPanel/search/getSearchResult', async (term, { dispatch, getState }) => {
   const response = await axios.apiAdvancedSearch.search(term);

   return response;
});

const advancedSearchSlice = createSlice({
   name: 'advancedSearchPanel/search',
   initialState: {
      loadingDialog: true,
      data: null,
   },
   reducers: {
      setLoadingDialog: {
         reducer: (state, action) => {
            state.loadingDialog = action.payload;
         },
      },
   },
   extraReducers: {
      [getSearchResult.fulfilled]: (state, action) => {
         state.data = action.payload;
      },
   },
});

export const { setLoadingDialog } = advancedSearchSlice.actions;

export const selectSearchResults = ({ advancedSearchPanel }) => advancedSearchPanel.advancedSearchPanel;

export default advancedSearchSlice.reducer;
