import axiosConfig from './axiosConfig';

const apiCurrency = {
   list: (filter) =>
      axiosConfig.requests
         .get(`/currencies?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&sDescription=${filter.sDescription}&SCode=${filter.sCode} 
            `),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sDescription: '',
      sCode: '',
   },
   details: (id) => axiosConfig.requests.get(`/currencies/${id}`),
   create: (currency) => axiosConfig.requests.post('/currencies', currency),
   update: (currency) => axiosConfig.requests.put(`/currencies/${currency.id}`, currency),
   delete: (id) => axiosConfig.requests.del(`/currencies/${id}`),
};

const apiCurrencyExRate = {
   list: (filter) =>
      axiosConfig.requests
         .get(`/CurrenciesExchangeRates?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&sCodeCurrency=${filter.sCodeCurrency} 
            `),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sCodeCurrency: '',
   },
   create: (currency) => axiosConfig.requests.post('/CurrenciesExchangeRates', currency),
   delete: (fields) => axiosConfig.requests.del(`/CurrenciesExchangeRates/delete?sCurrencyExchange=${fields.sCode}&dDate=${fields.dDate}`),
};

export default { apiCurrency, apiCurrencyExRate };
