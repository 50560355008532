/**
 * Authorization Roles
 */
const authRoles = {
   admin: ['admin'],
   staff: ['admin', 'staff'],
   user: ['admin', 'staff', 'user'],
   client: ['client'], // Priscila
   onlyGuest: [],
};

export default authRoles;
