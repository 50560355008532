import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import authRoles from '../auth/authRoles';
import SignInConfig from '../main/sign-in/SignInConfig';
import MyAccountConfig from '../main/myaccount/MyAccountConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import Error404Page from '../main/404/Error404Page';
// import ExampleConfig from '../main/client/ExampleConfig';
import ClientConfigs from '../main/client/clientConfigs';
import OperatorConfigs from '../main/operator/operatorConfigs';

const routeConfigs = [SignOutConfig, SignInConfig, SignUpConfig, MyAccountConfig, ...OperatorConfigs, ...ClientConfigs];

const routes = [
   ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),

   {
      path: '/', // is no longer used as settingsConfig.js was changed from  loginRedirectUrl: '/'  to loginRedirectUrl:''
      element: <Navigate to="/operator/diarytask" />,
      auth: settingsConfig.defaultAuth,
   },

   {
      path: 'loading',
      element: <FuseLoading />,
   },
   {
      path: '404',
      element: <Error404Page />,
      auth: authRoles.onlyGuest,
      settings: {
         layout: {
            config: {
               navbar: {
                  display: false,
               },
               toolbar: {
                  display: false,
               },
               footer: {
                  display: false,
               },
               leftSidePanel: {
                  display: false,
               },
               rightSidePanel: {
                  display: false,
               },
            },
         },
      },
   },
   {
      path: '*',
      element: <Navigate to="404" />,
   },
];

export default routes;
