import axiosConfig from './axiosConfig';

const apiPort = {
   list: (filter) =>
      axiosConfig.requests
         .get(`/ports?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&BActive=${filter.bActive}&sName=${filter.sName}&SCode=${filter.sCode}&sCountry=${filter.sCountry}&sCity=${filter.sCity}&sType=${filter.sType}               
            `),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sName: '',
      sCountry: '',
      sCity: '',
      sCode: '',
      sType: '',
      bActive: true,
   },
   details: (id) => axiosConfig.requests.get(`/ports/${id}`),
   create: (port) => axiosConfig.requests.post('/ports', port),
   update: (port) => axiosConfig.requests.put(`/ports/${port.id}`, port),
   delete: (id) => axiosConfig.requests.del(`/ports/${id}`),
};

export default { apiPort };
