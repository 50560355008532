/* eslint-disable react/jsx-no-duplicate-props */
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

export default function ControllerTextField(props) {
   const {
      control,
      name,
      className,
      label,
      variant,
      required = false,
      InputProps = {},
      inputProps = {},
      fullWidth = true,
      disabled = false,
      autoFocus = false,
      multiline = false,
      minRows,
      maxRows,
      type = 'text',
      placeholder,
      onChange,
   } = props;

   return (
      <Controller
         name={name}
         control={control}
         // field: { onChange, value }
         render={({ field, fieldState: { error }, formState }) => (
            <TextField
               {...field}
               id={name}
               className={className || null}
               helperText={error ? error.message : null}
               size="small"
               type={type}
               error={!!error}
               // onChange={field.onChange}
               onChange={(e) => {
                  if (onChange) {
                     field.onChange(e);
                     onChange(e);
                  } else {
                     field.onChange(e);
                  }
               }}
               onBlur={field.onBlur}
               value={field.value}
               placeholder={placeholder}
               required={!!required}
               label={label}
               fullWidth={!!fullWidth}
               disabled={!!disabled}
               InputProps={InputProps || null} // endaddoment only works with InputProps
               inputProps={inputProps || null} // maxLenght only works with inputProps
               variant={variant || 'outlined'}
               autoFocus={!!autoFocus}
               multiline={!!multiline}
               minRows={minRows || null}
               maxRows={maxRows || null}
            />
         )}
      />
   );
}
