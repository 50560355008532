import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosCurrency from 'app/api/apiCurrency';

/** ************ COUNTRY * */
export const getCurrencies = createAsyncThunk('commonAPI/currencies/getCurrencies', async () => {
   const params = { ...axiosCurrency.apiCurrency.filterListDM };

   const response = await axiosCurrency.apiCurrency.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const currenciesCommonDataAdapter = createEntityAdapter({
   selectId: (currency) => currency.sCode,
});

export const { selectAll: selectCurrenciesCommon } = currenciesCommonDataAdapter.getSelectors((state) => state.commonAPI.currencies.dataCurrencies);

const currenciesCommonSlice = createSlice({
   name: 'currencies',
   initialState: {
      dataCurrencies: currenciesCommonDataAdapter.getInitialState(),
   },
   reducers: {
      setLoading: {
         reducer: (state, action) => {
            state.loading = action.payload;
         },
      },
   },
   extraReducers: {
      [getCurrencies.fulfilled](state, { payload }) {
         currenciesCommonDataAdapter.setAll(state.dataCurrencies, payload);
         const newEmptyValue = { sCode: '', sName: 'None', sDescription: '', sSymbol: '' };
         currenciesCommonDataAdapter.addOne(state.dataCurrencies, newEmptyValue);
         // / agentsDataAdapter.addOne(state.dataResult, payload);
      },
   },
});

export const { setLoading } = currenciesCommonSlice.actions;

export default currenciesCommonSlice.reducer;
