import ProjectAppConfig from './project/ProjectAppConfig';
import CompanyAppConfig from './company/CompanyAppConfig';
import PurchaseOrderAppConfig from './purchaseorder/PurchaseOrderAppConfig';
import ShipmentAppConfig from './shipment/ShipmentAppConfig';
import DiaryTaskAppConfig from './diarytask/DiaryTaskAppConfig';
import ReportAppConfig from './report/ReportAppConfig';
import DashboardAppConfig from './dashboard/dashboardConfig';
import CustomAppConfig from './custom/customConfigs';
import MasterDataConfig from './masterdata/masterdataConfig';

const OperatorConfigs = [
   ...DashboardAppConfig,
   ...CustomAppConfig,
   ...MasterDataConfig,
   ProjectAppConfig,
   CompanyAppConfig,
   PurchaseOrderAppConfig,
   ShipmentAppConfig,
   DiaryTaskAppConfig,
   ReportAppConfig,
];

export default OperatorConfigs;
