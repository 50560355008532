import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import axiosShip from 'app/api/apiShipment';
import { showMessage } from 'app/store/fuse/messageSlice';
import _ from '@lodash';

export const getShipments = createAsyncThunk('commonAPI/shipments/getShipments', async (filter, { dispatch }) => {
   const params = _.merge({}, { ...axiosShip.apiShipment.filterListDM }, filter);

   try {
      const response = await axiosShip.apiShipment.list({ ...params });

      const data = await response.data;

      return data === undefined ? null : data;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      dispatch(
         showMessage({
            message: `Error: ${msgError}`,
            autoHideDuration: 4000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'right',
            },
            variant: 'error',
         })
      );

      throw error;
   }
});

const shipsCommonDataAdapter = createEntityAdapter({
   selectId: (ship) => ship.iId,
});

export const { selectAll: selectShipsCommon, selectById: selectShipById } = shipsCommonDataAdapter.getSelectors(
   (state) => state.commonAPI.shipments.dataships
);

const shipmentsCommonSlice = createSlice({
   name: 'shipments',
   initialState: {
      dataships: shipsCommonDataAdapter.getInitialState(),
   },
   extraReducers: {
      [getShipments.fulfilled](state, { payload }) {
         shipsCommonDataAdapter.setAll(state.dataships, payload);
      },
   },
});

export default shipmentsCommonSlice.reducer;
