import { memo } from 'react';
import QuickPanel from '../../shared-components/quickPanel/QuickPanel';

function RightSideLayout3() {
   return (
      <>
         <QuickPanel />

         {/* Priscila
      <NotificationPanel />
           */}
      </>
   );
}

export default memo(RightSideLayout3);
