import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosCompany from 'app/api/apiCompany';

/** ************ COMPANY * */
export const getCarriers = createAsyncThunk('commonAPI/carriers/getCarriers', async (dispatch) => {
   const params = { ...axiosCompany.apiCompany.filterListDM };
   params.bActive = [true];
   params.bCarrier = true;

   const response = await axiosCompany.apiCompany.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const carriersCommonDataAdapter = createEntityAdapter({
   selectId: (company) => company.iId,
});

export const { selectAll: selectCarriersCommon } = carriersCommonDataAdapter.getSelectors((state) => state.commonAPI.carriers.dataCompanies);

const carriersCommonSlice = createSlice({
   name: 'carriers',
   initialState: {
      dataCompanies: carriersCommonDataAdapter.getInitialState(),
   },
   extraReducers: {
      [getCarriers.fulfilled]: (state, { payload }) => {
         carriersCommonDataAdapter.setAll(state.dataCompanies, payload);
      },
   },
});

export default carriersCommonSlice.reducer;
