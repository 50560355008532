import { Typography } from '@mui/material';

export const shipmentStatus = [
   {
      value: 'Quotations',
      name: 'Quotations',
      label: (
         <Typography
            className="bg-pink-100 text-pink-800 dark:bg-pink-600 dark:text-pink-50  inline text-sm font-medium px-8 py-4 rounded-4"
            key="Quotations"
         >
            Quotations
         </Typography>
      ),
      colorChart: '#D81B60',
   },
   {
      value: 'Cargo not yet shipped',
      name: 'Cargo not yet shipped',
      label: (
         <Typography
            className="bg-cyan-100 text-cyan-800 dark:bg-cyan-600 dark:text-cyan-50  inline text-sm font-medium px-8 py-4 rounded-4"
            key="Cargo not yet shipped"
         >
            Cargo not yet shipped
         </Typography>
      ),
      colorChart: '#00ACC1',
   },
   {
      value: 'Cargo in transit',
      name: 'Cargo in transit',
      label: (
         <Typography
            className="bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="Cargo in transit"
         >
            Cargo in transit
         </Typography>
      ),
      colorChart: '#FB8C00',
   },
   {
      value: 'Cargo arrived at port but not yet cleared',
      name: 'Cargo arrived at port but not yet cleared',
      label: (
         <Typography
            className="bg-grey-100 text-grey-800 dark:bg-grey-600 dark:text-grey-50  inline text-sm font-medium px-8 py-4 rounded-4"
            key="Cargoarrived"
         >
            Cargo arrived at port but not yet cleared
         </Typography>
      ),
      colorChart: '#9E9E9E',
   },
   {
      value: 'Cargo cleared ex port and in transit to site',
      name: 'Cargo cleared ex port and in transit to site',
      label: (
         <Typography
            className="bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-50  inline text-sm font-medium px-8 py-4 rounded-4"
            key="Cargocleared"
         >
            Cargo cleared ex port and in transit to site
         </Typography>
      ),
      colorChart: '#1E88E5',
   },
   {
      value: 'Non-Shipment Order',
      name: 'Non-Shipment Order',
      label: (
         <Typography
            className="bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50  inline text-sm font-medium px-8 py-4 rounded-4"
            key="Non-Shipment"
         >
            Non-Shipment Order
         </Typography>
      ),
      colorChart: '#E53935',
   },
   {
      value: 'Cargo delivered',
      name: 'Cargo delivered',
      label: (
         <Typography
            className="bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="delivered"
         >
            Cargo delivered
         </Typography>
      ),
      colorChart: '#1E88E5',
   },
];

export default {
   shipmentStatus,
};
