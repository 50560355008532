import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import withReducer from 'app/store/withReducer';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHistoryNavigation } from 'app/store/commonAPI/historyNavigationSlice';
import { selectUser } from 'app/store/userSlice';
import reducer from './store';
import { selectQuickPanelState, toggleQuickPanel } from './store/stateSlice';
import HistoryNavigationChips from './HistoryNavigationChips';

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
   '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.background.default,
      width: 320,
   },
}));

function QuickPanel(props) {
   const dispatch = useDispatch();

   const user = useSelector(selectUser);
   const data = useSelector(selectHistoryNavigation);
   const state = useSelector(selectQuickPanelState);

   return (
      <StyledSwipeableDrawer open={state} anchor="right" onOpen={(ev) => {}} onClose={(ev) => dispatch(toggleQuickPanel())} disableSwipeToOpen>
         <FuseScrollbars>
            <div className="p-24">
               <Typography className="text-28 font-semibold leading-none">Browsing History</Typography>
            </div>

            {data.filter((x) => x.sType === 'PO').length > 0 && (
               <>
                  <div className="text-medium pt-20 pr-20 pl-20">
                     <Icon className="text-10 mx-4" color="inherit">
                        arrow_forward_ios
                     </Icon>
                     Purchase Orders
                  </div>
                  <div className="p-20">
                     <HistoryNavigationChips content={data.filter((x) => x.sType === 'PO')} />
                  </div>

                  <Divider />
               </>
            )}

            {data.filter((x) => x.sType === 'Shipment').length > 0 && (
               <>
                  <div className="text-medium pt-20 pr-20 pl-20">
                     <Icon className="text-10 mx-4" color="inherit">
                        arrow_forward_ios
                     </Icon>
                     Shipments
                  </div>
                  <div className="p-20">
                     <HistoryNavigationChips content={data.filter((x) => x.sType === 'Shipment')} />
                  </div>

                  <Divider />
               </>
            )}

            {data.filter((x) => x.sType === 'Project').length > 0 && user.role !== 'client' && (
               <>
                  <div className="text-medium pt-20 pr-20 pl-20">
                     <Icon className="text-10 mx-4" color="inherit">
                        arrow_forward_ios
                     </Icon>
                     Projects
                  </div>
                  <div className="p-20">
                     <HistoryNavigationChips content={data.filter((x) => x.sType === 'Project')} />
                  </div>

                  <Divider />
               </>
            )}

            {data.filter((x) => x.sType === 'Company').length > 0 && user.role !== 'client' && (
               <>
                  <div className="text-medium pt-20 pr-20 pl-20">
                     <Icon className="text-10 mx-4" color="inherit">
                        arrow_forward_ios
                     </Icon>
                     Organizations
                  </div>
                  <div className="p-20">
                     <HistoryNavigationChips content={data.filter((x) => x.sType === 'Company')} />
                  </div>
               </>
            )}
         </FuseScrollbars>
      </StyledSwipeableDrawer>
   );
}

export default withReducer('quickPanel', reducer)(memo(QuickPanel));
