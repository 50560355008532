import { Typography } from '@mui/material';

export const taskStatus = [
   {
      value: '',
      name: '',
      label: '',
   },
   {
      value: 'Complete',
      name: 'Complete',
      label: (
         <Typography
            className="bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="Complete"
         >
            Complete
         </Typography>
      ),
   },
   {
      value: 'Incomplete',
      name: 'Incomplete',
      label: (
         <Typography
            className="bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="Incomplete"
         >
            Incomplete
         </Typography>
      ),
   },
];

export default {
   taskStatus,
};
