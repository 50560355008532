import axios from 'axios';
import jwtDecode from 'jwt-decode';
import FuseUtils from '@fuse/utils/FuseUtils';
import apiUser from 'app/api/apiUser';
import {
   itemsInitialValues,
   projectsInitialValues,
   companiesInitialValues,
   posInitialValues,
   posInitialValuesClient,
   shipmentsInitialValues,
   diaryInitialValues,
   packagesInitialValues,
} from 'app/utils/UserSettingsDataGridInitialValues';
import { setStorageValue } from 'app/utils/UseLocalStorage';
import jwtServiceConfig from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
   init() {
      this.setInterceptors();
      this.handleAuthentication();
   }

   /* I'm not using this setInterceptors */
   setInterceptors = () => {
      axios.interceptors.response.use(
         (response) => {
            return response;
         },
         (err) => {
            return new Promise((resolve, reject) => {
               if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                  // if you ever get an unauthorized response, logout the user
                  this.emit('onAutoLogout', 'Invalid access_token');
                  this.setSession(null);
               }
               throw err;
            });
         }
      );
   };

   handleAuthentication = () => {
      const access_token = this.getAccessToken();

      if (!access_token) {
         this.emit('onNoAccessToken');

         return;
      }

      if (this.isAuthTokenValid(access_token)) {
         this.setSession(access_token);
         this.emit('onAutoLogin', true);
      } else {
         this.setSession(null);
         this.emit('onAutoLogout', 'access_token expired');
      }
   };

   createUser = (data) => {
      return new Promise((resolve, reject) => {
         axios.post(jwtServiceConfig.signUp, data).then((response) => {
            if (response.data.user) {
               this.setSession(response.data.access_token);
               resolve(response.data.user);
               this.emit('onLogin', response.data.user);
            } else {
               reject(response.data.error);
            }
         });
      });
   };

   signInWithEmailAndPassword = (username, password) => {
      return new Promise((resolve, reject) => {
         const data = {
            password,
            username,
         };
         apiUser.apiUser
            .login(data)
            .then((response) => {
               if (response) {
                  this.setSession(response.token);
                  const userFinal = this.createUserSettingsJWT(response);
                  this.setStorage(userFinal);
                  this.emit('onLogin', userFinal);
               } else {
                  reject(response.data.errors);
               }
            })
            .catch((error) => {
               this.logout();
               if (error.data === undefined) {
                  reject(error);
               } else {
                  reject(error.data.errors);
               }
            });
      });
   };

   signInWithPasswordExpired = (username, password, newpassword) => {
      return new Promise((resolve, reject) => {
         const data = {
            password,
            username,
            newpassword,
         };
         apiUser.apiUser
            .loginexpired(data)
            .then((response) => {
               if (response) {
                  this.setSession(response.token);
                  const userFinal = this.createUserSettingsJWT(response);
                  this.setStorage(userFinal);
                  this.emit('onLogin', userFinal);
               } else {
                  reject(response.data.errors);
               }
            })
            .catch((error) => {
               this.logout();
               if (error.data === undefined) {
                  reject(error);
               } else {
                  reject(error.data.errors);
               }
            });
      });
   };

   signInWithToken = async () => {
      try {
         const user = await apiUser.apiUser.current();

         if (user) {
            this.setSession(user.token);
            return this.createUserSettingsJWT(user);
         }
      } catch (error) {
         this.logout();
         throw new Error('Failed to login with token.');
      }

      return null;
   };

   updateUserData = (user) => {
      return axios.post(jwtServiceConfig.updateUser, {
         user,
      });
   };

   setStorage = (userFinal) => {
      setStorageValue(`${userFinal.id}_items`, userFinal.data.itemsCols);
      setStorageValue(`${userFinal.id}_projects`, userFinal.data.projectsCols);
      setStorageValue(`${userFinal.id}_companies`, userFinal.data.companiesCols);
      setStorageValue(`${userFinal.id}_pos`, userFinal.data.posCols);
      setStorageValue(`${userFinal.id}_shipments`, userFinal.data.shipmentsCols);
      setStorageValue(`${userFinal.id}_historyNavigation`, userFinal.data.historyNavigation);
      setStorageValue(`${userFinal.id}_diary`, userFinal.data.diaryCols);
      setStorageValue(`${userFinal.id}_packages`, userFinal.data.packagesCols);
   };

   setSession = (access_token) => {
      if (access_token) {
         localStorage.setItem('jwt_access_token', access_token);
         axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
      } else {
         localStorage.removeItem('jwt_access_token');
         delete axios.defaults.headers.common.Authorization;
      }
   };

   logout = () => {
      this.setSession(null);
      this.emit('onLogout', 'Logged out');
   };

   isAuthTokenValid = (access_token) => {
      if (!access_token) {
         return false;
      }
      const decoded = jwtDecode(access_token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
         console.warn('access token expired');
         return false;
      }

      return true;
   };

   getAccessToken = () => {
      return window.localStorage.getItem('jwt_access_token');
   };

   /* Priscila -  I created this one to map */
   createUserSettingsJWT = (authuser) => {
      const user = {
         role: authuser.role,
         from: 'ait.api',
         id: authuser.id,
         loginRedirectUrl: authuser.role === 'client' ? '/client/dashboards/projects' : '/operator/diarytask',
         data: {
            bWelcome: authuser.bWelcome, // temporary
            iCompanyId: authuser.iCompanyId,
            userName: authuser.userName,
            bNotShowDoc: authuser.bNotShowDoc,
            bEditCompany: authuser.bEditCompany,
            displayName: authuser.displayName,
            regionalDataFormat: authuser.regionalFormatData,
            timeZone: authuser.timeZone,
            email: authuser.email,
            photoURL: 'assets/images/avatars/profile.jpg',
            settings: '',
            itemsCols: [...itemsInitialValues],
            diaryCols: [...diaryInitialValues],
            projectsCols: [...projectsInitialValues],
            companiesCols: [...companiesInitialValues],
            posCols: authuser.role === 'client' ? [...posInitialValuesClient] : [...posInitialValues],
            shipmentsCols: [...shipmentsInitialValues],
            packagesCols: [...packagesInitialValues],
            historyNavigation: [],
            projectUserAccess: authuser.projectUserAccess || [],
            reportsAvailable: [],
         },
      };

      return user;
   };
}

const instance = new JwtService();

export default instance;
