import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ControllerTextField from 'app/shared-components/ControllerTextField';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

function HeaderEntity(props) {
   const {
      icon,
      title,
      subTitle,
      inputHidden,
      textControl,
      textName,
      buttonChangeToolTip,
      buttonChangeOnClick,
      buttonChangeIcon,
      buttonLinkToolTip,
      buttonLinkAddress,
      buttonLinkIcon,
      noWrap = true,
      id,
   } = props;

   return (
      <div className="flex items-top max-w-full">
         <Icon component={motion.span} initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.2 } }} className="text-24">
            {icon}
         </Icon>
         <div className="flex flex-col min-w-0 mx-8 sm:mc-16">
            <Tooltip title={subTitle} placement="bottom">
               <Typography className="sm:flex text-15 font-semibold truncate">{subTitle}</Typography>
            </Tooltip>
            <Typography className="hidden  sm:flex font-medium  text-md">{title}</Typography>

            <input type="hidden" {...inputHidden} />

            <ControllerTextField
               control={textControl}
               name={textName}
               className="mt-8"
               required
               label=""
               disabled
               InputProps={{
                  maxLength: 20,
                  endAdornment: (
                     <InputAdornment position="end">
                        {buttonChangeIcon !== null && (
                           <Tooltip title={buttonChangeToolTip} placement="bottom">
                              <IconButton
                                 className="w-32 h-32 mx-4 p-0"
                                 color="default"
                                 size="small"
                                 onClick={() => {
                                    buttonChangeOnClick();
                                 }}
                              >
                                 {buttonChangeIcon}
                              </IconButton>
                           </Tooltip>
                        )}
                        {id > 0 && (
                           <Tooltip title={buttonLinkToolTip} placement="bottom">
                              <IconButton className="w-16 h-16 p-0" color="default" size="small" component={Link} to={buttonLinkAddress}>
                                 <Icon fontSize="small">{buttonLinkIcon}</Icon>
                              </IconButton>
                           </Tooltip>
                        )}
                     </InputAdornment>
                  ),
               }}
            />
         </div>
      </div>
   );
}

export default HeaderEntity;
