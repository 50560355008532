import { formatDate } from 'app/utils/FormatDate';
import axiosConfig from './axiosConfig';

const apiPurchaseOrder = {
   list: (filter) => {
      const paramProjects = filter.sProjectCode.length > 0 ? filter.sProjectCode.join('&sProjectCode=') : '';

      const paramShipmentStatus = filter.sShipmentStatus.length > 0 ? filter.sShipmentStatus.join('&sShipmentStatus=') : '';

      const paramOrderStatus = filter.sOrderStatus.length > 0 ? filter.sOrderStatus.join('&sOrderStatus=') : '';

      const paramModeOfTransport = filter.sModeOfTransport.length > 0 ? filter.sModeOfTransport.join('&sModeOfTransport=') : '';

      return axiosConfig.requests.get(
         `/pos?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}
			&sOrderName=${filter.sOrderName}&sProjectCode=${paramProjects}&sOrderNo=${filter.sOrderNo}
			&iShipmentId=${filter.iShipmentId}&sSupplierName=${filter.sSupplierName}
			&sShipmentStatus=${paramShipmentStatus}
			&sOrderStatus=${paramOrderStatus}&sModeOfTransport=${paramModeOfTransport}
			&dDueDate_From=${filter.dDueDate_From === null ? '' : formatDate({ value: filter.dDueDate_From, lng: 'enUS' })}
			&dDueDate_To=${filter.dDueDate_To === null ? '' : formatDate({ value: filter.dDueDate_To, lng: 'enUS' })}
			&dReceivedDate_From=${filter.dReceivedDate_From === null ? '' : formatDate({ value: filter.dReceivedDate_From, lng: 'enUS' })}
			&dReceivedDate_To=${filter.dReceivedDate_To === null ? '' : formatDate({ value: filter.dReceivedDate_To, lng: 'enUS' })}
			&dReqdAtSite_From=${filter.dReqdAtSite_From === null ? '' : formatDate({ value: filter.dReqdAtSite_From, lng: 'enUS' })}
			&dReqdAtSite_To=${filter.dReqdAtSite_To === null ? '' : formatDate({ value: filter.dReqdAtSite_To, lng: 'enUS' })}
			&dCheckin_From=${filter.dCheckin_From === null ? '' : formatDate({ value: filter.dCheckin_From, lng: 'enUS' })}
			&dCheckin_To=${filter.dCheckin_To === null ? '' : formatDate({ value: filter.dCheckin_To, lng: 'enUS' })}
			&dCreated_From=${filter.dCreated_From === null ? '' : formatDate({ value: filter.dCreated_From, lng: 'enUS' })}
			&dCreated_To=${filter.dCreated_To === null ? '' : formatDate({ value: filter.dCreated_To, lng: 'enUS' })}
			&sAdditionalInfo=${filter.sAdditionalInfo}
			`
      );
   },
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sOrderName: '',
      sProjectCode: [],
      sOrderNo: '',
      iShipmentId: '',
      sSupplierName: '',
      sAdditionalInfo: '',
      sShipmentStatus: [],
      sOrderStatus: [],
      sModeOfTransport: [],
      dDueDate_From: null,
      dDueDate_To: null,
      dReceivedDate_From: null,
      dReceivedDate_To: null,
      dReqdAtSite_From: null,
      dReqdAtSite_To: null,
      dCheckin_From: null,
      dCheckin_To: null,
      dCreated_From: null,
      dCreated_To: null,
   },
   details: (id) => axiosConfig.requests.get(`/pos/${id}`),
   create: (po) => axiosConfig.requests.post('/pos/', po),
   update: (po) => axiosConfig.requests.put(`/pos/${po.PO.id}`, po),
   delete: (id) => axiosConfig.requests.del(`/pos/${id}`),
   expeditingEmail: (fields) => axiosConfig.requests.post(`/pos/ExpeditingEmail`, fields),
   exportPO: (id) => axiosConfig.requests.getDoc(`/pos/ExportPO?IPOId=${id}`),
   associatingShipment: (list) => axiosConfig.requests.post(`/pos/AssociateShipment`, list),
   disassociateShipment: (fields) => axiosConfig.requests.post(`/pos/DisassociateShipment?IPOId=${fields.IPOId}&IShipmentId=${fields.IShipmentId}`),
   listassociatedShipments: (filter) => axiosConfig.requests.get(`/pos/ListAssociateShipments?IPOId=${filter.iPoId}`),
   listassociatedPOShipment: (filter) => axiosConfig.requests.get(`/pos/ListAssociatePOShipment?IShipmentID=${filter.iShipmentId}`),
   importPOs: (file, bUpdate, bInsert) => axiosConfig.requests.postFormFilePOLineItems('/pos/ImportPO', file, bInsert, bUpdate),
   poAmended: (fields) =>
      axiosConfig.requests.get(`/pos/amendedPOs?IPOId=${fields.IPOId}&PageNumber=${fields.pageNumber}&PageSize=${fields.pageSize}`),
};

const apiVendorCompliance = {
   details: (id) => axiosConfig.requests.get(`/povendorCompliance/${id}`),
   create: (compliances) => axiosConfig.requests.post('/povendorCompliance', compliances),
   update: (compliance) => axiosConfig.requests.put(`/povendorCompliance/${compliance.iId}`, compliance),
   delete: (id) => axiosConfig.requests.del(`/povendorCompliance/${id}`),
   noncomplianceEmail: (fields) => axiosConfig.requests.post(`/povendorCompliance/NonComplianceEmail`, fields),
};

const apiItems = {
   list: (filter) =>
      axiosConfig.requests.get(`/poitems?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}
					&iPOid=${filter.iPOId}&iShipmentId=${filter.iShipmentId}&bInvoiced=${filter.bInvoiced}
					&bUNInvoiced=${filter.bUNInvoiced}
	`),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iPOId: '',
      iShipmentId: '',
      bInvoiced: '',
      bUNInvoiced: false,
   },
   details: (id) => axiosConfig.requests.get(`/poitems/${id}`),
   totalsbyPO: (id) => axiosConfig.requests.get(`/poitems/TotalValuesPerPO?IPOId=${id}`), // retrieve total values and qty by status
   create: (item) => axiosConfig.requests.post('/poitems', item),
   update: (item) => axiosConfig.requests.put(`/poitems/${item.Item.iId}`, item),
   updateBulk: (items) => axiosConfig.requests.put(`/poitems/editbulk`, items),
   updateqty: (items) => axiosConfig.requests.put(`/poitems/editqty`, items), // create new line for partially supplied
   importPOLineItems: (file, bUpdate, bInsert) => axiosConfig.requests.postFormFilePOLineItems('/poitems/ImportPOLineItems', file, bInsert, bUpdate),
   delete: (ids) => {
      const paramIds = ids.length > 0 ? ids.join('&id=') : '';
      axiosConfig.requests.del(`/poitems/delete?id=${paramIds}`);
   },
};

export default {
   apiPurchaseOrder,
   apiVendorCompliance,
   apiItems,
};
