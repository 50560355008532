import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosDiary from 'app/api/apiDiaryTask';
import _ from '@lodash';
import { showMessage } from 'app/store/fuse/messageSlice';
import { removeTask, saveTask, allocateTaskBulk } from './diarytaskSlice';

export const getTasks = createAsyncThunk('diarytask/tasks/getTasks', async (filters, { dispatch }) => {
   const params = _.merge({}, { ...axiosDiary.apiDiaryTask.filterListDM }, filters);

   try {
      const response = await axiosDiary.apiDiaryTask.list({ ...params });

      const data = await response.data;

      return data === undefined ? null : data;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      dispatch(
         showMessage({
            message: `Error: ${msgError}`,
            autoHideDuration: 4000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'right',
            },
            variant: 'error',
         })
      );

      throw error;
   }
});

const tasksDataAdapter = createEntityAdapter({
   selectId: (field) => field.iId,
   sortComparer: (a, b) => b.sStatus.localeCompare(a.sStatus) || a.dDueTime.localeCompare(b.dDueTime),
});

export const { selectAll: selectTasks, selectById: selectTaskById } = tasksDataAdapter.getSelectors(
   (state) => state.diarytaskPage.diarytasks.dataTasks
);

const diarytasksSlice = createSlice({
   name: 'diarytaskPage/tasks',
   initialState: {
      dataTasks: tasksDataAdapter.getInitialState(),
      loading: false,
   },
   reducers: {
      resetTasks: (state) => {
         state.dataTasks = tasksDataAdapter.getInitialState();
         state.loading = false;
      },
      setLoading: {
         reducer: (state, action) => {
            state.loading = action.payload;
         },
      },
   },
   extraReducers: {
      [getTasks.fulfilled](state, { payload }) {
         tasksDataAdapter.setAll(state.dataTasks, payload);
      },
      [removeTask.fulfilled](state, { payload }) {
         tasksDataAdapter.removeOne(state.dataTasks, payload);
      },
      [saveTask.fulfilled](state, { payload }) {
         tasksDataAdapter.upsertOne(state.dataTasks, payload);
      },
      [allocateTaskBulk.fulfilled](state, { payload }) {
         tasksDataAdapter.setMany(state.dataTasks, payload);
      },
   },
});

export const { setLoading, resetTasks } = diarytasksSlice.actions;

export default diarytasksSlice.reducer;
