import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { isDate } from 'date-fns';
import FuseUtils from '@fuse/utils';
import { showMessage } from 'app/store/fuse/messageSlice';
import { formatDate } from 'app/utils/FormatDate';
import _ from '@lodash';
import axiosTask from 'app/api/apiDiaryTask';
import axiosPO from 'app/api/apiPurchaseOrder';

export const getTask = createAsyncThunk('diarytaskPage/task/getTask', async (params, { dispatch, getState }) => {
   const response = await axiosTask.apiDiaryTask.details(params);
   const dataResult = await response.data;

   return dataResult === undefined ? null : dataResult;
});

export const removeTask = createAsyncThunk('diarytaskPage/task/removeTask', async (val, { dispatch, getState }) => {
   try {
      await axiosTask.apiDiaryTask.delete(val);

      dispatch(
         showMessage({
            message: 'Deleted.',
            autoHideDuration: 2000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
            variant: 'success',
         })
      );

      return val;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      dispatch(
         showMessage({
            message: `Error: ${msgError}`,
            autoHideDuration: 4000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'right',
            },
            variant: 'error',
         })
      );

      throw error;
   }
});

export const saveTask = createAsyncThunk('diarytaskPage/task/saveTask', async (taskData, { dispatch, getState }) => {
   const { task } = getState().diarytaskPage;

   taskData.tDue = isDate(taskData.tDue) ? formatDate({ value: taskData.tDue, format: 'hourminutesecond' }) : taskData.tDue;
   taskData.iShipmentId = taskData.iShipmentId || 0;

   const finalParam = {
      DiaryTask: _.merge({}, task, taskData),
   };

   try {
      const response =
         finalParam.DiaryTask.iId !== 0 ? await axiosTask.apiDiaryTask.update(finalParam) : await axiosTask.apiDiaryTask.create(finalParam);

      const data = await response.data;

      dispatch(
         showMessage({
            message: 'Saved.',
            autoHideDuration: 2000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
            variant: 'success',
         })
      );

      return data;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      dispatch(
         showMessage({
            message: `Error: ${msgError}`,
            autoHideDuration: 4000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'right',
            },
            variant: 'error',
         })
      );

      throw error;
   }
});

export const allocateTaskBulk = createAsyncThunk('diarytaskPage/task/allocateTaskBulk', async (taskData, { dispatch, getState }) => {
   try {
      const response = await axiosTask.apiDiaryTask.allocateBulk(taskData);

      dispatch(
         showMessage({
            message: 'Saved.',
            autoHideDuration: 2000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'center',
            },
            variant: 'success',
         })
      );

      return response;
   } catch (error) {
      const msgError = error?.data?.errors || '';

      dispatch(
         showMessage({
            message: `Error: ${msgError}`,
            autoHideDuration: 4000,
            anchorOrigin: {
               vertical: 'bottom',
               horizontal: 'right',
            },
            variant: 'error',
         })
      );

      throw error;
   }
});

// retrieve POs Associated with the Shipment linked to the TASK
export const getPOsAssociated = createAsyncThunk('diarytaskPage/task/getPOsAssociated', async (filter) => {
   const params = _.merge({}, { ...axiosPO.apiPurchaseOrder.filterListDM }, filter);

   const response = await axiosPO.apiPurchaseOrder.list({ ...params });

   const data = await response.data;

   const result =
      data === undefined
         ? null
         : // eslint-disable-next-line func-names
           data.map(function (o) {
              const row = { ...o, iShipmentId: params.iShipmentId };
              return row;
           });

   return result;
});

export const getShipsAssociated = createAsyncThunk('diarytaskPage/task/getShipsAssociated', async (filter) => {
   const response = await axiosPO.apiPurchaseOrder.listassociatedShipments({ ...filter });

   return response === undefined ? null : response;
});

const diaryShipsAssociatedDataAdapter = createEntityAdapter({
   selectId: (shipment) => shipment.iId,
});

export const { selectAll: selectDiaryShipAssociated } = diaryShipsAssociatedDataAdapter.getSelectors(
   (state) => state.diarytaskPage.diarytask.shipsAssociated
);

const diaryPOsAssociatedDataAdapter = createEntityAdapter({
   selectId: (po) => po.iId,
});

export const { selectAll: selectDiaryPOsAssociated } = diaryPOsAssociatedDataAdapter.getSelectors(
   (state) => state.diarytaskPage.diarytask.posAssociated
);

export const selectTask = ({ diarytaskPage }) => diarytaskPage.diarytask.data;

export const initialValues = {
   iId: 0,
   iProjectId: 0,
   iShipmentId: 0,
   iPoId: 0,
   sStatus: 'Incomplete',
   sPriority: 'Normal',
   dDue: null,
   tDue: '00:00:00',
   iActionBy: 0,
   iCreatedBy: 0,
   iUpdatedBy: 0,
   dCreated: new Date(),
   dUpdated: null,
   sDetails: '',
   iCompany: 0,
   sNameCreatedBy: '',
   sNameUpdatedBy: '',
   sProjectCod: '',
   sOrderNo: '',
   tblDiarySubTasks: [],
};

export const subTaskModel = (data) => {
   data = data || {};

   return _.defaults(data, {
      id: FuseUtils.generateGUID(),
      iId: 0,
      iDiaryID: 0,
      sContent: '',
      bCompleted: false,
   });
};

const diarytaskSlice = createSlice({
   name: 'diarytaskPage/task',
   initialState: {
      posAssociated: diaryPOsAssociatedDataAdapter.getInitialState(),
      shipsAssociated: diaryShipsAssociatedDataAdapter.getInitialState(),
      data: null,
      rightSidebarOpen: false,
      leftSidebarOpen: true,
   },
   reducers: {
      setRightSidebarOpen: (state, action) => {
         state.rightSidebarOpen = action.payload;
         state.leftSidebarOpen = !action.payload;
      },
      setLeftSidebarOpen: (state, action) => {
         state.leftSidebarOpen = action.payload;
      },
      resetTask: (state, action) => {
         state.posAssociated = diaryPOsAssociatedDataAdapter.getInitialState();
         state.shipsAssociated = diaryShipsAssociatedDataAdapter.getInitialState();
         state.data = null;
         state.rightSidebarOpen = false;
         state.leftSidebarOpen = true;
      },
      newTask: {
         reducer: (state, action) => {
            state.data = action.payload;
            diaryPOsAssociatedDataAdapter.getInitialState();
            diaryShipsAssociatedDataAdapter.getInitialState();
         },
         prepare: (event) => {
            // prepare initial Values accordingly
            let extraValues = {};
            if (event.origin === 'ship')
               extraValues = {
                  iShipmentId: event.state?.iId,
                  iProjectId: event.state?.iProjectId,
                  sShipmentCargo: event.state?.sCargo,
               };
            else if (event.origin === 'po')
               extraValues = {
                  iPoId: event.state?.po?.iId,
                  iProjectId: event.state?.project?.iId,
                  sOrderNo: event.state?.po?.sNo,
                  sProjectCod: event.state?.po.sProjectCod,
               };
            else {
               // diary
               extraValues = {
                  iPoId: event.state?.iPoId,
                  iProjectId: event.state?.iProjectId,
                  iShipmentId: event.state?.iShipmentId,
                  sOrderNo: event.state?.sOrderNo,
                  sProjectCod: event.state?.sProjectCod,
                  sShipmentCargo: event.state?.sShipmentCargo,
               };
            }

            return {
               payload: {
                  ...initialValues,
                  ...extraValues,
               },
            };
         },
      },
      copyTask: {
         reducer: (state, action) => action.payload,
         prepare: (event) => ({
            payload: {
               ...event,
               iId: 0,
            },
         }),
      },
   },
   extraReducers: {
      [getTask.fulfilled]: (state, action) => {
         state.data = action.payload;
      },
      [saveTask.fulfilled]: (state, action) => {
         state.data = action.payload;
      },
      [getPOsAssociated.fulfilled](state, { payload }) {
         diaryPOsAssociatedDataAdapter.setAll(state.posAssociated, payload);
      },
      [getShipsAssociated.fulfilled](state, { payload }) {
         diaryShipsAssociatedDataAdapter.setAll(state.shipsAssociated, payload);
      },
      [removeTask.fulfilled]: (state, action) => {
         diaryPOsAssociatedDataAdapter.getInitialState();
         diaryShipsAssociatedDataAdapter.getInitialState();
         state.data = null;
      },
   },
});

export const { newTask, resetTask, copyTask, setRightSidebarOpen, setLeftSidebarOpen } = diarytaskSlice.actions;

export const selectRightSidebarOpen = ({ diarytaskPage }) => diarytaskPage.diarytask.rightSidebarOpen;
export const selectLeftSidebarOpen = ({ diarytaskPage }) => diarytaskPage.diarytask.leftSidebarOpen;

export default diarytaskSlice.reducer;
