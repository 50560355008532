import axiosConfig from './axiosConfig';

const apiIncoterm = {
   list: (filter) =>
      axiosConfig.requests
         .get(`/incoterms?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&BActive=${filter.bActive}&sDescription=${filter.sDescription}
            `),
   /* Data Model */
   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      sDescription: '',
      bActive: true,
   },
   details: (id) => axiosConfig.requests.get(`/incoterms/${id}`),
   create: (incoterm) => axiosConfig.requests.post('/incoterms', incoterm),
   update: (incoterm) => axiosConfig.requests.put(`/incoterms/${incoterm.id}`, incoterm),
   delete: (id) => axiosConfig.requests.del(`/incoterms/${id}`),
};

export default { apiIncoterm };
