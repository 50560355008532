import { useState } from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import CustomPopover from 'app/shared-components/CustomPopover';

function IconPopover(props) {
   const { content = '', icon = 'heroicons-outline:information-circle', id = '' } = props;

   // show popover  when mouse over icon info
   const [anchorInfoLog, setAnchorInfoLog] = useState(null);

   return (
      <div key={id}>
         <FuseSvgIcon
            size={20}
            onMouseEnter={(event) => setAnchorInfoLog(event.currentTarget)}
            onMouseLeave={() => setAnchorInfoLog(null)}
            aria-haspopup="true"
            aria-owns={anchorInfoLog ? 'mouse-over-popover' : undefined}
         >
            {icon}
         </FuseSvgIcon>

         <CustomPopover
            key={id || 'Custom'}
            id={`mouse-over-popover` || id}
            mouseover="true"
            state={anchorInfoLog}
            onClose={() => setAnchorInfoLog(null)}
         >
            <div>{content}</div>
         </CustomPopover>
      </div>
   );
}

export default IconPopover;
