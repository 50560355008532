import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosEquipmentTypes from 'app/api/apiEquipmentTypes';

export const getEquipmentTypes = createAsyncThunk('commonAPI/equipmentTypes/getEquipmentTypes', async () => {
   const params = { ...axiosEquipmentTypes.apiEquipmentType.filterListDM };

   const response = await axiosEquipmentTypes.apiEquipmentType.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const equipmentTypesCommonDataAdapter = createEntityAdapter({
   selectId: (equipmentType) => equipmentType.iId,
});

export const { selectAll: selectEquipmentTypesCommon } = equipmentTypesCommonDataAdapter.getSelectors(
   (state) => state.commonAPI.equipmentTypes.dataTypes
);

const equipmentTypesCommonSlice = createSlice({
   name: 'equipmentTypes ',
   initialState: {
      dataTypes: equipmentTypesCommonDataAdapter.getInitialState(),
   },
   reducers: {
      setLoading: {
         reducer: (state, action) => {
            state.loading = action.payload;
         },
      },
   },
   extraReducers: {
      [getEquipmentTypes.fulfilled](state, { payload }) {
         equipmentTypesCommonDataAdapter.setAll(state.dataTypes, payload);
         // const newEmptyValue = { iId: 0, sCod: 'TBA' };
         // incotermsCommonDataAdapter.addOne(state.dataIncoterms, newEmptyValue);
      },
   },
});

export const { setLoading } = equipmentTypesCommonSlice.actions;

export default equipmentTypesCommonSlice.reducer;
