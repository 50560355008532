import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Project = lazy(() => import('./project/Project'));
const Projects = lazy(() => import('./projects/Projects'));

const ProjectAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.staff, // ['admin']
   routes: [
      {
         path: '/operator/project/projects/:projectId',
         element: <Project />,
      },
      {
         path: '/operator/project/projects',
         element: <Projects />,
      },
      {
         path: '/operator/project',
         element: <Projects />,
      },
   ],
};

export default ProjectAppConfig;
