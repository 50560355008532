/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import * as yup from 'yup';
import FuseLoading from '@fuse/core/FuseLoading';
import ControllerTextField from 'app/shared-components/ControllerTextField';
import ControllerSelect from 'app/shared-components/ControllerSelect';
import ControllerTimePicker from 'app/shared-components/ControllerTimePicker';
import ControllerDatePicker from 'app/shared-components/ControllerDatePicker';
import ControllerAutoComplete from 'app/shared-components/ControllerAutoComplete';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import withReducer from 'app/store/withReducer';
import _ from '@lodash';
import { selectOperators, getOperators } from 'app/store/commonAPI/operatorsCommonSlice';
import { taskStatus } from 'app/utils/Collections/taskStatus';
import { taskPriority } from 'app/utils/Collections/taskPriority';
import { useYupValidationResolver } from 'app/utils/UseYupValidationSchema';
import { selectUser } from 'app/store/userSlice';
import reducer from '../store';
import { newTask, resetTask, getTask, initialValues, selectTask } from '../store/diarytaskSlice';
import DiaryTaskFormPOShip from './DiaryTaskFormPOShip';
import DiaryTaskFormHeader from './DiaryTaskFormHeader';
import TaskFormList from './subtasks/TaskFormList';

const schema = yup.object({
   iActionBy: yup.number().typeError('You must select an operator').required('You must select an operator').min(1, 'You must select an operator'),
   sDetails: yup.string().required('You must enter a Note'),
   sPriority: yup.string().required('You must enter a Priority'),
   sStatus: yup.string().required('You must enter a Status'),
   dDue: yup.date().required('You must enter a Due Date').typeError('Invalid Date'),
});
function DiaryTaskForm(props) {
   const dispatch = useDispatch();
   const location = useLocation();
   const resolver = useYupValidationResolver(schema);
   const routeParams = useParams();
   const methods = useForm({
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues: {
         ...initialValues,
      },
      resolver,
   });
   const { reset, watch, control, onChange, formState, register, getValues, setValue } = methods;
   const { isValid, dirtyFields, errors } = formState;

   const task = useSelector(selectTask);
   const operators = useSelector(selectOperators);
   const user = useSelector(selectUser);
   const copy = props?.location?.state; // check if copy was clicked
   // const copydataTask = useSelector((state) => selectTaskById(state, copy?.id)); //capture data of Id

   const [noDiaryTask, setNoDiaryTask] = useState(false);

   const form = watch();

   /**
    * Update Task
    */
   /* useDeepCompareEffect(() => {
      if (!isValid || _.isEmpty(form) || !task || routeParams.taskId === 'new') {
      }
   }, [form, isValid]);
*/
   useEffect(() => {
      if (routeParams.taskId === 'new') {
         dispatch(
            newTask({
               state: location.state,
               origin: routeParams.shipId ? 'ship' : routeParams.poId ? 'po' : 'diary',
            })
         );
      } else {
         dispatch(getTask(routeParams.taskId)).then((action) => {
            /**
             * If the requested diary does not exist show message
             */
            if (!action.payload) {
               setNoDiaryTask(true);
            }
         });
      }
   }, [dispatch, routeParams, location.state]);

   useEffect(() => {
      if (task?.iId === 0) reset({ ...task, iActionBy: user.id });
      else reset({ ...task });
   }, [task, reset, task?.iId, user.id]);

   useEffect(() => {
      return () => {
         /**
          * Reset  diary
          */
         dispatch(resetTask());
         setNoDiaryTask(false);
      };
   }, [dispatch]);

   useEffect(() => {
      /**
       * Load  operators
       */
      if (operators.length === 0) {
         dispatch(getOperators());
      }
   }, [dispatch, operators.length]);

   function checkSubTasksChecked() {
      return form.tblDiarySubTasks === undefined ? [] : _.sum(form.tblDiarySubTasks.map((x) => (x.bCompleted ? 1 : 0)));
   }

   /**
    * Show Message if the requested company does not exist
    */
   if (noDiaryTask) {
      return (
         <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-col flex-1 items-center justify-center h-full"
         >
            <Typography color="textSecondary" variant="h5">
               There is no such diary and tasks!
            </Typography>
            <Button className="mt-24" component={Link} variant="outlined" to="/operator/diarytask" color="inherit">
               Go to Diary and Tasks Page
            </Button>
         </motion.div>
      );
   }

   /**
    * Wait while company data is loading and form is setted
    */
   if (_.isEmpty(form) || (task && routeParams.taskId !== task.iId.toString() && routeParams.taskId !== 'new')) {
      return <FuseLoading />;
   }

   return (
      <FormProvider {...methods}>
         <div className="relative flex flex-col flex-auto items-center px-24 sm:px-24  ">
            <div className="flex items-center justify-between border-b-1 w-full  py-12 mb-32">
               <DiaryTaskFormHeader />
            </div>

            <div className="flex flex-col sm:flex-row mb-32 w-full">
               <DiaryTaskFormPOShip />
            </div>

            <div className="flex">
               <div className="flex flex-col border-0">
                  <div className="flex flex-col sm:flex-row border-0">
                     {/* Main fields */}
                     <div className="flex flex-col sm:flex-row flex-wrap sm:w-1/3 sm:pr-10 content-start mx-4 ">
                        <div className="sm:flex-row w-full border-0">
                           <ControllerSelect
                              control={control}
                              name="sStatus"
                              label="Task Status"
                              variant="outlined"
                              options={taskStatus}
                              required
                              optionName="label"
                              optionValue="value"
                           />
                        </div>
                        <div className="sm:flex-row border-0 w-full pt-16">
                           <ControllerSelect
                              control={control}
                              name="sPriority"
                              label="Task Priority"
                              required
                              variant="outlined"
                              options={taskPriority}
                              optionName="label"
                              optionValue="value"
                           />
                        </div>
                        <div className="sm:flex-row border-0 w-full pt-16">
                           <ControllerDatePicker control={control} variant="outlined" name="dDue" required label="Due Date" fullWidth />
                        </div>
                        <div className="sm:flex-row border-0 w-full  pt-16">
                           <ControllerTimePicker control={control} variant="outlined" name="tDue" required label="Time" />
                        </div>
                        <div className="sm:flex-row border-0 w-full  pt-16">
                           <ControllerAutoComplete
                              control={control}
                              name="iActionBy"
                              options={operators}
                              label="Action by"
                              optionId="iId"
                              variant="outlined"
                              required
                              getOptionLabel={(option) => (option.sFullName === undefined ? '' : option.sFullName)}
                           />
                        </div>
                     </div>
                     <div className="flex w-full sm:w-2/3 mx-4 ">
                        <div className="sm:flex-row border-0 w-full pt-16 sm:pt-0 ">
                           <ControllerTextField
                              control={control}
                              name="sDetails"
                              placeholder="Writing a note.."
                              multiline
                              minRows={16}
                              maxRows={20}
                              required
                              label="Notes"
                              inputProps={{
                                 maxLength: 5000,
                                 style: { fontSize: 13 },
                              }}
                           />
                        </div>
                     </div>
                  </div>

                  {/* Checklist - SubTasks */}
                  <div className="flex flex-col justify-between mt-16 mb-12 border-0">
                     <div className="flex items-center">
                        <FuseSvgIcon size={20}>heroicons-outline:check-circle</FuseSvgIcon>
                        <Typography className="font-semibold text-16 mx-8">Checklist</Typography>
                        <Typography className="flex font-semibold mx-6">{`${checkSubTasksChecked()} / ${form.tblDiarySubTasks?.length}`}</Typography>
                     </div>
                     <div className="flex items-center w-full">
                        <Controller
                           name="tblDiarySubTasks"
                           control={control}
                           defaultValue={[]}
                           render={({ field: { onChange, value } }) => {
                              return (
                                 <div className="px-16 w-full">
                                    <TaskFormList tasks={value || []} onCheckListChange={(val) => onChange(val)} />
                                 </div>
                              );
                           }}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </FormProvider>
   );
}

export default withReducer('diarytaskPage', reducer)(DiaryTaskForm);
