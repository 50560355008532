import { lazy } from 'react';

const ReportApp = lazy(() => import('./ReportApp'));
const CombinedManifest = lazy(() => import('./otherreports/combinedManifest/CombinedManifestPage'));
const DocdispatchMeasurement = lazy(() => import('./otherreports/docdispatchMeasurement/DocdispatchMeasurementPage'));
const TimelyDispatch = lazy(() => import('./otherreports/timelyDispatch/TimelyDispatchPage'));
const POAnalytics = lazy(() => import('./otherreports/poAnalytics/POAnalyticsPage'));
const SchedulerTasks = lazy(() => import('./scheduler/SchedulerTasks'));
const SchedulerTask = lazy(() => import('./scheduler/SchedulerTaskContent/SchedulerTask'));
const TestAutoComplete = lazy(() => import('./test/TestAutoComplete'));

const ReportAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/operator/report/:reportId',
         element: <ReportApp />,
      },
      {
         path: '/operator/report/combinedManifest/',
         element: <CombinedManifest />,
      },
      {
         path: '/operator/report/docdispatchMeasurement/',
         element: <DocdispatchMeasurement />,
      },
      {
         path: '/operator/report/timleyDispatch/',
         element: <TimelyDispatch />,
      },
      {
         path: '/operator/report/poAnalytics/',
         element: <POAnalytics />,
      },
      {
         path: '/operator/report/schedulertasks/',
         element: <SchedulerTasks />,
      },
      {
         path: '/operator/report/schedulertasks/:taskId',
         element: <SchedulerTask />,
      },
      {
         path: '/operator/report/test',
         element: <TestAutoComplete />,
      },
   ],
};

export default ReportAppConfig;
