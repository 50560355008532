import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosIncoterms from 'app/api/apiIncoterm';

export const getIncoterms = createAsyncThunk('commonAPI/incoterms/getincoterms', async () => {
   const params = { ...axiosIncoterms.apiIncoterm.filterListDM };

   const response = await axiosIncoterms.apiIncoterm.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const incotermsCommonDataAdapter = createEntityAdapter({
   selectId: (Incoterm) => Incoterm.iId,
});

export const { selectAll: selectIncotermsCommon } = incotermsCommonDataAdapter.getSelectors((state) => state.commonAPI.incoterms.dataIncoterms);

const incotermsCommonSlice = createSlice({
   name: 'incoterms',
   initialState: {
      dataIncoterms: incotermsCommonDataAdapter.getInitialState(),
   },
   reducers: {
      setLoading: {
         reducer: (state, action) => {
            state.loading = action.payload;
         },
      },
   },
   extraReducers: {
      [getIncoterms.fulfilled](state, { payload }) {
         incotermsCommonDataAdapter.setAll(state.dataIncoterms, payload);
         const newEmptyValue = { iId: 0, sCod: 'TBA' };
         incotermsCommonDataAdapter.addOne(state.dataIncoterms, newEmptyValue);
      },
   },
});

export const { setLoading } = incotermsCommonSlice.actions;

export default incotermsCommonSlice.reducer;
