import axiosConfig from './axiosConfig';

const apiUser = {
   current: () => axiosConfig.requests.get('/user/currentuser'),
   login: async (user) => {
      axiosConfig.instanceTokenAlternative.defaults.headers.common.Authorization = ``;
      const response = await axiosConfig.instanceTokenAlternative.post(`/user/login/`, user);
      return response.data;
   },
   loginexpired: async (user) => {
      axiosConfig.instanceTokenAlternative.defaults.headers.common.Authorization = ``;
      const response = await axiosConfig.instanceTokenAlternative.post(`/user/loginexpired/`, user);
      return response.data;
   },
   register: (user) => axiosConfig.requests.post('/user/register', user),
   list: (filter) => {
      const paramAccess = filter.sAccess.length > 0 ? filter.sAccess.join('&SAccess=') : '';

      return axiosConfig.requests.get(
         `/user?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&ICompanyId=${filter.iCompanyId}&SUserName=${filter.sUserName}&SCompanyName=${filter.sCompanyName}&BActive=${filter.bActive}
			&BShowProjects=${filter.bShowProjects}&SAccess=${paramAccess}`
      );
   },

   editList: (filter) => {
      const paramAccess = filter.sAccess.length > 0 ? filter.sAccess.join('&SAccess=') : '';

      return axiosConfig.requests.get(
         `/user/editUserList?PageNumber=${filter.pageNumber}&PageSize=${filter.pageSize}&ICompanyId=${filter.iCompanyId}&SUserName=${filter.sUserName}&SCompanyName=${filter.sCompanyName}&BActive=${filter.bActive}
			&BShowProjects=${filter.bShowProjects}&SAccess=${paramAccess}`
      );
   },

   filterListDM: {
      pageNumber: 1,
      pageSize: 99999,
      iCompanyId: 0,
      sUserName: '',
      sCompanyName: '',
      bActive: '',
      bShowProjects: false,
      sAccess: [],
   },

   details: (id) => axiosConfig.requests.get(`/user/${id}`),
   create: (fields) => axiosConfig.requests.post('/user', fields),
   unlockaccount: (user) => axiosConfig.requests.put('/user/UnlockAccount', user),
   update: (field) => axiosConfig.requests.put(`/user/${field.iId}`, field),
   changePassword: (fields) => axiosConfig.requests.put(`/user/ChangePassword/`, fields),
   delete: (id) => axiosConfig.requests.del(`/user/${id}`),
   movetoanothercompany: (fields) => axiosConfig.requests.put(`/user/movetoanothercompany`, fields),
   forgotPassword: async (fields) => {
      const response = await axiosConfig.instanceTokenAlternative.post('/user/ForgotPassword', fields);
      return response.data;
   },
};

const apiResetPassword = {
   resetPassword: async (fields) => {
      axiosConfig.instanceTokenAlternative.defaults.headers.common.Authorization = `Bearer ${fields.token}`;
      const response = await axiosConfig.instanceTokenAlternative.put(`/user/ChangePassword/`, fields);
      return response.data;
   },
};

export default { apiUser, apiResetPassword };
