import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const ShipInTransit = lazy(() => import('./shipintransit/ShipInTransit'));

const MapAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.client, // ['admin']
   routes: [
      {
         path: '/client/map/shipintransit',
         element: <ShipInTransit />,
      },
   ],
};

export default MapAppConfig;
