import { lazy } from 'react';

const IncotermsPage = lazy(() => import('./IncotermsPage'));

const IncotermsPageConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/operator/masterdata/incoterms',
         element: <IncotermsPage />,
      },
   ],
};

export default IncotermsPageConfig;
