/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetPassword } from 'app/store/userSlice';
import jwtDecode from 'jwt-decode';
import { motion } from 'framer-motion';
import { Alert } from '@mui/material';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
   passwordConfirm: yup
      .string()
      .required('Please enter your password.')
      .min(9, 'Password is too short - must be at least 9 characters.')
      .oneOf([yup.ref('password'), null], 'Passwords must match.'),
   password: yup.string().required('Please enter your password.').min(9, 'Password is too short - must be at least 9 characters.'),
});

const defaultValues = {
   passwordConfirm: '',
   password: '',
};

function ResetPassword(props) {
   const { handleScreen, token } = props;
   const dispatch = useDispatch();

   const [showPassword, setShowPassword] = useState(false);
   const [showPassword2, setShowPassword2] = useState(false);
   const [showMsg, setShowMsg] = useState(['', false]);
   const [noToken, setNoToken] = useState(false);
   const [username, setUserName] = useState('');
   const [userId, setUserId] = useState(0);

   const { control, formState, handleSubmit, setError, setValue } = useForm({
      mode: 'onChange',
      defaultValues,
      resolver: yupResolver(schema),
   });

   const { isValid, dirtyFields, errors } = formState;

   useEffect(() => {
      // check if token is valid.
      if (!token) {
         setNoToken(true);
      }

      try {
         const decoded = jwtDecode(token);
         const currentTime = Date.now() / 1000;
         if (decoded.website !== 'reset') {
            setNoToken(true);
         } else if (decoded.exp < currentTime) {
            setNoToken(true);
         } else {
            setUserName(decoded.unique_name);
            setUserId(decoded.nameid);
         }
      } catch {
         setNoToken(true);
      }
   }, [token]);

   function onSubmit({ password }) {
      dispatch(resetPassword({ Id: userId, sNewPassword: password, sType: 'reset', token })).then((action) => {
         if (action.error === undefined) {
            setShowMsg(['Your password has been reset!', false]);
         } else {
            setShowMsg([action.payload, true]);
         }
      });
   }

   if (noToken) {
      return (
         <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            className="flex flex-col flex-1 items-center justify-center h-full"
         >
            <Typography color="textSecondary" variant="h6">
               This password reset token is invalid.
            </Typography>
            <Button
               className="mt-24"
               component={Link}
               variant="outlined"
               to="/sign-in"
               color="inherit"
               onClick={() => {
                  setShowMsg(['', false]);
                  handleScreen('sign-in');
               }}
            >
               Go to Sign in
            </Button>
         </motion.div>
      );
   }

   return (
      <>
         <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">Reset your password</Typography>
         <Typography className="font-medium">Create a new password for your account</Typography>

         <form name="resetForm" noValidate className="flex flex-col justify-center w-full mt-32" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-24">Username: {username}</div>
            <Controller
               name="password"
               control={control}
               render={({ field }) => (
                  <TextField
                     {...field}
                     className="mb-24"
                     label="Password"
                     type="password"
                     error={!!errors.password}
                     helperText={errors?.password?.message}
                     variant="outlined"
                     required
                     fullWidth
                     inputProps={{
                        maxLength: 20,
                     }}
                     InputProps={{
                        className: 'pr-2',
                        autoComplete: 'off',
                        type: showPassword ? 'text' : 'password',
                        /*  endAdornment: (
                           <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword(!showPassword)}>
                                 <Icon className="text-20" color="action">
                                    {showPassword ? 'visibility' : 'visibility_off'}
                                 </Icon>
                              </IconButton>
                           </InputAdornment>
                        ), */
                     }}
                  />
               )}
            />
            <Controller
               name="passwordConfirm"
               control={control}
               render={({ field }) => (
                  <TextField
                     {...field}
                     className="mb-24"
                     label="Password (Confirm)"
                     type="password"
                     error={!!errors.passwordConfirm}
                     helperText={errors?.passwordConfirm?.message}
                     variant="outlined"
                     required
                     fullWidth
                     inputProps={{
                        maxLength: 20,
                     }}
                     InputProps={{
                        className: 'pr-2',
                        autoComplete: 'off',
                        type: showPassword2 ? 'text' : 'password',
                        /* endAdornment: (
                           <InputAdornment position="end">
                              <IconButton onClick={() => setShowPassword2(!showPassword2)}>
                                 <Icon className="text-20" color="action">
                                    {showPassword2 ? 'visibility' : 'visibility_off'}
                                 </Icon>
                              </IconButton>
                           </InputAdornment>
                        ), */
                     }}
                  />
               )}
            />
            <Button
               variant="contained"
               color="secondary"
               className=" w-full mt-4"
               aria-label="Register"
               disabled={_.isEmpty(dirtyFields) || !isValid}
               type="submit"
               size="large"
            >
               Reset your password
            </Button>
            {showMsg[0] !== '' && (
               <Alert className="mt-12" severity={showMsg[1] === true ? 'error' : 'success'}>
                  <Typography className="text-md font-medium" color={showMsg[1] === true ? 'error.main' : 'success.main'}>
                     {showMsg[0]}
                  </Typography>
               </Alert>
            )}

            <div className="mt-8">
               <Typography className="text-md font-medium" color="text.secondary">
                  Password must meet complexity requirements as below:
                  <ul className=" list-disc">
                     <li>At least 9 characters is required to create a new password;</li>
                     <li> The new password must be different of from the 5 previous passwords;</li>
                     <li className="mb-6">
                        The new password must be contained with Uppercase/Lowercase characters, numbers, and special characters. E.g. @nDr3w^69.{' '}
                     </li>
                  </ul>
               </Typography>
            </div>
            <Typography className="mt-32 text-md font-medium" color="text.secondary">
               <span>Return to</span>
               <Link
                  className="ml-4"
                  to="/sign-in"
                  onClick={() => {
                     setShowMsg(['', false]);
                     handleScreen('sign-in');
                  }}
               >
                  sign in
               </Link>
            </Typography>
         </form>
      </>
   );
}

export default ResetPassword;
