import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosCountry from 'app/api/apiCountry';
import PaginationModel from 'app/utils/PaginationApiModel';

/** ************ COUNTRY * */
export const getCountries = createAsyncThunk('commonAPI/countries/getCountries', async () => {
   const params = PaginationModel.PageSize9999ApiModel();
   const response = await axiosCountry.apiCountry.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const countriesCommonDataAdapter = createEntityAdapter({
   selectId: (country) => country.iId,
});

export const { selectAll: selectCountriesCommon } = countriesCommonDataAdapter.getSelectors((state) => state.commonAPI.countries.dataCountries);

const countriesCommonSlice = createSlice({
   name: 'countries',
   initialState: {
      dataCountries: countriesCommonDataAdapter.getInitialState(),
   },
   reducers: {
      setLoading: {
         reducer: (state, action) => {
            state.loading = action.payload;
         },
      },
   },
   extraReducers: {
      [getCountries.fulfilled](state, { payload }) {
         countriesCommonDataAdapter.setAll(state.dataCountries, payload);
         const newEmptyValue = { iId: '', sCountry: '', sCode: '' };
         countriesCommonDataAdapter.addOne(state.dataCountries, newEmptyValue);
      },
   },
});

export const { setLoading } = countriesCommonSlice.actions;

export default countriesCommonSlice.reducer;
