import { lazy } from 'react';

const EquipmentPage = lazy(() => import('./EquipmentPage'));

const EquipmentPageConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/operator/masterdata/equipmenttypes',
         element: <EquipmentPage />,
      },
   ],
};

export default EquipmentPageConfig;
