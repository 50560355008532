/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert } from '@mui/material';
import { sendEmailForgotPassword } from 'app/store/userSlice';
import _ from '@lodash';

/**
 * Form Validation Schema
 */

const schemaForgotPassword = yup.object().shape({
   username: yup.string().required('You must enter an username'),
});

function ForgotPassword(props) {
   const { handleScreen } = props;
   const dispatch = useDispatch();
   const routeParams = useParams();
   const [showMsg, setShowMsg] = useState(['', false]);

   const { control, formState, handleSubmit, setError, setValue, getValues } = useForm({
      mode: 'onChange',
      defaultValues: {
         username: '',
      },
      resolver: yupResolver(schemaForgotPassword),
   });

   const { isValid, dirtyFields, errors } = formState;

   function sendEmailPassword() {
      const formData = getValues();

      dispatch(sendEmailForgotPassword({ sUserName: formData.username })).then((action) => {
         if (action.payload === 'sent') {
            setShowMsg(['We have e-mailed your password reset link!', false]);
         } else {
            setShowMsg([action.payload, true]);
         }
      });
   }

   return (
      <>
         <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">Forgot password?</Typography>
         <div className="flex items-baseline mt-2 font-medium">
            <Typography>Fill the form to reset your password</Typography>
         </div>
         <form name="registerForm" noValidate className="flex flex-col justify-center w-full mt-32">
            <Controller
               name="username"
               control={control}
               render={({ field }) => (
                  <TextField
                     {...field}
                     className="mb-24"
                     label="Username"
                     error={!!errors.username}
                     helperText={errors?.username?.message}
                     variant="outlined"
                     required
                     fullWidth
                     inputProps={{
                        autoComplete: 'off',
                     }}
                  />
               )}
            />

            <Button
               variant="contained"
               color="secondary"
               className=" w-full mt-4"
               aria-label="Register"
               disabled={_.isEmpty(dirtyFields) || !isValid}
               // type="submit"
               size="large"
               onClick={sendEmailPassword}
            >
               Send reset link
            </Button>
            {showMsg[0] !== '' && (
               <Alert className="mt-12" severity={showMsg[1] === true ? 'error' : 'success'}>
                  <Typography className="text-md font-medium" color={showMsg[1] === true ? 'error.main' : 'success.main'}>
                     {showMsg[0]}
                  </Typography>
               </Alert>
            )}

            <Typography className="mt-32 text-md font-medium" color="text.secondary">
               <span>Return to</span>
               <Link
                  className="ml-4"
                  to="/sign-in"
                  onClick={() => {
                     setValue('username', '');
                     setShowMsg(['', false]);
                     handleScreen('sign-in');
                  }}
               >
                  sign in
               </Link>
            </Typography>
         </form>
      </>
   );
}

export default ForgotPassword;
