import { lazy } from 'react';

const UnitItemPage = lazy(() => import('./UnitItemPage'));

const UnitItemPageConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/operator/masterdata/unititems',
         element: <UnitItemPage />,
      },
   ],
};

export default UnitItemPageConfig;
