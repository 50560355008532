import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axiosCompany from 'app/api/apiCompany';
import _ from '@lodash';

/** ************ COMPANY * */
export const getCompanies = createAsyncThunk('commonAPI/companies/getCompanies', async ({ search, idStatus, idTypes }, dispatch) => {
   const params = { ...axiosCompany.apiCompany.filterListDM };
   params.bActive = idStatus !== undefined && idStatus.length === 1 ? idStatus[0] : '';

   if (idTypes !== undefined) {
      params.bAgent = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bAgent');
      params.bClient = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bClient');
      params.bCarrier = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bCarrier');
      params.bVendor = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bVendor');
      params.bTransport = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bTransport');
      params.bWarehouse = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bWarehouse');
      params.bPacking = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bPacking');
      params.bTerminal = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bTerminal');
      params.bDg = idTypes.length === 0 ? '' : !!_.find(idTypes, (x) => x === 'bDg');
   }

   params.sName = search;
   params.sCode = search;
   params.sCodeInterface = search;
   params.sFilterbyEntity = 'Or';

   const response = await axiosCompany.apiCompany.list({ ...params });
   const data = await response.data;

   return data === undefined ? null : data;
});

const companiesCommonDataAdapter = createEntityAdapter({
   selectId: (company) => company.iId,
});

export const { selectAll: selectCompaniesCommon, selectById: selectCompanyById } = companiesCommonDataAdapter.getSelectors(
   (state) => state.commonAPI.companies.dataCompanies
);

const companiesCommonSlice = createSlice({
   name: 'companies',
   initialState: {
      dataCompanies: companiesCommonDataAdapter.getInitialState(),
   },
   extraReducers: {
      [getCompanies.fulfilled]: (state, { payload }) => {
         companiesCommonDataAdapter.setAll(state.dataCompanies, payload);
      },
   },
});

export default companiesCommonSlice.reducer;
