import { lazy } from 'react';
import authRoles from '../../../auth/authRoles';
import DiaryTaskForm from '../diarytask/diarytask-form/DiaryTaskForm';

const PurchaseOrder = lazy(() => import('./purchaseorder/PurchaseOrder'));
const PurchaseOrders = lazy(() => import('./purchaseorders/PurchaseOrders'));
const ImportPOLineItems = lazy(() => import('./importPOAndLineItems/ImportPOLineItemsPage'));

const PurchaseOrderAppConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   auth: authRoles.user, // ['admin']
   routes: [
      {
         path: '/operator/purchaseorder/importPOLineItems',
         element: <ImportPOLineItems />,
      },
      {
         path: '/operator/purchaseorder/purchaseorders/:poId',
         element: <PurchaseOrder />,
         children: [
            {
               path: 'diarytask/:taskId',
               element: <DiaryTaskForm />,
            },
         ],
      },
      {
         path: '/operator/purchaseorder/purchaseorders',
         element: <PurchaseOrders />,
      },

      {
         path: '/operator/purchaseorder',
         element: <PurchaseOrders />,
      },
   ],
};

export default PurchaseOrderAppConfig;
