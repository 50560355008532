import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Icon from '@mui/material/Icon';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import FuseLoading from '@fuse/core/FuseLoading';
import ControllerAutoComplete from 'app/shared-components/ControllerAutoComplete';
import _ from '@lodash';
import CustomButton from 'app/shared-components/CustomButton';
/**
 * Form Validation Schema
 */
const schemaSingle = yup.object().shape({
   modalInput: yup.string().required('You must select an item.'),
});

const schemaMultiple = yup.object().shape({
   modalInput: yup.array().min(1, 'You must select an item.'),
});

function BasicDialogAutoComplete(props) {
   const {
      dialogTitle = '',
      dialogContentText = '',
      // inputValue,
      inputName1 = '',
      inputName2 = '',
      // inputNameId,
      inputClassName = 'mt-12',
      inputDisableCloseOnSelect = false,
      inputMultiple = false,
      inputLabel,
      inputOptionId = 'iId',
      inputGetOptionLabel,
      inputRequired = false,
      inputRenderOption,
      buttonTextOK,
      // buttonOKfunction = '',
      callfunction,
      calluseSelector,
      // setModalFormData,
      receiveData,
   } = props;

   const dispatch = useDispatch();
   const [loading, setLoading] = useState(true);
   const data = useSelector(calluseSelector);
   const theme = useTheme();

   const methods = useForm({
      mode: 'onChange',
      resolver: inputRequired && (inputMultiple ? yupResolver(schemaMultiple) : yupResolver(schemaSingle)),
   });

   const { control, onChange, formState, handleSubmit, getValues, setValue } = methods;
   const { isValid, dirtyFields } = formState;

   const calluseCallBack = useCallback(() => {
      callfunction().then(() => setLoading(false));
   }, [callfunction]);

   useEffect(() => {
      calluseCallBack();
   }, [calluseCallBack]);

   /* stopped loop infinite */
   /*	useDeepCompareEffect(() => {
		function loadData() {
			callfunction().then(() => setLoading(false));
		}

		loadData();
	}, [dispatch, data]);
*/
   const onSubmitForm = () => {
      let dataInputName1 = '';
      let dataInputName2 = '';
      let dataObject = '';
      if (inputName1 !== '' && inputName2 !== '') {
         const objData = data.find((x) => {
            return x[inputOptionId] === getValues('modalInput');
         });

         dataObject = objData;
         dataInputName1 = objData ? objData[inputName1] : '';
         dataInputName2 = objData ? objData[inputName2] : '';
      }

      receiveData(getValues('modalInput'), dataInputName1, dataInputName2, dataObject);

      dispatch(closeDialog());
   };

   if (loading) {
      return <FuseLoading />;
   }

   return (
      <form>
         <Dialog open fullWidth maxWidth="xs">
            <AppBar position="static" elevation={0}>
               <Toolbar className="flex">
                  <Icon className="text-14 mx-4" color="inherit">
                     arrow_forward_ios
                  </Icon>
                  {dialogTitle}
               </Toolbar>
            </AppBar>
            {/* }	<DialogTitle id="alert-dialog-title">
						<Icon className="text-14 mx-4" color="inherit">
							arrow_forward_ios
						</Icon>
						
					</DialogTitle>
	*/}
            <DialogContent>
               <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
               <ControllerAutoComplete
                  control={control}
                  name="modalInput"
                  className={inputClassName}
                  options={data}
                  label={inputLabel}
                  optionId={inputOptionId}
                  getOptionLabel={inputGetOptionLabel}
                  required={inputRequired}
                  renderOption={inputRenderOption}
                  disableCloseOnSelect={inputDisableCloseOnSelect}
                  multiple={inputMultiple}
               />
            </DialogContent>
            <DialogActions>
               {/* }
						<Button
							onClick={() => onSubmitForm()}
							className="whitespace-nowrap mx-4"
							variant="contained"
							color="secondary"
							size="small"
							disabled={_.isEmpty(dirtyFields) || !isValid}
						>
							{buttonTextOK}
</Button> */}

               <CustomButton
                  className="whitespace-nowrap mx-4"
                  text={buttonTextOK}
                  disabled={inputRequired ? _.isEmpty(dirtyFields) || !isValid : false}
                  onClick={() => onSubmitForm()}
               />

               <CustomButton color="primary" variant="outlined" text="Close" onClick={() => dispatch(closeDialog())} />
            </DialogActions>
         </Dialog>
      </form>
   );
}

export default BasicDialogAutoComplete;
