import { Typography } from '@mui/material';

export const poStatus = [
   {
      value: 'Order Received',
      name: 'Order Received',
      label: (
         <Typography
            className="bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-50  inline text-sm font-medium px-8 py-4 rounded-4"
            key="OrderReceived"
         >
            Order Received
         </Typography>
      ),
      colorChart: '#1E88E5',
   },
   {
      value: 'Not yet supplied',
      name: 'Not yet supplied',
      label: (
         <Typography
            className="bg-cyan-100 text-cyan-800 dark:bg-cyan-600 dark:text-cyan-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="Notyetsupplied"
         >
            Not yet supplied
         </Typography>
      ),
      colorChart: '#00ACC1',
   },
   {
      value: 'Not yet supplied - allocated',
      name: 'Not yet supplied - allocated',
      label: (
         <Typography
            className="bg-grey-100 text-grey-800 dark:bg-grey-600 dark:text-grey-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="NotyetsuppliedAllocated"
         >
            Not yet supplied - allocated
         </Typography>
      ),
      colorChart: '#9E9E9E',
   },
   {
      value: 'Partially Supplied',
      name: 'Partially Supplied',
      label: (
         <Typography
            className="bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="Partially"
         >
            Partially Supplied
         </Typography>
      ),
      colorChart: '#FB8C00',
   },
   {
      value: 'Fully Supplied',
      name: 'Fully Supplied',
      label: (
         <Typography
            className="bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50  inline text-sm font-medium px-8 py-4 rounded-4"
            key="Fully"
         >
            Fully Supplied
         </Typography>
      ),
   },
   {
      value: 'Non-Shipment Order',
      name: 'Non-Shipment Order',
      label: (
         <Typography
            className="bg-pink-100 text-pink-800 dark:bg-pink-600 dark:text-pink-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="Non"
         >
            Non-Shipment Order
         </Typography>
      ),
   },
   {
      value: 'Order Cancelled',
      name: 'Order Cancelled',
      label: (
         <Typography
            className="bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50 inline text-sm font-medium px-8 py-4 rounded-4"
            key="Cancelled"
         >
            Order Cancelled
         </Typography>
      ),
   },
];

export default {
   poStatus,
};
